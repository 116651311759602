import React, { useCallback, useEffect, useState } from 'react'
import { useInput } from '../../hooks/useInput'
import { Input } from '../../ui/Input'
import { TextEditor } from '../../ui/TextEditor'
import styles from './index.module.scss'
// import cn from 'classnames'
import { SvgSprite } from '../../ui/SvgSprite'
import { Select } from '../../ui/Select/Select'
import cn from 'classnames'
import ReactDatePicker from 'react-datepicker'
import { axiosInstance } from '../../config/https'
import { useLocation, useNavigate } from 'react-router-dom'
import { Loader } from '../../ui/Loader'
import { Datastates } from '../../../StaticData/states'
import { ProfileImage } from '../../ui/ProfileImage/ProfileImage'
import { Btn } from '../../ui/Btn/Btn'
import { useDispatch, useSelector } from 'react-redux'
import { Actions } from '../../ui/Actions/Actions'
import { toast } from '../../functions/toast'
import { getIncidentReport } from '../../functions/getIncidentReport'
import { Chips } from '../../ui/ChipsInput'

const Card = ({Header, data, setData})=>{

    return(
        <div className={styles.controlCardPopup}>
            <div className={styles.controlCardHeaderPopup}>
                <div className={styles.controlCardHeaderLeftPopup}> 
                    <div>{Header}</div> 
                </div>
                <div className={styles.controlCardHeaderReigthPopup}> <SvgSprite spriteID={'arrow'} className={styles.arrowInverst}/></div>
            </div>
            <div className={styles.controlCardBodyPopup}>
                <TextEditor setData={setData} data={data} placeholder={Header}/>
            </div>
        </div>
    )
}

export const FormReport = ({reports, setReports, users}) => {
    const currentUser = useSelector(state => state.user.userInfo)
    const dispatch = useDispatch()
    const [preview, setPreview] = useState(false)
    const [newstates, setNewstates] = useState(null)
    const [report, setReport] = useState()
    const [cause, setCause] = useState()
    const [action, setAction] = useState()
    const [newValue, setNewValue] = useState('Draft')
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [userId, setUserId] = useState()
    const [openListUsers, setOpenListUsers] = useState(false)
    const [listMembers, setListMembers] = useState([])
    const [listOfUser, setListOfUser] = useState([])
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const AllReports = useSelector(state => state.incidentReport.incidentReport)
    const [tags, setTags] = useState([])
    const [listOfTags, setListOfTags] = useState([])

    const [updatedReport, setUpdatedReport] = useState()

    const [status] = useState([
        {
            id: 0,
            name:  'Draft',
            value: 'Draft',
        },
        {
            id: 1,
            name: 'Published',
            value: 'Published',
        }
       ]
    )

    const title = useInput('')
    const nbr = useInput('')

    const handelSubmit = async()=>{
        setLoader(true)
        const data = {
            id: reports.length +1,
            report: report,
            cause: cause,
            action: action,
            title: title.value,
            nbr : nbr.value,
            startDate : startDate,
            endDate: endDate,
            status: newValue,
            state : newstates,
            listUsers : listMembers?.map(u => {return u.id}),
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            tags : tags
        }

        if(updatedReport?.id){
            const dataJson =  JSON.stringify(data)
            await axiosInstance.patch(`incident/report/${updatedReport?.id}`, dataJson)
            .then((res)=>{
                dispatch(toast('success', 'Incident Report Updated Successfully'))
                navigate('/report/all')
                dispatch(getIncidentReport())
            }).catch((err)=>{
                dispatch(toast('error', err?.response?.data?.message))
            }).finally(()=>{
                setLoader(false)
            })
        }else{
            await axiosInstance.post('incident/report', data)
            .then((res)=>{
                dispatch(toast('success', 'Incident Report Created Successfully'))
                navigate('/report/all')
                dispatch(getIncidentReport())
            }).catch((err)=>{
                dispatch(toast('error', err?.response?.data?.message))
            }).finally(()=>{
                setLoader(false)
            })
        }
    }

    useEffect(()=>{
        users && setListOfUser(users.map(u => {return {
            id:u.id, 
            name:u.firstName+' '+u.lastName, 
            firstName : u.firstName,
            lastName : u.lastName,
            value:u.id}}))
    }, [users])

    useEffect(()=>{
        location && AllReports?.length > 0 && (()=>{
            setUpdatedReport(AllReports?.find(r => r.id === location?.state?.id))
        })()
    }, [location, AllReports])

    useEffect(()=>{
        updatedReport&&(()=>{
            nbr.setNewValue(updatedReport.nbCaliforniaIndividualsAffected)
            title.setNewValue(updatedReport.title)
            setReport(updatedReport.report)
            setAction(updatedReport.correctiveAction)
            setCause(updatedReport.rootCause)
            setStartDate(new Date(updatedReport.startTime))
            setEndDate(new Date(updatedReport.endTime))
            setNewstates(updatedReport.state)
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[updatedReport])

    useEffect(()=>{
        setListMembers(updatedReport?.listusers || [])
        setUserId(updatedReport?.userId || currentUser?.id)
    }, [currentUser, updatedReport])

    const getTags = useCallback(async()=>{   
        const res = await axiosInstance(`all/framework`)
        
        if(res.status === 200){
            setListOfTags(res.data.frameworks)
        }else{
            setListOfTags([])
        }
    }, [])

    useEffect(()=>{
        getTags()
    }, [getTags])

    return (
        !preview ? 
        <div className={styles.container}>
            <div className={styles.textHeader}>
               <div className={styles.textHeaderLeft}>
                    <SvgSprite spriteID={'filePaper'} className={styles.textHeaderLeftIcon} onClick={()=>navigate('/report/all')} />
                    <SvgSprite spriteID={'arrowNext'} className={styles.textHeaderLeftIcon}/>
                    <div className={styles.textHeaderLeftText}> New Incident Report</div>
               </div>
               <div className={styles.textHeaderRiegth}>
               <button
                    className={styles.teamsPopupBtn}
                    type={'button'}
                    onClick={()=>setPreview(true)}
                >
                    <SvgSprite spriteID={'eyePassword'}/>
                    <div className={styles.textHeaderReigthText}>Preview</div>
                </button>
                <button
                    className={styles.btnBlue}
                    type={'button'}
                    onClick={handelSubmit}
                >
                    <SvgSprite spriteID={'filePaper'}/>
                    <div className={styles.textHeadersave}>Save</div>
                    {loader && <Loader />}
                </button>
               </div>
            </div>
            <div className={styles.reportFormBody}>
                <div className={styles.reportFormBodyLeft}>
                    <div className={styles.titleReport}>
                        <div className={styles.titleReportLeft}>Create Title</div>
                        <div className={styles.titleReportReight}>
                            <Input
                            className={styles.inputStyle}
                            onChange={e => title.onChange(e)}
                            value={title.value || ''} />
                        </div>
                    </div>
                    <div>
                    <Card Header={'Incident Report'} data={report} setData={setReport}/>
                    <Card Header={'Root Cause'} data={cause} setData={setCause}/>
                    <Card Header={'Corrective Action'} data={action} setData={setAction}/>
                </div>
                </div>
                <div className={styles.reportFormBodyRiegth}>
                    <div className={styles.rightSideForm}>
                        <div className={styles.titleriegthSelect}>
                            <div>Status & Visibility</div>
                            {/* <SvgSprite spriteID={'arrow'} className={styles.arrowStyle}/> */}
                        </div>
                        <Select selectFullWidth className={styles.selectClassName} setNewValue={setNewValue} initValue={newValue} values={status}/>
                    </div>

                    <div className={styles.rightSideForm}>
                        <div className={styles.titleriegthSelect}>
                            <div>Contact Information</div>
                            {/* <SvgSprite spriteID={'arrow'} className={styles.arrowStyle}/>     */}
                        </div>
                        {listMembers?.length > 0 && <div className={styles.listProfiles} >        
                            {listMembers?.map((u, i)=>(
                                <ProfileImage key={i} className={styles.listMembersProfile} name={u?.firstName + ' ' +u?.lastName} />
                            ))}
                        </div>}
                        <div className={styles.listIncidentUsers}>
                            <div className={styles.titleriegthSelect}>
                                <div className={styles.titleBorder} onClick={()=>{setOpenListUsers(!openListUsers)}}>
                                    <div>Select Users...</div>
                                    <SvgSprite spriteID={'arrow'} className={cn(styles.arrowStyle, styles.listIconUserReport)}/>    
                                </div>
                            </div>
                            <Actions 
                                setActiveItem={setListMembers} 
                                listMembers={listMembers}
                                isOpenedActions={openListUsers} 
                                actions={listOfUser} 
                                className={styles.btnFull} 
                                noIcon
                                users
                                report
                            />
                        </div>
                    </div>

                    <div className={styles.rightSideForm}>
                        <div className={styles.titleriegthSelect}>
                            <div>Duration of Incident</div>
                            {/* <SvgSprite spriteID={'arrow'} className={styles.arrowStyle}/> */}
                        </div>
                        <div className={styles.ReportDate}>
                            <div className={styles.datePickerReport}>
                                <div className={styles.startEndDate}>Started</div>
                                <div className={styles.startEndTime}>
                                    <ReactDatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        timeInputLabel="Time:"
                                        dateFormat="MMM d, h:mm aa"
                                        showTimeInput
                                        className={styles.datePicker}
                                        selectsStart
                                        startDate={startDate}
                                        endDate={endDate}
                                    />
                                </div>
                            </div>
                            <div className={styles.datePickerReportSvg}><SvgSprite spriteID={'arrow'} className={styles.arrowDateStyle}/></div>
                            <div className={styles.datePickerReport}>
                                <div className={styles.startEndDate}>Ended</div>
                                <div className={styles.startEndTime}>
                                    <ReactDatePicker
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                        timeInputLabel="Time:"
                                        dateFormat="MMM d, h:mm aa"
                                        showTimeInput
                                        selectsEnd
                                        className={styles.datePicker}
                                        startDate={startDate}
                                        endDate={endDate}
                                        minDate={startDate}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.rightSideForm}>
                        <div className={styles.titleriegthSelect}>
                            <div>State</div>
                            {/* <SvgSprite spriteID={'arrow'} className={styles.arrowStyle}/> */}
                        </div>
                        <div className={cn(styles.inputStyle, styles.inputContainer, styles.noBox)} >
                            <Select selectFullWidth 
                                className={styles.selectClassName}  
                                setNewValue={setNewstates} 
                                initValue={newstates} 
                                values={Datastates}/>
                        </div>
                    </div>

                    <div className={styles.rightSideForm}>
                        <div className={cn(styles.titleriegthSelect, styles.marginSlelect)}>
                            <div>Number of Individuals affected</div>
                            {/* <SvgSprite spriteID={'arrow'} className={styles.arrowStyle}/> */}
                        </div>
                        <div className={cn(styles.inputStyle, styles.inputContainer)} >
                            <Input 
                                className={styles.inputReportForm} 
                                type={'number'} 
                                onChange={e => nbr.onChange(e)} 
                                value={nbr.value || ''}
                                placeholder={'0.00'}
                                />
                        </div>
                    </div>

                    <div className={styles.rightSideForm}>
                        <div className={cn(styles.titleriegthSelect, styles.marginSlelect)}>
                            <div>Tags</div>
                            <div className={cn(styles.tagsNumber, {[styles.maxTagNumber] : tags.length === 5})}>{`${tags.length}/5`}</div>
                            {/* <SvgSprite spriteID={'arrow'} className={styles.arrowStyle}/> */}
                        </div>
                        <div className={cn(styles.inputStyle, styles.inputContainer)} >
                            <Chips
                                tags={tags} 
                                setTags={setTags} 
                                listOfTags={listOfTags}
                                max={5} 
                                maxLength={20} 
                            />
                        </div>
                    </div>

                    <div className={cn(styles.extraDataOwner, styles.extraMargin)}>
                        <Btn className={styles.datRiskBtn} noHover onClick={()=>navigate('/calendar', { state: { report: true, title: (title.value || '') } })}>
                            <SvgSprite spriteID={'calendar'} className={styles.calendarIcon}/>
                            <div>Add to Calendar</div>
                        </Btn>
                    </div>
                </div>
            </div>
        </div> 
        :
        <div className={cn(styles.preview)}>
        <div className={styles.containerShowReport}>
          <div className={styles.ShowReportHeader}>
            <div className={styles.imageOwner}> 
              <ProfileImage name={userId ? users?.find(u=>u.id === userId)?.firstName+' '+users?.find(u=>u.id === userId)?.lastName : 'N / A'} className={styles.imageProfilReportShow}/>
              <div className={styles.nameOwnerRole}>
                <div>{userId && users?.find(u=>u.id === userId)?.firstName} {userId && users?.find(u=>u.id === userId)?.lastName}</div>
                <div className={styles.roleReporOwner}>{userId && users?.find(u=>u.id === userId)?.role}</div>
              </div>
            </div>
            <div className={styles.dataOwner}>
              <div className={styles.emailOwner}>{userId && users?.find(u=>u.id === userId)?.email}</div>
              <div className={styles.phoneOwner}>{userId && users?.find(u=>u.id === userId)?.phone}</div>
            </div>
            <div className={styles.extraDataOwner}>
                <SvgSprite spriteID={'close'} className={styles.closePreview} onClick={()=>{setPreview(false)}}/>
            </div>
          </div>
          <div className={styles.bodyShowReport}>
            <div className={styles.bodyHeaderReport}>
              <div className={styles.CloudStorage}>{title.value}</div>
              <div className={styles.bodyDate}>
                {new Date(startDate).toLocaleString('default', { month: 'long', day : '2-digit',year : 'numeric', hour : '2-digit', minute:'2-digit' })}{' '} 
                  PST until {' '}
                {new Date(endDate).toLocaleString('default', { month: 'long', day : '2-digit',year : 'numeric', hour : '2-digit', minute:'2-digit'})}
              </div>
            </div>
            <div className={styles.actionsReport}>
              <Btn className={styles.btnReport}>Incident Report</Btn> 
              <div className={cn(styles.reportText, styles.reportTextIncident)} dangerouslySetInnerHTML={{
                                __html: report
                            }}/>
              <Btn className={styles.btnReport}>Root Cause</Btn> 
              <div className={styles.reportText} dangerouslySetInnerHTML={{
                                __html: cause
                            }}/>
              <Btn className={styles.btnReport}>Corrective Action</Btn> 
              <div className={styles.reportText} dangerouslySetInnerHTML={{
                                __html: action
                            }}/>
            </div>
            <div className={styles.nbrReport}>
              <div className={styles.nbrReportTitle}>Number of Individuals affected</div>
              <div className={styles.nbrReportValue}>{nbr.value}</div>
            </div>
          </div>
        </div>
        </div>
  )
}
