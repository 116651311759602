import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import cn from 'classnames'
import { SvgSprite } from '../../ui/SvgSprite'
import { Btn } from '../../ui/Btn/Btn'
import { useNavigate } from 'react-router-dom'
import { deleteCookie } from '../../utils/cookie'

export const Breakdown = ({closePopup, activeControl, framework}) => {
    const [red, setRed] = useState(true)
    const [blue, setBlue] = useState(true)
    const [green, setgreen] = useState(true)
    const [question, setQuestion] = useState('')
    const [secondQuestion, setSecondQuestion] = useState('')
    const navigate = useNavigate()

    useEffect(()=>{
       setQuestion(`Control Overview: \n\n ${activeControl?.name} \n\n ${activeControl?.description} \n\n Provide a detailed overview of the key requirements, objectives, and scope of this control. Discuss the risks it addresses and its importance within the overall compliance framework.`)
       setSecondQuestion(`Evidence and Validation:
            \n\nTo demonstrate compliance with ${activeControl?.name}, what specific policies, documentation, and checklists should be developed and maintained?
            \n\nProvide examples and lists of the types of evidence that would be necessary to validate the effective implementation of this control. Consider including:
            - Policy and procedure templates
            - Technical configuration standards and baselines
            - Risk assessment and audit frameworks
            - Incident response and business continuity plans
            - Training and awareness materials
            - Contracts and service level agreements (SLAs) with third parties
            - Access control matrices and data flow diagrams
            - System and application inventories and network diagrams
            - Vulnerability and patch management records
            - Change management and version control logs
            - Backup and recovery test results
            - Penetration testing and vulnerability scan reports
            - Audit logs and activity monitoring reports
            - Evidence of management review and approval
            \n\nProvide guidance on how this evidence should be organized, maintained, and presented to auditors or assessors to facilitate efficient validation of control effectiveness.`);    
        }, [activeControl])

    return (
    <div className={styles.addPopup}>
        <div className={styles.controlPopupHeader}>
            <div>Breakdown</div>
            <div className={styles.BreakdownLeftHeader}>
                <Btn disable={!activeControl} className={styles.BreakdownBtn} onClick={() => {deleteCookie('active_chat'); navigate('/help', {state : {firstQuestion : question, secondQuestion : secondQuestion}}) }}>
                    Get Guidance
                </Btn>
                <SvgSprite spriteID={'close'} className={styles.closeIconPopup} onClick={closePopup}/>
            </div>
        </div>
        <div className={cn(styles.controlPopupBodyGod,styles.flexderec, styles.addscroll)}>
            <div className={styles.controlCardPopup}>
                <div className={styles.controlCardHeaderPopup}>
                    <div className={styles.controlCardHeaderLeftPopup}> 
                        <SvgSprite spriteID={'question'} className={styles.controlCardHeaderIconPopup}/>
                        <div>{'Control Question'}</div> 
                    </div>
                    <div className={styles.controlCardHeaderReigthPopup}> <SvgSprite spriteID={'arrow'} className={styles.arrowInverst} onClick={()=>setRed(!red)}/></div>
                </div>
                {red && activeControl?.controlQuestions && <div className={cn(styles.controlCardBodyPopupBrekdown, styles.borderRed)}>
                    <div className={styles.textPopup}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: activeControl.controlQuestions
                            }}
                        />
                    </div>
                </div>}
            </div>

            <div className={styles.controlCardPopup}>
                <div className={styles.controlCardHeaderPopup}>
                    <div className={styles.controlCardHeaderLeftPopup}> 
                        <SvgSprite spriteID={'info'} className={styles.controlCardHeaderIconPopup}/>
                        <div>{'Methods To Comply'}</div> 
                    </div>
                    <div className={styles.controlCardHeaderReigthPopup}> <SvgSprite spriteID={'arrow'} className={styles.arrowInverst} onClick={()=>setBlue(!blue)}/></div>
                </div>
                {blue && activeControl?.complyMethods && <div className={cn(styles.controlCardBodyPopupBrekdown, styles.borderBlue)}>
                    <div className={styles.textPopup}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: activeControl.complyMethods
                            }}
                        />
                    </div>
                </div>}
            </div>

            <div className={styles.controlCardPopup}>
                <div className={styles.controlCardHeaderPopup}>
                    <div className={styles.controlCardHeaderLeftPopup}> 
                        <SvgSprite spriteID={'info'} className={styles.controlCardHeaderIconPopup}/>
                        <div>{'Evidence Collection'}</div> 
                    </div>
                    <div className={styles.controlCardHeaderReigthPopup}> <SvgSprite spriteID={'arrow'} className={styles.arrowInverst} onClick={()=>setgreen(!green)}/></div>
                </div>
                {green && activeControl?.evidenceCollection && <div className={cn(styles.controlCardBodyPopupBrekdown, styles.borderGreen)}>
                    <div className={styles.textPopup}>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: activeControl.evidenceCollection
                            }}
                        />
                    </div>
                </div>}
            </div>
        </div>
    </div>
  )
}