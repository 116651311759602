import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Search } from '../Search/Search'
import { SvgSprite } from '../SvgSprite/SvgSprite'
import styles from './index.module.scss'
import {Popup} from '../../ui/Popup'
// setting popup model
import  {SettingModel}  from '../../modules/SettingsPopup'
import cn from 'classnames'

// hooks
import { useDispatch, useSelector } from 'react-redux'

// utils
import { deleteCookie } from '../../utils/cookie'

// slices
import { setIsAuth, setNotificationNabr } from '../../../store/slices/userSlice'

// asset
import logo from '../../../assets/images/SecureLabsLogo.png'
import { axiosInstance } from '../../config/https'
import { useMobile } from '../../hooks/useMobile'
import { store } from '../../../store'
import { Link, useNavigate } from 'react-router-dom'
import { DotsLoader } from '../Loader'
import { Btn } from '../Btn'
import { AiQuestions } from '../../../StaticData'
import { getControls } from '../../functions/getControls'
import { getFrameworks } from '../../functions/getFrameworks'
import { resetData } from '../../functions/resetData'


const NotifComp = ({notif, type, control, clearAll}) =>{
  const submission = useSelector(state => state.controlSubmission.controlSubmission)
  const controls = useSelector(state => state.control.controls)
  const [path, setPath] = useState('')

  const logs = {
    'OWNER_ASSIGNED' : `${notif?.meta_data?.first_name} ${notif?.meta_data?.last_name} has added you as a control owner`,
    'OWNER_REMOVED': `${notif?.meta_data?.first_name} ${notif?.meta_data?.last_name} has removed you as a control owner`,
    'STAKEHOLDER_ADDED': `${notif?.meta_data?.first_name} ${notif?.meta_data?.last_name} has added you as a stakeholder `,
    'STAKEHOLDER_REMOVED': `${notif?.meta_data?.first_name} ${notif?.meta_data?.last_name} has removed you as a stakeholder `,
    'CONTROL_EVENT_ADDED': `${notif?.meta_data?.first_name} ${notif?.meta_data?.last_name} has assigned you in a new event `,
    'CONTROL_EVENT_REMOVED': `${notif?.meta_data?.first_name} ${notif?.meta_data?.last_name} has removed you from event `,
    'ASSIGNED_INCIDENT_REPORT' : `${notif?.meta_data?.first_name} ${notif?.meta_data?.last_name} has added you to an incident report`,
    'REMOVED_INCIDENT_REPORT' : `${notif?.meta_data?.first_name} ${notif?.meta_data?.last_name} has removed you to an incident report`,
    'USER_MENTIONS' : `${notif?.meta_data?.first_name} ${notif?.meta_data?.last_name} has mention you in a comment`,
  }
  useEffect(()=>{
    if(notif?.meta_data?.control_id){
      var control_id = notif?.meta_data?.control_id;
      setPath(`/framework/control/${control_id}/submission`)
    }else if(notif?.meta_data?.control_submission_id && control?.length > 0 && submission?.length > 0){
      const id = notif?.meta_data?.control_submission_id
      const conrol_id = controls?.find(c => c.id === submission?.find(s => s.id === id)?.control_id)?.id
      setPath(`/framework/control/${conrol_id}/submission`)
    }else if(notif?.meta_data?.incident_report_id){
      setPath(`/report/all`)
    }
  }, [control, controls, notif, submission])
  return (  
    <div to={path} className={styles.notifMessage}>
      <Link to={path} className={styles.messageNotif}>{logs[type]}</Link>
      <SvgSprite spriteID={'close'} onClick={()=>{clearAll(notif.id)}}/>
    </div>
  )
}

export const Navbar = ({expanded, setExpanded}) => {
  const [addSettingPopup, setAddSettingPopup] = React.useState(false)
  const accountPlan =useSelector(state => state.organization.org.account_plan)
  const dispatch = useDispatch()
  const notificationNbr = useSelector(state => state.user.notificationNbr)
  const user = useSelector(state => state.user.userInfo)
  const framework = useSelector(state => state.framework.frameworks)
  const control = useSelector(state => state.control.controls)
  const isMobile = useMobile(1300)
  const isMobile2 = useMobile(1035)
  const [open, setOpen] = useState(false)
  const [openNotification, setOpenNotification] = useState(false)
  const [notifications, setNotifications] = useState([])
  const ref = useRef()
  const searchRef = useRef()
  const notifRef = useRef()
  const listNotifRef = useRef()
  const [search, setSearch] = useState('')
  const [searchData, setSearchData] = useState(null)
  const [loadNotif, setLoadNotif] = useState(true)

  const navigate = useNavigate()

  const Logout = async () => {
    const res = await axiosInstance.post('/auth/logout')
    if(res.data.success) {
      deleteCookie('access_token')
      dispatch(resetData())
      dispatch(setIsAuth(false))
      const sliceReducers = Object.keys(store.getState()).filter(key => key !== 'root');
      sliceReducers.forEach(key => store.dispatch({ type: `${key}/RESET` }));
      }
  }

  const handleClick = e => {
    if (open && !ref.current.contains(e.target)) {
        setOpen(false)
    }

    if (search.length>0 && !searchRef.current.contains(e.target)) {
      setSearch('')
    }

    if(notifRef && !notifRef?.current?.contains(e.target) && !listNotifRef?.current?.contains(e.target)) {
      setOpenNotification(false)
    }
  }

  const handelNavigate = (id, to) => {
    if(to === 1){
      navigate(`/framework/${id}`)
    }else{
      const data = { control : control?.find(c => c.id === id) };
      navigate(`/framework/control/${id}/submission`, {state : data})
    }
    setSearch('')
    setSearchData(null)
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)

    return () => document.removeEventListener('click', handleClick)
  })


  useEffect(()=>{
     setSearchData({
                  frameworks : framework?.length > 0 && framework?.filter((item) => item.name.toLowerCase().includes(search.toLowerCase())).map(f => {return {id : f.id, name : f.name}}),
                  controls : control?.length > 0 && control?.filter((item) => item.name.toLowerCase().includes(search.toLowerCase())).map(f => {return {id : f.id, name : f.name}}),
                  aiQuestions : AiQuestions.filter((item) => item.question.toLowerCase().includes(search.toLowerCase())).map(f => {return {id : f.id, question : f.question}}),
                })
  }, [search, framework, control])


  useEffect(()=>{
    openNotification && (async()=>{
      setLoadNotif(true)
      axiosInstance('notifications')
      .then(response => {
        setNotifications(response?.data?.data);
        dispatch(setNotificationNabr(response?.data?.data?.length))
      })
      .catch(error => {
        setLoadNotif(false)
        setOpenNotification(false)
      }).finally(()=>{
        setLoadNotif(false)
      })
    })()
  }, [dispatch, openNotification])

  const clearAll = (id = null) =>
  {
    if(id){
      axiosInstance.put(`notifications/${id}/mark-as-read`)
      .then(()=>{
        setNotifications([...notifications.filter((n)=> n.id!==id)])
      })
      .catch(error => {
        console.log(error);
      });
    }else{
      axiosInstance.put('notifications/mark-all-as-read')
      .then(()=>{
        setNotifications([])
      })
      .catch(error => {
        console.log(error);
      });
    }
  }

  const getData = useCallback(async()=>{
      await Promise.all([
        !control && dispatch(getControls()),
        !framework && dispatch(getFrameworks()),
      ])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  useEffect(()=>{
    search?.length > 0 && getData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getData])

  return (
    <div className={styles.container}>
        <div className={cn(styles.logo, {[styles.marginLogo] : isMobile2})}>
          {!isMobile && <SvgSprite className={styles.svgIconsTop} spriteID={'menu'} onClick={() => setExpanded(!expanded)}/>}
          <div className={styles.logoText}>
            <img src={logo} alt='logo'/>
          </div>
        </div>
      <div className={styles.serachContainerDiv}>
        {accountPlan?.toLowerCase() !== 'individual' && accountPlan?.toLowerCase() !== 'basic' &&
          <Search className={styles.withContainer} value={search} setSearch={setSearch} icon/>
        }
      {search.length > 0 &&( 
        (framework?.length > 0 && control?.length > 0) ?
        <div ref={searchRef} className={cn(styles.dropDownSearch)}>
          <div className={cn(styles.actionsContentBtn, styles.searchHeaderItem)}>
            <div>Frameworks {searchData?.frameworks?.length}</div>
          </div>
          <div className={styles.itemSearchContainer}>
            {searchData?.frameworks?.length > 0 && searchData?.frameworks?.map((d,index) => ( <div key={index} className={styles.dropDownSearchItem} onClick={()=>handelNavigate(d.id, 1)}>
              {d.name}
            </div>))}
          </div>
          <div className={cn(styles.actionsContentBtn, styles.searchHeaderItem)}>
            <div>Controls {searchData?.controls?.length}</div>
          </div>
          <div className={styles.itemSearchContainer}>
            {searchData?.controls?.length > 0 && searchData?.controls?.map( (d,index) => ( <div key={index} className={styles.dropDownSearchItem} onClick={()=>handelNavigate(d.id)}>
              {d.name}
            </div>))}
          </div>
          <div className={cn(styles.actionsContentBtn, styles.searchHeaderItem)}>
            <div> Ask SecureLabs Ai {searchData?.aiQuestions?.length}</div>
          </div>
          <div className={styles.itemSearchContainer}>
            {searchData?.aiQuestions?.length > 0 && searchData?.aiQuestions?.map( (d,index) => ( <div key={index} className={styles.dropDownSearchItem} onClick={()=>{navigate('/help', {state : d.question}); setSearch('')}}>
              {d.question}
            </div>))}
          </div>
        </div>
        
        :
        <div ref={searchRef} className={cn(styles.dropDownSearch, styles.loaderSearch)}>
          <DotsLoader />
        </div>
      )}
      </div>
      <div className={cn(styles.header)}>
          <div ref={notifRef} className={styles.notification}>
            {notificationNbr > 0 && <div className={styles.notificationNbr}>{(notifications?.length || notificationNbr)}</div>}
            <SvgSprite spriteID={'bell'} className={styles.settingsIcon} onClick={()=>{setOpenNotification(!openNotification)}}/>
          </div>
          {openNotification && <div ref={listNotifRef} className={cn(styles.actionsContentNotif)}>
              {loadNotif && !notifications?.length > 0 ? 
                <div className={styles.loadNotif}>
                  <DotsLoader />
                </div>
              :
              notifications?.length > 0 ?
                <div>
                  <div className={styles.notifHeader}>
                    <Btn className={styles.notifHeaderBtn} onClick={()=>{clearAll()}}>Clear All</Btn>
                  </div>
                  <div>
                    {(notifications)?.map((n,i)=>(
                        <NotifComp key={i} notif={n} type={n.type} control={control} clearAll={clearAll}/>
                      ))}
                  </div> 
                </div>
              : 
              <div className={styles.loadNotif}>No Notifications</div>}
            </div>}
          <SvgSprite spriteID={'user'} className={styles.userIcon}/>
          <div className={styles.user}>
            <div className={styles.name} ref={ref} onClick={()=>setOpen(!open)}> {user.firstName} {user.lastName} </div>
            {open && <div className={cn(styles.actionsContent)}>
              <div className={cn(styles.actionsContentBtn)} onClick={()=>setAddSettingPopup(true)}>
                <div>Settings</div>
                <SvgSprite spriteID={'settings'}/>
              </div>
              <div className={cn(styles.actionsContentBtn)} onClick={Logout}>
                <div>Logout</div>
                <SvgSprite spriteID={'log-out'}/>
              </div>
            </div>}
            {/* <div className={styles.role}> Super Admin </div> */}
          </div>
           {/* <SvgSprite spriteID={'settings'} className={styles.settingsIcon}  onClick={()=>setAddSettingPopup(true)} /> */}
          {/*<SvgSprite spriteID={'log-out'} className={styles.settingsIcon} onClick={Logout}/> */}
        </div>
        {addSettingPopup && <Popup extraBig  grayBackground isOpenedPopup={addSettingPopup} closePopup={() => setAddSettingPopup(false)}>
          <SettingModel
            closePopup={() => setAddSettingPopup(false)}
            activities={false}
          />
        </Popup>}

    </div>
  )
}