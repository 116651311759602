import React, { useState } from 'react'
import styles from './index.module.scss'
import { SettingModel } from '../SettingsPopup'
import { Popup } from '../../ui/Popup'

export const Plan = () => {
  const [addSettingPopup, setAddSettingPopup] = React.useState(false)
  const [plan, setPlan] = useState(false)

  return (
    <div>
        <div className={styles.main}>
          <div className={styles.noReportMsg}>
          {/* {(accountPlan.toLowerCase() === 'individual' || accountPlan.toLowerCase() === 'basic') ?
            <Btn className={styles.upgradeBtn} onClick={()=>{setAddSettingPopup(true);setPlan(true)}}>Upgrade to {accountPlan.toLowerCase() === 'individual' ? 'Basic or' : ''} Standard Plan</Btn>
          :
            <>{accountPlan}</>
          } */}
          Coming Soon
        </div>

        {addSettingPopup && <Popup extraBig  grayBackground isOpenedPopup={addSettingPopup} closePopup={() => setAddSettingPopup(false)}>
          <SettingModel
            closePopup={() => setAddSettingPopup(false)}
            plan={plan}
            setPlan={setPlan}
          />
        </Popup>}
      </div>
    </div>
  )
}
