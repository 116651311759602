import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import cn from 'classnames'

export const Status = ({ submission, resources, red = 30, green = 20, yellow = 50, big, small}) => {
  const [status, setStatus] = useState(0)
  const [hover, setHover] = useState(false)

  useEffect(()=>{
    (()=>{
      var status = 0
      if(submission?.user_id)  {status += 10}
      if(submission?.stakeholders?.length > 0) {status += 20}
      ( submission?.control_logs?.find(e => e.attachments != null) || (resources && resources?.length > 0) ) && (status += 30)
      if(submission?.events?.length > 0) {status += 20}

      setStatus(status)
    })()
  }, [submission, resources])
  return (
    <div className={styles.statusRiskComp}>
      <div className={cn(styles.statusComp, {[styles.bigStatus]  : big})} onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}>
        <div className={styles.redStatus} style={{width : red + '%'}}></div>
        <div className={styles.yellowStatus} style={{width : yellow + '%'}}></div>
        <div className={styles.greenStatus} style={{width : green + '%'}}></div>
      </div>
      {(big || hover )&& 
      <>
        <div className={cn(styles.toulBarStatus, {
          [styles.toulBarStatusRed] :status <=30 ,
          [styles.toulBarStatusYellow] : status>30 && status<80,
          [styles.toulBarStatusGreen] : status>=80 && status<=100,
          [styles.toulBarStatusSmall] : small,
          })} style={{left : (status-1.5)>88? 88+'%' : (status-1.5)+'%' }}> {status}%</div>
        <div className={cn(styles.arrowDown, {
          [styles.redArrow] : status <=30,
          [styles.yellowArrow] : status>30 && status<80,
          [styles.greenArow] : status>=80 && status<=100,
          [styles.arrowDownSmall] : small,
          })} style={{left : (status-1.5) > 97.5 ? 97.5+'%':  (status-1.5)+'%'}}></div>
      </>
      }
      {small &&
        <div className={cn(styles.circlStatus, {
          [styles.redCircl] : status <=30,
          [styles.yellowCircl] : status>30 && status<80,
          [styles.greenCircl] : status>=80 && status<=100
          })} style={{left : (status-1.5) > 97.5 ? 97.5+'%':  (status-1.5)+'%'}}
          onMouseEnter={()=>setHover(true)} onMouseLeave={()=>setHover(false)}
        >
        </div>}
      </div>
  )
}