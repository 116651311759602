// src/features/documents/documentsSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../../core/config/https';

// Async thunks to fetch controls and frameworks
export const fetchControls = createAsyncThunk('documents/fetchControls', async () => {
  const response = await axiosInstance('/organization/controls');
  return response.data;
});

export const fetchFrameworks = createAsyncThunk('documents/fetchFrameworks', async () => {
  const response = await axiosInstance('/frameworks');
  return response.data;
});

export const fetchControlsByFramework = createAsyncThunk('documents/fetchControlsByFramework', async (frameworkId) => {
    const response = await axiosInstance(`/organization/controls?frameworkId=${frameworkId}`);
    return { frameworkId, controls: response.data };
});

export const fetchNonApplicableControls = createAsyncThunk('documents/fetchNonApplicableControls', async () => {
  const response = await axiosInstance('/controls/non-applicable');
  return response.data;
});


const documentsSlice = createSlice({
  name: 'documents',
  initialState: {
    controls: [],
    frameworks: [],
    controlsByFramework: {},
    nonApplicableControls : [],
    status: 'idle',
    error: null,
    selectedTab: 'all-controls',
  },
  reducers: {
    selectTab: (state, action) => {
      state.selectedTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchControls.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchControls.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.controls = action.payload;
      })
      .addCase(fetchControls.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchFrameworks.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFrameworks.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.frameworks = action.payload;
      })
      .addCase(fetchFrameworks.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchControlsByFramework.pending, (state, action) => {
        state.status= 'loading';
        state.controlsByFramework[action.meta.arg] = {
          controls: [],
        };
      })
      .addCase(fetchControlsByFramework.fulfilled, (state, action) => {
        const { frameworkId, controls } = action.payload;
        state.status= 'succeeded';
        state.controlsByFramework[frameworkId] = {
          controls,
        };
      })
      .addCase(fetchControlsByFramework.rejected, (state, action) => {
        state.status= 'failed';
        state.controlsByFramework[action.meta.arg] = {
          controls: [],
          error: action.error.message,
        };
      })
      .addCase(fetchNonApplicableControls.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchNonApplicableControls.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.nonApplicableControls = action.payload;
      })
      .addCase(fetchNonApplicableControls.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { selectTab } = documentsSlice.actions;

export default documentsSlice.reducer;
