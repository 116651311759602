import React, { useEffect, useRef, useState } from 'react'
import styles from './index.module.scss'
import cn from 'classnames'
import { DotsLoader, Loader } from '../../ui/Loader'
import { SvgSprite } from '../../ui/SvgSprite'
import { axiosInstance } from '../../config/https'
import { toast } from '../../functions/toast'
import { Btn } from '../../ui/Btn'
import { Popup } from '../../ui/Popup'

export const Repository = ({listRepositories, repository, setRepositoryLoader, handelMove, allRepositories, setData, data, setMoveRepToRep, deleteLoadFolder, setShowDeleteRepository, setActiveRepository, dropFolder, setDropFolder, repositores, repositoryLoader, resources, getExt, loader, getPDF, getFile, deleteLoad, dispatch, setResources, editResource, downloadFile, delete_resource, setDropResource, dropResource, cloneResource}) => {
  const [showListRepositoriesPopup, setShowListRepositoriesPopup] = useState(false)
  const [moveToRepository, setMoveToRepository] = useState(null)

  const repoRef = useRef(null);
  const ressourcesRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (repoRef.current && !repoRef.current.contains(event.target)) {
        setDropFolder(null);
      }

      if(ressourcesRef.current && !ressourcesRef.current.contains(event.target)){
        setDropResource(null)
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repoRef, ressourcesRef]);

  const detach = (id) => {
    const data = {
      'repository_id' : repository.id,
      'resource_id' : id 
    };
    
    axiosInstance.patch('repositories/resources', data)
    .then(()=>{
      dispatch(toast('success',  'Resource Moved To Parent Directory'))
    }).catch((err)=>{
      dispatch(toast('error',  err?.response?.data?.message))
    }).finally(()=>{
      setShowListRepositoriesPopup(false);
      setMoveToRepository(null);
    })
  }

  const delete_res = async(id) =>{
    await delete_resource(id)
  }

  const handelMoveTo = () => {
    setDropResource(null)
    if(moveToRepository){
      const data = {
        'repository_id' : moveToRepository,
        'resource_id' : showListRepositoriesPopup
      }
      axiosInstance.patch('repositories', data)
      .then(()=>{
        dispatch(toast('success', 'Resource Moved Successfully'))
      })
      .catch((err)=>{
        dispatch(toast('error', err?.response?.data?.message))
      })
      .finally(()=>{
          setMoveToRepository(null);
          setShowListRepositoriesPopup(false); 
      })
    }else{
      detach(showListRepositoriesPopup)
    }
  }

  return (
    <div className={styles.resourcesMain}>
      {!loader ? <div className={styles.gridRow}>
      {repositores?.map((r, i) => (
          <div key={i} className={cn(styles.gridItem)}> 
            <div className={styles.resourcesHeaderIcon}>
              <SvgSprite spriteID={'dots'} className={cn(styles.resourcesIcon, styles.dotsStyles)} onClick={()=>setDropFolder(dropFolder === r?.id ? null : r.id)}/>
              {dropFolder === r.id && 
                <div ref={repoRef} className={cn(styles.actionsContent, styles.dropResourcActionsContent)}>
                  {r?.organization_id && <div className={cn(styles.dropResourceBtn, {
                    [styles.deleteLoad] : deleteLoad})} onClick={()=> {setShowDeleteRepository(r?.id); setDropFolder(null)}}>
                    <div> Delete </div>
                    {deleteLoadFolder ? <Loader /> : <SvgSprite spriteID={'trash'} className={styles.resourcesIcon}/> }
                  </div>}
                  <div className={cn(styles.dropResourceBtn, { [styles.desableClick] : listRepositories.length <= 0 })} onClick={()=>{setMoveRepToRep(r.id)}}>
                    <div> Move </div>
                    <SvgSprite spriteID={'move'} className={styles.resourcesIcon}/>
                  </div>
                </div>
              }
            </div>
            <div onClick={()=>setActiveRepository(r)} className={styles.bodyTypeContainer} >
              <SvgSprite spriteID={'folder'} className={styles.folderStyles} />
            </div>
            <div onClick={()=>setActiveRepository(r)} className={styles.centerFrameworkName} >{r.name} </div>
          </div>
        ))}
        {resources?.map((r,i) => (
          <div key={i} className={cn(styles.gridItem)} > 
              <div className={styles.resourcesHeaderIcon}>
                <SvgSprite spriteID={'dots'} className={cn(styles.resourcesIcon, styles.dotsStyles)} onClick={()=>setDropResource(dropResource === r.id ? null : r.id)}/>
                {dropResource === r.id && 
                  <div ref={ressourcesRef} className={cn(styles.actionsContent, styles.dropResourcActionsContent)}>
                    {r?.html && <div className={styles.dropResourceBtn}  onClick={()=>{cloneResource(r); setDropResource(null)}}>
                        <div> Clone </div>
                      <SvgSprite spriteID={'filePaper'} className={styles.resourcesIcon} />
                    </div>}
                    {r?.organization_id && <div className={styles.dropResourceBtn} onClick={()=>{editResource(r); setDropResource(null)}}>
                      <div> Edit </div>
                      <SvgSprite spriteID={'edit_pen'} className={styles.resourcesIcon}/>
                    </div>}
                    <div className={styles.dropResourceBtn} onClick={()=>{downloadFile(r.policyDocument); setDropResource(null)}}>
                      <div> Download </div>
                      <SvgSprite spriteID={'download'} className={styles.resourcesIcon}/>
                    </div>
                    <div className={styles.dropResourceBtn} onClick={()=> {  setShowListRepositoriesPopup(r.id);setDropResource(null);}}>
                      <div> Move </div>
                      <SvgSprite spriteID={'move'} className={styles.resourcesIcon}/>
                    </div>
                    {r?.organization_id && <div className={cn(styles.dropResourceBtn, {
                      [styles.deleteLoad] : deleteLoad})} onClick={()=> delete_res(r?.id)}>
                      <div> Delete </div>
                      {deleteLoad ? <Loader /> : <SvgSprite spriteID={'trash'} className={styles.resourcesIcon}/> }
                    </div>}
                  </div>
                }
              </div>
              <div className={styles.documentDetailsContainer} onClick={()=>{(getExt(r.policyDocument) !== 'pdf') ? getFile(r.policyDocument) : getPDF(r.policyDocument)}}>
                <div className={styles.bodyTypeContainer} >
                  <div className={styles.extention}>{getExt(r.policyDocument)}</div>
                </div>
                <div className={styles.centerFrameworkName} >{r.policyName} </div>
                <div className={styles.frameworkCategory} >{r.category} {r?.deleted_at}</div>
              </div>
              
          </div>
        ))}
        </div>:<div className={styles.centerLoader}> <DotsLoader /> </div>
      }
      <div className={styles.centerLoader}>{ !loader && repositores?.length === 0 && resources?.length === 0 && <div className={styles.noResource}> No resources </div> }</div>
     <Popup small={true} isOpenedPopup={showListRepositoriesPopup} closePopup={()=>{setShowListRepositoriesPopup(false);setMoveToRepository(null);}} >
        <div className={styles.repositoriesContainer}>
          <div className={styles.listOfRepositories}>
            <div className={styles.radioRepository} onClick={()=>setMoveToRepository(0)}>
                <input type='radio' name='repo' checked={0 === moveToRepository} className={styles.repositoryInputRadio} placeholder='Create Repository' onChange={() => setMoveToRepository(0)}/> {"/"} 
              </div>
            {allRepositories?.map((r, i) => r.id !== repository.id && (
              <div key={i} className={styles.radioRepository} onClick={()=>setMoveToRepository(r.id)}>
                <input type='radio' name='repo' checked={r.id === moveToRepository} className={styles.repositoryInputRadio} placeholder='Create Repository' onChange={() => setMoveToRepository(r.id)}/> {r.name} 
              </div>
            ))}
            {allRepositories.length === 0 && <div className={styles.NoRepoMessage}> No Repositories </div>}
          </div>
          <Btn className={styles.repositoryBtn} onClick={() => handelMoveTo()} > {false ? <Loader /> : 'Move'} </Btn>
        </div>
      </Popup>
  </div>
  )
}