import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './index.module.scss'
import cn from 'classnames'
import { ProfileImage } from '../../ui/ProfileImage/ProfileImage'
import { SvgSprite } from '../../ui/SvgSprite'
import { useNavigate } from 'react-router-dom'
import { DotsLoader } from '../../ui/Loader/DotsLoader'
import { Btn } from '../../ui/Btn'
import { getIncidentReport } from '../../functions/getIncidentReport'
import { axiosInstance } from '../../config/https'

export const AllReport = () => {
  const reports = useSelector(state => state.incidentReport.incidentReport)
  const navigate = useNavigate()
  const users = useSelector(state => state.organization.org.users)
  const dispatch = useDispatch()
  const [filter, setFilter] = useState(false)
  const [listOfTags, setListOfTags] = useState([])
  const [tags, setTags] = useState([])
  const [filterTags, setFilterTags] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [filterReports, setFilterReports] = useState([])

  const handleClick = (r)=>{
    navigate(`/report/${r?.id}`, { state: r })
  }
 
  useEffect(() => {
    (async () => {
      await Promise.all([
        !reports && dispatch(getIncidentReport())
      ])
    })()
  }, [dispatch, reports])

  const getTags = useCallback(async()=>{   
    const res = await axiosInstance(`tags`)
    
    if(res.status === 200){
      setListOfTags(res.data.data)
      setFilterTags(res.data.data)
    }else{
      setListOfTags([])
    }
  }, [])
  
  useEffect(()=>{
    getTags()
  }, [getTags])

  const handleAddChip = (tag) => {
    setTags((prevTags) => [...prevTags, tag]);
    setFilterTags((prevFilterTags) => prevFilterTags.filter(t => t !== tag));
  };
  
  const handleFilterTags = useCallback(() => {
    setFilterTags(
      listOfTags?.filter(t =>
        t.toLowerCase().includes(inputValue) && !tags?.some(existingTag => existingTag === t)
      )
    );
  }, [inputValue, listOfTags, tags]);
  
  useEffect(() => {
    handleFilterTags();
  }, [inputValue, handleFilterTags]);
  
  const unselectTags = (tag) => {
    setTags((prevTags) => prevTags.filter(t => t !== tag));
    setFilterTags((prevFilterTags) => [...prevFilterTags, tag]);
  };
  
  useEffect(() => {
    if (tags.length === 0) {
      setFilterReports(reports || []);
    } else {
      const reportsWithTag = reports?.filter(report =>
        report.tags?.some(tag =>
          tags?.some(t => t === tag)
        )
      );
      setFilterReports(reportsWithTag || []);
    }
  }, [tags, reports]);

  return (
    <div>
      <div className={styles.main}>
        <div className={styles.newReport}>
          <div className={styles.tagsContainer}>
            <div className={styles.filterReports}>
              <div className={styles.filterBtn} onClick={()=>setFilter(!filter)}>
                <div>Filter</div>
                <SvgSprite spriteID={'filter'} />
              </div>
              {filter && (<div className={styles.filterDropDown}>
                {listOfTags?.length > 0 &&
                  <div className={styles.tagsDropDown}>
                    <div key={0} className={styles.tagsSearch} >
                      <input onChange={(e)=>setInputValue(e.target.value)} placeholder='Search...'/>
                    </div>
                    <ul className={styles.listOfTags}>
                      {filterTags.map((tag, i)=>(
                        <li key={i} className={styles.tagItem} onClick={()=>handleAddChip(tag)}>{tag}</li>
                      ))}  
                    </ul>
                  </div>
                }
              </div>)}
            </div>
            <div className={styles.listOfSelectedTags}>
              {tags.map((t, index) => (
                  <div key={index} className={styles.chipTag}>
                    <div>{t}</div>
                    <SvgSprite spriteID={'close'} className={styles.closeSvg} onClick={()=>unselectTags(t)}/>
                  </div>
              ))}
            </div>
          </div>
          <Btn className={styles.newReportBtn} onClick={()=>navigate('/report/new')}>
            <SvgSprite spriteID={'plus'} />
            <div> New Report</div>
          </Btn>
        </div>
        <div className={styles.containerAllReport}>
          <ul className={styles.responsiveTable}>
            <li className={styles.tableHeader}>
              <div className={cn(styles.col, styles.col1, styles.ownerHeader)}>Owner</div>
              <div className={cn(styles.col, styles.col2)}>Name</div>
              <div className={cn(styles.col, styles.col3)}>Description</div>
              <div className={cn(styles.col, styles.col4)}>Tags</div>
              <div className={cn(styles.col, styles.col5)}>Status</div>
              <div className={cn(styles.col, styles.col6)}></div>
            </li>
            {reports?.length > 0 ? (
              filterReports?.length > 0 ? (
                filterReports?.map((r, i) => (
                  <li className={styles.tableRow} key={i}>
                    <div className={cn(styles.col, styles.col1, styles.col1Border)} data-label="Owner Img">
                      <ProfileImage
                        name={`${users?.find(u => u.id === r.owner)?.firstName} ${users?.find(u => u.id === r.owner)?.lastName}`}
                        className={styles.imageProfil}
                      />
                    </div>
                    <div className={cn(styles.col, styles.col2)} data-label="Report Title">
                      {r.title}
                    </div>
                    <div className={cn(styles.col, styles.col3)} data-label="Description">
                      {new Date(r.startTime).toLocaleString('default', {
                        month: 'long',
                        day: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                      })}{' '}
                      PST until{' '}
                      {new Date(r.endTime).toLocaleString('default', {
                        month: 'long',
                        day: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                      })}
                    </div>
                    <div className={cn(styles.col, styles.col4)} data-label="Tags">
                      {r.tags?.length > 0 ? <ChipContainer chips={r.tags} /> : "-"}
                    </div>
                    <div className={cn(styles.col, styles.col5)}>{r.status}</div>
                    <div className={cn(styles.col, styles.col6)}>
                      <SvgSprite spriteID={'more'} onClick={() => handleClick(r)} className={styles.moreSvg} />
                    </div>
                  </li>
                ))
              ) : (
                <li className={styles.noReportMsg}>
                  {filterReports?.length === 0 ? 'No Report Available' : <DotsLoader />}
                </li>
              )
            ) : (
              <li className={cn(styles.noReportMsg, styles.tableRow)}>
                {reports?.length === 0 ? 'No Report Available' : <DotsLoader />}
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

const ChipContainer = ({ chips }) => {
  const maxVisibleChips = 1;
  const [showMore, setShowMore] = useState(false);

  const visibleChips = chips.slice(0, maxVisibleChips);
  const hiddenChips = chips.slice(maxVisibleChips);

  const tooltipRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setShowMore(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []); 

  return (
    <div className={styles.chipContainer}>
      {visibleChips.map((chip, index) => (
        <div className={styles.chip} key={index}>
          <div>{chip}</div>
        </div>
      ))}
      {hiddenChips.length > 0 && (
        <div
          className={cn(styles.chip, styles.moreChips)}
          onClick={() => setShowMore(!showMore)}
          style={{ cursor: 'pointer' }}
        >
          <div>+{hiddenChips.length} more</div>
        </div>
      )}
      <div ref={tooltipRef} className={cn(styles.tooltip, {[styles.ShowTooltip] : showMore})}>
        {hiddenChips.map((chip, index) => (
          <div key={index} className={styles.chip}>{chip}</div>
        ))}
      </div>
    </div>
  );
};