import { setEvents, setEventsIsLoading } from '../../store/slices/eventsSlice'
import { axiosInstance } from '../config/https'

export const getEvents = () => {
    return async dispatch => {
        dispatch(setEventsIsLoading(true))
        const res = await axiosInstance(`events`)
        
        if(res.status === 200){
            dispatch(setEvents(res.data.events))
        }
        dispatch(setEventsIsLoading(false))
    }
}