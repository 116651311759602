
import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid'
import cn from 'classnames';
// Hooks
import { useDispatch, useSelector } from 'react-redux'

// styles
import styles from './index.module.scss'

// components
import { SvgSprite } from '../../ui/SvgSprite';

// slices
import { addNotification, removeNotification } from '../../../store/slices/notificationsSlice'
import { Btn } from '../../ui/Btn';
import { DotsLoader, Loader } from '../../ui/Loader';
import { Search } from '../../ui/Search/Search';
import { axiosInstance } from '../../config/https';
import { Popup } from '../../ui/Popup';
import { getResources } from '../../functions/getResources';
import { toast } from '../../functions/toast';

export default function FilesDragAndDrop({onUpload, listFiles, setResetFiles, resetFiles, submissionId, ResourcesIds, setResources, controlId}) {
    const [file, setFile] = useState([]);
    const dispatch = useDispatch()
    const [displayResources, setdisplayResources] = useState(false)
    const [loader, setLoader] = useState(false)
    const [search, setSearch] = useState([])
    const resources = useSelector(state => state.resources.resources)
    const [ids, setIds] = useState([])
    const [dotsLoader, setDotsLoader] = useState(false)
    const [data, setData] = useState([])
    const [listRepositories, setListRepositories] = useState([])
    const [activeRepository, setActiveRepository] = useState(null);
    const [listOfRepositoryResources, setListOfRepositoryResources] = useState([])
    const [resourcesLoad, setResourcesLoad] = useState(false)
    const [repositoryLoad, setRepositoryLoad] = useState(false)

    const handleDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
    
    const select = (id)=>{
      console.log();
      if(!ids.includes(id)){
          setIds([...ids, id])
      }else{
          setIds([...ids.filter(i => i !== id)])
      }
    }

    const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();

      const {files} = e.dataTransfer;

      if (files && files.length && file.length + files.length <= 5) {
        if(file?.length === 0) 
          setFile([...Array.from(files)])
        else
          setFile([...Array.from(files).concat(file)])
      } else {
        const errorID = uuid()

        dispatch(addNotification({id: errorID, type: 'error', text: `the Max Number of Files is 5`}))
        setTimeout(() => {
            dispatch(removeNotification({id: errorID}))
        }, 3000)
      }

      onUpload([...file]);
    };

    const removeFile = (i) => {
      setFile([...file.filter((f, index) => i !== index)])
    }

    useEffect(()=>{
        onUpload([...file])
    }, [file, onUpload])
  
    const handleUpload = (e) => {
       if(Array.from(e.target.files).length + file.length <=5) {
         if(file.length > 0)  
          setFile([...Array.from(e.target.files), file]) 
            else 
          setFile([...Array.from(e.target.files)])
       } else{
          const errorID = uuid()

          dispatch(addNotification({id: errorID, type: 'error', text: `the Max Number of Files is 5`}))
          setTimeout(() => {
              dispatch(removeNotification({id: errorID}))
          }, 3000)
       }
    }

    const handleAddResources = async () => {
      try {
        setLoader(true)
        const data = {
            ids : ids,
            submissionId : submissionId,
        }
        
        await axiosInstance.post(`control_log/${controlId}/resources`, data)
        .then((res) => {
          setResources([...res.data.resources])
          dispatch(toast('success', res?.data?.message))
        })
        .catch(err => {
          dispatch(toast('error',  err?.response?.data?.message))
        }).finally(()=>{
          setLoader(false)
        })
       
      } catch (error) {
        dispatch(toast('error', error))
        setLoader(false)
      }
    }
  
    useEffect(()=>{
      if(resetFiles){
        setFile([])
        onUpload([])
        setResetFiles(false)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetFiles])

    useEffect(()=>{
      (async()=>{
        setDotsLoader(true)
        await dispatch(getResources())
        setDotsLoader(false)
      })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

      
  useEffect(()=>{
    if(search?.length > 0 ) {
      setData(resources?.filter((item) => item.policyName?.toLowerCase().includes(search?.toLowerCase()) ))
    }else{
      setData(resources)
    }
  }, [search, resources])

  useEffect(()=>{
    ResourcesIds && setIds([...ResourcesIds]);
  }, [ResourcesIds])

  const handelClose = () => {
    setdisplayResources(false)
    setIds([...ResourcesIds]);
  }

  const getRepository = useCallback(async () =>{
    setRepositoryLoad(true)
    await axiosInstance('/repositories')
    .then((res)=>{
      setListRepositories([...res.data.data])
    }).catch((err)=>{
      dispatch(toast('error', err?.response?.data?.message))
    }).finally(()=> setRepositoryLoad(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listRepositories])

  useEffect(()=>{
    getRepository()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getExt = (path) =>{
    return path.substring(path.lastIndexOf(".")+1)
  }    

  const getRepositoryResources = useCallback(()=>{
    setResourcesLoad(true)
    axiosInstance(`repositories/${activeRepository?.id}`)
    .then((res)=>{
      setListOfRepositoryResources(res?.data?.data)
    })
    .catch((err)=>{
      dispatch(toast('error', err?.response?.data?.message))
    }).finally(()=>setResourcesLoad(false))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRepository])

  useEffect(()=>{
    activeRepository && getRepositoryResources()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRepositoryResources])

  return (
    <>
     <div className={styles.filesDragAndDropArea} onDragOver={handleDragOver} onDrop={handleDrop}>
        {listFiles.length > 0 ?
          <ul className={styles.choosen}> 
            {listFiles.map((f, i)=>(
                <li className={styles.styleListing} key={i}>
                    <SvgSprite spriteID={'close'} className={styles.filesClose} onClick={()=>removeFile(i)}/>
                    {f?.name}
                </li>
            ))}
        </ul>
         : 
        <div className={styles.documentFormUoImage}>
            Drag & Drop to Upload File <br /> Or
            <div className={styles.upload}>
                <div className={styles.formUp}>
                    <input 
                        disable={(file.length > 5)+''}
                        type="file" 
                        accept=".pdf,.doc,.docx,.jpg,.jpeg,.png,.gif"
                        multiple
                        name="attachment[]"
                        onChange={(e) => handleUpload(e)}/>
                    <div className={styles.customUploadBtn}> Browse Files </div>
                </div>
            </div>
            <div className={styles.formUp}>
                    <div className={cn(styles.customUploadBtn, styles.resourcesRow)} onClick={() => {setdisplayResources(true) }}> Browse Resources </div>
                </div>
        </div>
        
        }
      </div>
      <Popup big={true} isOpenedPopup={displayResources} closePopup={() => {handelClose(); setActiveRepository(null)}}> 
        <div className={styles.addPopupFramwork}>
            <div className={styles.topPopupAddFramwork}>
                <div className={styles.addframworkTitle}> Attach resources </div>
                <div className={styles.searchFramworkPopup}>
                    <Search setSearch={setSearch} className={styles.search} placeholder={'Search Resources'}/>                       
                </div>
                {/* <div className={styles.addframworkTitleCenter}> {ids?.length}/{resources?.filter(r => r.organization_id)?.length} Selected</div> */}
            </div>

            {!dotsLoader ?<>
              {!activeRepository && (
                  (data?.length > 0) ? (
                      <div className={cn(styles.gridRow, styles.individualPlan)}>
                          {listRepositories?.map((r, i) => (
                              <div key={i} className={cn(styles.gridItem)} onClick={() => setActiveRepository(r)}>
                                  <div className={styles.bodyTypeContainer}>
                                      <SvgSprite spriteID={'folder'} className={styles.folderStyles} />
                                  </div>
                                  <div className={styles.centerFrameworkName}>{r.name} </div>
                              </div>
                          ))}
                          {(data?.filter(r => r.organization_id != null) || []).map((r, i) => (
                              <div key={i} className={cn(styles.gridItem, {
                                  [styles.selectedFramwork]: ids.includes(r.id),
                              })} onClick={() => select(r.id)}>
                                  <div className={styles.bodyTypeContainer}>
                                      <div className={styles.extention}>{getExt(r.policyDocument)}</div>
                                  </div>
                                  <div className={styles.centerFrameworkName}>{r.policyName} </div>
                              </div>
                          ))}
                      </div>
                  ) : (
                      <div className={styles.NoResourcesFound}>
                          {repositoryLoad ? <div className={cn(styles.loaderResources)}><DotsLoader /></div> : 'No Resources Found' }
                      </div>
                  )
                )}
              {activeRepository && <div className={styles.repositoryPathDiv}> 
                  <div className={styles.repositoryPath} onClick={()=>{setActiveRepository(null);setListOfRepositoryResources([])}}>{"Resources "}</div> 
                  <div className={styles.repositoryPath}>       
                  <pre>{" / "}</pre>
                  </div>  
                  <div className={styles.repositoryPath}>{activeRepository?.name}</div>
              </div>}

              {activeRepository && <div className={styles.hFull}>        
                  {!resourcesLoad &&
                    (listOfRepositoryResources?.resources?.length === 0 && listOfRepositoryResources?.children?.length === 0) && 
                      <div className={styles.NoResourcesFound}> 
                          No Resources Found 
                      </div>
                  }
                  {resourcesLoad && (
                    <div className={styles.NoResourcesFound}> 
                      <DotsLoader/> 
                    </div>
                  )}
                  {!resourcesLoad &&  <div className={cn(styles.gridRow, styles.individualPlan)}>
                      {listOfRepositoryResources?.children?.map((r, i) => (
                          <div key={i} className={cn(styles.gridItem)} onClick={() => setActiveRepository(r)}>
                              <div className={styles.bodyTypeContainer}>
                                  <SvgSprite spriteID={'folder'} className={styles.folderStyles} />
                              </div>
                              <div className={styles.centerFrameworkName}>{r.name} </div>
                          </div>
                      ))}
                      {listOfRepositoryResources?.resources?.map((r, i) => (
                          <div key={i} className={cn(styles.gridItem, {
                              [styles.selectedFramwork]: ids.includes(r.id),
                          })} onClick={() => select(r.id)}>
                              <div className={styles.bodyTypeContainer}>
                                  <div className={styles.extention}>{getExt(r.policyDocument)}</div>
                              </div>
                              <div className={styles.centerFrameworkName}>{r.policyName} </div>
                          </div>
                      ))}
                    </div>}
              </div>}
            <div className={styles.listBtnsAddframework}>
                <Btn className={cn(styles.addBtn, styles.backroundFrameworkCancel)} onClick={()=> {handelClose(); setActiveRepository(null)}}>Cancel</Btn>
                <Btn disable={loader || resources?.length <= 0} className={cn(styles.addBtn, styles.addPadding)} noHover onClick={()=> handleAddResources() }>
                    <div>Update Resources</div>
                    {loader && <Loader />}</Btn>
            </div>
            </>
            :
              <div className={cn(styles.gridRow, {
                [styles.individualPlan] : true
              })}>
                <div className={styles.NoResourcesFound}> 
                  <DotsLoader />
                </div>
              </div>
            }
        </div>
      </Popup>
    </>
  );
}