/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { DonutChart } from './DonutChart'
import styles from './index.module.scss'
import styled from 'styled-components';
import { DotsLoader } from '../Loader';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../services/api';

const Li = styled.li`
  &:after {
    content: "";
    position: absolute;
    top: 2px;
    left: -25px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: ${props => props.color};
    color: ${props => props.color};
    font-weight: bold;
  }
`;
export const DonutChartComp = () => {

  const donutDataQuery = useQuery({
    queryKey : ['donutData'],
    queryFn : () => api.fetchData("donutData")
  })

  return donutDataQuery.isSuccess ? 
      <div className={styles.container}>
          <div className={styles.donutChart}>
              <DonutChart data={donutDataQuery.data.reportData} />
          </div>
          <div className={styles.dataChart}>
              <div>
                  <div className={styles.stats}>
                      <ul>
                        {donutDataQuery.data?.reportData?.map( (data, i) => (
                          <Li key={i} color={data.color}>
                            <div>{data.state}</div>
                            <div style={{color: data.color}}>{data.percentage}%</div>
                          </Li>
                        ))}
                      </ul>
                  </div>
              </div>
          </div>
      </div>
  :
  <div className={styles.loaderContainer}><DotsLoader /></div>
}
