import { setControl, setControlIsLoading, setNbrOfControls } from '../../store/slices/controlSlice'
import { axiosInstance } from '../config/https'

export const getControls = () => {
    return async dispatch => {
        dispatch(setControlIsLoading(true))
        const res = await axiosInstance(`control`)
        
        if(res.status === 200){
            dispatch(setNbrOfControls(res.data.count))
            dispatch(setControl(res.data.controls))
        }
        dispatch(setControlIsLoading(false))
    }
}