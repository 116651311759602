import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

// Pages
import { Home } from '../core/modules/Home'
import { NotFound } from '../core/status/NotFound'
import { Calendar } from '../core/modules/Calendar'
import { Settings } from '../core/modules/Settings'
import { Plan } from '../core/modules/Plan'
import { AllReport, Report } from '../core/modules/Report'
import { Resources } from '../core/modules/Resources'
import { Login } from '../core/modules/Login'
import { Invite } from '../core/modules/Invite'
import { DocumentForm } from '../core/modules/Documents/DocumentForm'
import { ShowReport } from '../core/modules/Report/ShowReport'
import { NewFramework } from '../core/modules/Documents/NewFramework'
import { About } from '../core/modules/About'
import { ControlsAssigned } from '../core/modules/Documents/ControlsAssigned'
import Controls from '../core/modules/Controls/Controls'
import { ControlsActions } from '../core/modules/Controls/ControlsActions'

export const MainRoutes = () => {

    return (
        <Routes>
            <Route path='/home' element={<Home />} exact/>
            <Route path='/controls/:id' element={<Controls />} exact />
            <Route path='/framework/control/:controlId/submission' element={<DocumentForm />} exact/>
            <Route path='/report/new' element={<Report />} exact />
            <Route path='/report/all' element={<AllReport />} exact />
            <Route path='/report/:id' element={<ShowReport />} exact />
            <Route path='/plan' element={<Plan /> } exact />
            <Route path='/resources' element={<Resources />} exact />
            <Route path='/calendar' element={<Calendar />} exact />
            <Route path='/help/*' element={<Settings />} exact />
            <Route path='/about' element={<About />} exact />
            <Route path='/controls/assigned' element={<ControlsAssigned />} exact />
            <Route path='/controls/actions' element={<ControlsActions />} exact />

            {/* <Route path='/help' element={<Help />} exact /> */}
            {/* <Route path='/questionnaire' element={<Questionnaire />} exact /> */}
            <Route path='/login' element={<Login />} exact />
            <Route path='/invite' element={<Invite />} exact />
            <Route path='new-framework' element={<NewFramework />} exact />
            <Route path='/404' element={<NotFound/>} exact/>
            <Route path='*' element={<Navigate to='/home' replace/>}/>
        </Routes>
    )
}
