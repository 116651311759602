import React from 'react'
import styles from './index.module.scss'
import { SvgSprite } from '../../ui/SvgSprite'
import { Btn } from '../../ui/Btn'
import cn from 'classnames'
import { Loader } from '../../ui/Loader'

export const RemoveFramwork = ({framework, closePopup, handleRemove, loader}) => {
  return (
    <div>
        <div className={styles.addPopup}>
        <div className={styles.controlPopupHeader}>
            <div>Are you sure you want to delete these frameworks?</div>
            <SvgSprite spriteID={'close'} className={styles.closeIconPopup} onClick={closePopup}/>
        </div>
        <div className={styles.bodyframwo}>
            <div >
                {framework?.map((f, index)=>(
                    <div className={styles.bodyFameworkRemove} key={index}>{f.name}</div>
                ))}
            </div>
            <div className={styles.controlPopupBody}>
                <Btn className={cn(styles.fileBtn, styles.cancelBtn)} onClick={closePopup}>Cancel</Btn>
                <Btn disable={loader} className={cn(styles.fileBtn, styles.removeBtn)} onClick={handleRemove}>
                    {loader ? <Loader /> : <div>Confirm</div>}</Btn>
            </div>
           
        </div>
    </div>
    </div>
  )
}
