import { setAllFramework } from '../../store/slices/allFrameworkSlice'
import { axiosInstance } from '../config/https'

export const setAllFrameworkFunction = () => {
    return async dispatch => {
        const res = await axiosInstance(`all/framework`)
        
        if(res.status === 200){
            dispatch(setAllFramework(res.data.frameworks))
        }
            
    }
}
