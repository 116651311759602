// ControlsBody.js
import React, { useEffect, useState } from "react";
import styles from "./ControlsBody.module.scss";
import { Status } from "./Status";
import { ProfileImage } from "../../ui/ProfileImage/ProfileImage";
import cn from "classnames";
import { sortControlsFn } from "../../functions/sortControls";
import { useNavigate } from "react-router-dom";
import { SvgSprite } from "../../ui/SvgSprite/SvgSprite";
import { useDispatch } from "react-redux";
import { toast } from "../../functions/toast";

const ControlsBody = ({ controls, activeTab, risk, users, sortControls, setSortControls, search, searchControls }) => {
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");
  const navigate = useNavigate();
  const [selectedLabels, setSelectedLabels] = useState(["Owner", "Name", "Description", "Risk", "Status"]);
  const [openEye, setOpenEye] = useState(false);
  const labels = ["Owner", "Name", "Description", "Risk", "Status", "Articles"];
  const dispatch = useDispatch();

  const handleSortingChange = (accessor, order) => {
    setOrder(order);
    setSortField(accessor);
    setSortControls(sortControlsFn(sortControls, accessor, order));
  };

  const updateListLabels = label => {
    const updatedLabels = selectedLabels.includes(label)
      ? selectedLabels.filter(l => l !== label)
      : [...selectedLabels, label];

    if (updatedLabels.length < 3) {
      dispatch(toast("error", "At least Three labels must be selected"));
      return;
    }

    localStorage.setItem("labels", JSON.stringify(updatedLabels));
    setSelectedLabels(updatedLabels);
  };

  useEffect(() => {
    var labels = localStorage.getItem("labels");
    setSelectedLabels(JSON.parse(labels) || selectedLabels);
    // eslint-disable-next-line
  }, []);

  const renderSortableHeader = (label, accessor) => (
    <div
      className={cn(styles.item, styles.sortItem)}
      key={accessor}
    >
      <div>{label}</div>
      <div className={styles.sortable}>
        <div
          className={cn(styles.default, { [styles.up]: sortField === accessor && order === "asc" })}
          onClick={() => handleSortingChange(accessor, "asc")}
        >
          {" "}
          {`\u25B2`}{" "}
        </div>
        <div
          className={cn(styles.default, { [styles.down]: sortField === accessor && order === "desc" })}
          onClick={() => handleSortingChange(accessor, "desc")}
        >
          {" "}
          {`\u25BC`}{" "}
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.body}>
      <div className={cn(styles.controlItem, styles.sticky)}>
        {selectedLabels.includes("Owner") && <div className={cn(styles.item)}>Owner</div>}
        {selectedLabels.includes("Name") && renderSortableHeader("Name", "name")}
        {selectedLabels.includes("Description") && (
          <div className={cn(styles.item, styles.descriptionItem)}>Description</div>
        )}
        {selectedLabels.includes("Risk") && renderSortableHeader("Risk", "risk")}
        {selectedLabels.includes("Status") && renderSortableHeader("Status", "status")}
        {selectedLabels.includes("Articles") && activeTab !== "all-controls" && activeTab !== "non-applicable" && (
          <div className={cn(styles.item)}>Article Numbers</div>
        )}
        <div className={styles.eyeHeader}>
          <SvgSprite
            spriteID={"eye"}
            onClick={() => setOpenEye(!openEye)}
          />
          {openEye && (
            <div className={styles.selectFilter}>
              {labels.map(
                label =>
                  ((label === "Article Numbers" && activeTab !== "all-controls" && activeTab !== "non-applicable") ||
                    label !== "Article Numbers") && (
                    <div
                      key={`#${label}`}
                      value={label}
                      onClick={() => updateListLabels(label)}
                      className={cn(styles.itemFilter, { [styles.activeFilter]: selectedLabels?.includes(label) })}
                    >
                      {label}
                    </div>
                  ),
              )}
            </div>
          )}
        </div>
      </div>
      {(search?.length > 0 ? searchControls : sortControls)?.map(control => (
        <div
          key={control.control_id}
          className={styles.controlItem}
          onClick={() => {
            if (activeTab !== "non-applicable")
              navigate(`/framework/control/${control.control_id}/submission?frameworkId=${activeTab}`);
          }}
        >
          {selectedLabels.includes("Owner") && (
            <div className={styles.controlOwner}>
              {control.submission_owner ? (
                <ProfileImage
                  name={
                    users?.find(u => u.id === control.submission_owner)?.firstName +
                    " " +
                    users?.find(u => u.id === control.submission_owner)?.lastName
                  }
                  className={styles.ownerItem}
                />
              ) : (
                <ProfileImage
                  name={"N / A"}
                  className={styles.ownerItem}
                />
              )}
            </div>
          )}
          {selectedLabels.includes("Name") && <div className={cn(styles.item)}>{control.control_name}</div>}
          {selectedLabels.includes("Description") && (
            <div className={cn(styles.item, styles.descriptionItem)}>{control.control_description}</div>
          )}
          {selectedLabels.includes("Risk") && (
            <div className={cn(styles.item, styles.autoMargin)}>
              <div
                className={styles.riskComp}
                style={{
                  backgroundColor:
                    risk.find(
                      r => r.name === (control.submission_risk?.toLowerCase() || control.control_risk?.toLowerCase()),
                    )?.color || "#ccc",
                }}
              ></div>
            </div>
          )}
          {selectedLabels.includes("Status") && (
            <div className={styles.flexCenter}>
              <Status
                small={true}
                status={control.submission_status || 0}
              />
            </div>
          )}
          {selectedLabels.includes("Articles") && activeTab !== "all-controls" && activeTab !== "non-applicable" && (
            <div className={cn(styles.item, styles.itemArticles)}>
              {control.article_numbers?.length <= 2 &&
                control.article_numbers?.slice(0, 2).map((num, index) => (
                  <span key={index}>
                    {num}
                    {index < 1 && control.article_numbers.length > 1 && ", "}
                  </span>
                ))}
              {control.article_numbers?.length > 2 && (
                <div className={styles.tooltipContainer}>
                  {control.article_numbers?.slice(0, 2).map((num, index) => (
                    <span key={index}>
                      {num}
                      {index < 1 && control.article_numbers.length > 1 && ", "}
                    </span>
                  ))}
                  <span className={styles.tooltipTrigger}>...</span>
                  <span className={styles.tooltipContent}>{control.article_numbers.slice(2).join(" , ")}</span>
                </div>
              )}
            </div>
          )}
          <div></div>
        </div>
      ))}
    </div>
  );
};

export default ControlsBody;
