import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    allFrameworks: null,
}

const allFrameworkSlice = createSlice({
    name: 'allFrameworks',
    initialState,
    reducers: {
        setAllFramework(state, action) {
            state.allFrameworks = action.payload
        },
    }
})

export default allFrameworkSlice.reducer
export const { setAllFramework } = allFrameworkSlice.actions