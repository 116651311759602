import React, { useEffect, useState } from 'react'

// styles
import styles from './index.module.scss'
import Gpt3Form from '../../ui/Gpt3Form/Gpt3Form'
import { axiosInstance } from '../../config/https'
import { SvgSprite } from '../../ui/SvgSprite'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useSearchParams } from 'react-router-dom'
import { Loader } from '../../ui/Loader'
import { Btn } from '../../ui/Btn';
import { SettingModel } from '../SettingsPopup'
import {Popup} from '../../ui/Popup'
import { deleteCookie, getCookie, setCookie } from '../../utils/cookie'
import cn from 'classnames'
import { Input } from '../../ui/Input/Input'
import { useInput } from '../../hooks/useInput'
import { toast } from '../../functions/toast'

export const Settings = () => {
  const [queryParameters] = useSearchParams()
  const [chats, setChats] = useState([])
  const [activeChat, setActiveChat] = useState(null)
  const [messages, setMessages] = useState([]);
  const users = useSelector(state => state.organization.org.users)
  const accountPlan =useSelector(state => state.organization.org.account_plan)
  const [addSettingPopup, setAddSettingPopup] = React.useState(false)
  const [plan, setPlan] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const location  = useLocation()
  const [question, setQuestion] = useState(null)
  const [secondQuestion, setSecondQuestion] = useState(null)
  const [loadDelete, setLoadDelete] = useState(null)
  const [selectedUsers, setSelectedUsers] = useState([]);
  const shareMessage = useInput('')
  const [loadShare, setLoadShare] = useState()
  const [showDropShare, setShowDropShare] = useState(false)
  const dispatch = useDispatch()

  useEffect(()=>{
    queryParameters.get("chatId")
  }, [queryParameters])
  const getChats = ()=>{
      axiosInstance('chats')
      .then(res=>{
        setChats([...res.data.chats])
      }).catch((err)=>{
        console.log(err)
      })
  }

  const getMessage = (id)=>{
    if (id) {
      setCookie('active_chat', id)
      axiosInstance(`chats/${id}/messages`)
      .then(res=>{
        setMessages([...res.data.messages])
      }).catch((err)=>{
        console.log(err)
      })
    }
  }

  const delete_chat=(id)=>{
    setLoadDelete(id)
    axiosInstance.delete(`chats/${id}`)
    .then(()=>{
      getChats()
      setActiveChat(null)
      setMessages([])
    }).catch((err)=>{
      console.log(err);
    }).finally(()=>{
      setLoadDelete(false)
    })
  }

  useEffect(()=>{
    (async()=>{
      await Promise.all([
        getChats(),
      ]);
    })()
  },[])

  useEffect(()=>{
    if(location?.state?.firstQuestion){
      setQuestion(location?.state?.firstQuestion)
      setSecondQuestion(location?.state?.secondQuestion)
    }else if(location.state)
    {
      setQuestion(location.state)
    }
  }, [location.state])

  useEffect(()=>{
    const active_chat = getCookie('active_chat')
    getMessage(active_chat)
  }, [])

  const newChat = () => {
    setActiveChat(null)
    deleteCookie('active_chat')
    setMessages([])
  }

  const share_chat = (id) => {
    try {
      setLoadShare(id)
      const data = {
        chatId : id,
        listUsers : selectedUsers,
        message : shareMessage.value
      }

      axiosInstance.post('share/chat', data)
      .then(()=>{
        dispatch(toast('success', 'Chat successfully shared'))
      })
      .catch((err)=>{
        dispatch(toast('error', err?.response?.data?.message))
      })
      .finally(()=>{
        setLoadShare(null)
        setShowDropShare(false)
      })
    } catch (error) {
      setLoadShare(null)
      setShowDropShare(false)
    }
  }

  const handleUserChange = (event, userId) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedUsers([...selectedUsers, userId]);
    } else {
      setSelectedUsers(selectedUsers.filter((id) => id !== userId));
    }
  };

  return (
    <div className={styles.chatMain}>
      {(accountPlan?.toLowerCase() === 'individual' || accountPlan?.toLowerCase() === 'basic') ?
        <div className={styles.planfeature} >
          <div className={styles.planfeature_text} >This feature is not available in your current plan</div>
          <Btn className={styles.upgradeBtn} onClick={()=>{setAddSettingPopup(true);setPlan(true)}}>Upgrade Plan</Btn>
        </div>
      :
        <div className={styles.main}>
          <div className={styles.noReportMsg}>
            <Gpt3Form question={question} setQuestion={setQuestion} secondQuestion={secondQuestion} setSecondQuestion={setSecondQuestion} setActiveChat={setActiveChat} activeChat={activeChat} ListMessages={messages} getChats={getChats}/>  
          </div>
          <div className={styles.historiqueContainer}>
            <div className={styles.TilesHistorique} >
              <div className={styles.tilesSenderNewMessage} onClick={()=>{newChat()}}>
                <div className={styles.messageTilesHeader}>
                  <SvgSprite spriteID={'plus'} className={styles.addNewChatIcon}/>
                  <div> New Chat</div>
                </div>
              </div>
            </div>
            <div className={cn(styles.historiqueChat, {
              [styles.overflow] : showDropShare
            })}>
              {chats.map((chat, index)=>(
              <div 
                className={cn(styles.messageTilesHistorique, {
                  [styles.activeChat] : chat?.id === (activeChat?.id || parseInt(getCookie('active_chat')))
                })} 
                key={index} 
                onMouseEnter={()=>setShowDelete(chat.id)} onMouseLeave={()=>setShowDelete(false)}
              >
                <div className={styles.tilesSender} onClick={()=>{setActiveChat(chat); getMessage(chat.id)}}>
                  <div className={styles.messageTiles}>{chat?.title || 'No Title'}</div>
                  <div className={styles.messageSenderDate}> {users?.find(user => user.id ===chat.user_id)?.firstName +' '+ users?.find(user => user.id ===chat.user_id)?.lastName} </div>
                </div>
                <div className={styles.chatIcons}>
                  <div>
                    { showDelete === chat?.id && !loadShare &&
                        <SvgSprite 
                        spriteID={'share'} 
                        className={styles.removeIconHistorique}
                        onClick={()=>{setShowDropShare(showDropShare ? null : chat?.id)}}/>}
                    {loadShare === chat?.id && <Loader />}
                  </div>
                  <div>
                    {showDelete === chat?.id && !loadDelete && <SvgSprite 
                      spriteID={'remove'} 
                      className={styles.removeIconHistorique}
                      onClick={()=>delete_chat(chat?.id)}/>}
                    {loadDelete === chat?.id && <Loader />}
                  </div>
                </div>
                {showDropShare === chat?.id && 
                  <div className={cn(styles.actionsContent, styles.dropResourcActionsContent)}>
                    <div className={styles.dropHeader}>Share</div>
                    <ul className={styles.userList}>
                      {/* Render the list of users dynamically */}
                      {users.map((user) => (
                        <li key={user.id}>
                          <div className={styles.userItem}>
                            <div>
                              {user.firstName} {user.lastName}
                            </div>
                            <input
                              type="checkbox"
                              checked={selectedUsers.includes(user.id)}
                              onChange={(event) => handleUserChange(event, user.id)}
                            />
                          </div>
                        </li>
                      ))}
                    </ul>
                    <Input 
                      placeholder={'Message'}
                      comment={true}
                      type={'text'}
                      className={styles.messateText}
                      onChange={shareMessage.onChange} 
                      />
                    <Btn className={styles.btnSaveMessage} onClick={()=>share_chat(chat?.id)} disable={loadShare}> {loadShare ? <Loader /> : 'Save' }</Btn>
                  </div>
                }
              </div>
            ))}
            </div>
          </div>
        </div> 
      }
      {addSettingPopup && <Popup extraBig  grayBackground isOpenedPopup={addSettingPopup} closePopup={() => setAddSettingPopup(false)}>
        <SettingModel
          closePopup={() => setAddSettingPopup(false)}
          plan={plan}
          setPlan={setPlan}
        />
      </Popup>}
    </div>
  )
}
