
import { Component } from 'react';
import { redirect } from "react-router-dom";

export class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, info) {
      console.log(error, info.componentStack);
    }
  
    render() {
      if (this.state.hasError) {
        return redirect("/auth/login");
      }
  
      return this.props.children;
    }
  }