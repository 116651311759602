import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from "./index.module.scss";
import { Btn } from '../../ui/Btn';
import { setCookie } from '../../utils/cookie';
import { setAccountPlan, setIsAuth, setNotificationNabr, setQrCode, setTeamSize, setUserInfo } from '../../../store/slices/userSlice';
import { toast } from '../../functions/toast';
import { Select } from '../../ui/Select/Select';
import cn from 'classnames'
import { axiosInstance } from '../../config/https';
import { Loader } from '../../ui/Loader/Loader';
import { Input } from '../../ui/Input/Input';

export const Subscription = () => {

  const [number, setNumber] = useState('');
  const [cvc, setCvc] = useState('');
  const [type, setType] = useState('month');
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const currentUser = useSelector(state => state.user.userInfo)
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [monthYear, setMonthYear] = useState('');

  const [seats] = useState([
    {
        id: 0,
        name:  '3 seats',
        value: 3,
    },
    {
        id: 1,
        name:  '5 seats',
        value: 5,
    },
    {
        id: 2,
        name:  '10 seats',
        value: 10,
    },
    {
        id: 3,
        name:  '15 seats',
        value: 15,
    },
    {
        id: 4,
        name:  '20 seats',
        value: 20,
    },
    {
        id: 5,
        name:  '25 seats',
        value: 25,
    },
    {
        id: 6,
        name:  '30 seats',
        value: 30,
    },
    {
        id: 7,
        name:  '40 seats',
        value: 40,
    },
    {
        id: 8,
        name:  '50 seats',
        value: 50,
    },
    {
        id: 9,
        name:  '100 seats',
        value: 100,
    },
   ]
)

const [newPlan, setNewPlan] = useState()
const [newSeat, setNewSeat] = useState()
const [price, setPrice] = useState(0)
const [listPlan] = useState([
    {
        id: 0,
        name:  'Individual',
        value: 'individual',
    },
    {
        id: 1,
        name: 'Basic',
        value: 'basic',
    },
    {
        id: 2,
        name:  'Standard',
        value: 'standard',
    },
   ]
)

const [listType] = useState([
  {
      id: 0,
      name:  'Monthly',
      value: 'month',
  },
  {
      id: 1,
      name: 'Yearly',
      value: 'year',
  },
 ]
)

  useEffect(()=>{
    // const plan = location.state.account_plan
    switch (newPlan) {
      case 'basic':
        if(type === 'month'){
          setPrice(11*newSeat)
        }else{
          setPrice(108*newSeat)
        }
        break;
      case 'standard':
          if(type === 'month'){
            setPrice(17*newSeat)
          }else{
            setPrice(180*newSeat)
          }
        break;
      case 'individual':
        setPrice(0)
        break;
      default:
        break;
    }
  }, [location, newPlan, type, newSeat])


  const handleSubmit = async (e) => {
    const date = new Date(monthYear);
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    let mode = '';
    if (process.env.NODE_ENV === 'development') {
      mode = 'test'
    }
    setLoading(true)
    if (newPlan !== 'individual') {
      if(!(newPlan && number && month && year  && cvc && type)){
        setLoading(false)
        return
      }
      e.preventDefault();
      try {
        const formData = {
          plan : newPlan,
          number : parseInt(number),
          exp_month: month,
          exp_year: year,
          cvc : parseInt(cvc),
          type : type,
          userId : currentUser.id,
          quantity : newPlan === 'individual' ? 2 : parseInt(newSeat),
          mode : mode
        };
        await axiosInstance.post('subscribe', formData).then((res)=>{
          setCookie('access_token', res.data.access_token, res.data.expires_in, true)
          dispatch(setUserInfo({...res.data.user}))
          dispatch(setQrCode(res.data.qr_code_url))
          dispatch(setAccountPlan(res.data?.account_plan))
          dispatch(setNotificationNabr(res.data.notifications_nbr))
          dispatch(setTeamSize(res.data.team_size))
          dispatch(setIsAuth(true))
          navigate('/dashboard')
        }).catch((err)=>{
          dispatch(toast('error', err?.response?.data?.message))
        }).finally(()=>{
          setLoading(false)
        })
      } catch (error) {
        dispatch(toast('error', error));
        setLoading(false)
      } 
    } else {
      e.preventDefault();
      try {
        const formData = {
          plan : newPlan,
          userId : currentUser.id,
          mode : mode
        };
        await axiosInstance.post('/subscribe/individual', formData).then((res)=>{
          setCookie('access_token', res.data.access_token, res.data.expires_in, true)
          dispatch(setUserInfo({...res.data.user}))
          dispatch(setQrCode(res.data.qr_code_url))
          dispatch(setAccountPlan(res.data?.account_plan))
          dispatch(setNotificationNabr(res.data.notifications_nbr))
          dispatch(setTeamSize(res.data.team_size))
          dispatch(setIsAuth(true))
          navigate('/dashboard')
        }).catch((err)=>{
          dispatch(toast('error', err?.response?.data?.message))
        }).finally(()=>{
          setLoading(false)
        })
      } catch (error) {
        dispatch(toast('error', error));
        setLoading(false)
      } 
    }
    setLoading(false)
  };

  return (
    <div className={styles.container}> 
      <div className={styles.formContainer}> 
        <div className={styles.leftside}>
          <div className={styles.leftside_content}>
            <div className={styles.top_type_header}>
              <div className={styles.logo} style={{marginBottom: '30px'}}></div> 
              <div className={styles.left_header}> Purchase {newPlan?.charAt(0).toUpperCase() + newPlan?.slice(1)} Plan For SecureLabs.ai </div>
              <div className={styles.pricing}>
              <div className={styles.price}>{price || 'Free'}{price ? '$':''}</div>
              <div className={styles.per}>
              <div>per</div>
              <div>{type}</div>
            </div>
          </div>                    
        </div>
        <div className={styles.bottom_type_header}>
          <div>
            <div className={styles.price_type}>
              <div>{newPlan?.charAt(0).toUpperCase() + newPlan?.slice(1)}</div>
              <div>{price || 'Free'}{price ? '$':''}</div>
            </div>
            {/* <div className={styles.price_type_under}>
              <div>Qty 3Billed monthly</div>
              <div>$17.00 each</div>
            </div> */}
          </div>
          <div>
            <div className={styles.price_type_sub}>
              <div>Subtotal</div>
              <div>{price || 'Free'}{price ? '$':''}</div>
            </div>
            <div className={styles.price_type_tax}>
              <div>Tax</div>
              <div>$0.00</div>
            </div> 
            <div className={styles.price_type}>
              <div>Total due today</div>
              <div>{price || 'Free'}{price ? '$':''}</div>
            </div>
            <div className={styles.Powered}>Powered by Stripe</div>
          </div>
        </div>
        </div>
      </div>
      <div className={styles.rightside}>
        <div className={styles.rightside_content}>
          <div className={styles.right_header}>Pay with card</div>
            <div>
              <div>
                <div className={styles.input_Plan}>
                  <div>Plan</div>
                  <Select selectFullWidth 
                    className={cn(styles.inputer_plan)}  
                    setNewValue={setNewPlan} 
                    initValue={location?.state?.account_plan}
                    values={listPlan}/>
                </div>
                <div className={styles.input_Size}>
                  <div className={styles.label}>Subscription Type :</div>
                  <Select selectFullWidth 
                    className={cn(styles.inputer)}  
                    setNewValue={setType} 
                    initValue={'month'}
                    values={listType}/>
                </div>
                <div className={styles.input_Size}>
                  <div className={styles.label}>Choose Team Size : </div>
                  <Select selectFullWidth 
                    className={cn(styles.inputer)}  
                    setNewValue={setNewSeat} 
                    initValue={location?.state?.team_size}
                    values={seats}/>
                </div>
                <div className={styles.input_email}>
                  <div>Card information</div>
                    <Input className={styles.cardnumber}
                      type="text" 
                      pattern="[0-9]{16}"
                      maxlength="16"
                      id="number" 
                      placeholder="1234 1234 1234 1234"
                      value={number} 
                      onChange={(e) => setNumber(e.target.value)} />
                  <div className={styles.cardMC}>
                    <Input className={styles.mm}
                      type="month" 
                      id="expYear" 
                      value={monthYear} 
                      onChange={(e) => setMonthYear(e.target.value)}/>
                    <Input className={styles.cvc} 
                      type="text" 
                      minlength="3" 
                      maxlength="4" 
                      placeholder="cvc"
                      id="cvc" 
                      value={cvc} 
                      onChange={(e) => setCvc(e.target.value)} />
                  </div>
                </div>
              </div>
              <div>
                <div>
                </div>
                {/* <div className={styles.SignUpForm}>
                        <input className={styles.checkboxForm} type="checkbox"/>
                    <div className={styles.SignUpForm_text}>
                        <div className={styles.SignUpForm_text_header}>Securely save my information for 1-click checkout</div>

                        <div className={styles.SignUpForm_text_content}>Pay faster on SECURELABS INC and everywhere Link is accepted.</div>
                    </div>
                </div> */}
              </div>
              <div className={styles.submiter}>
                <div>
                  <Btn type="submit" onClick={handleSubmit} className={styles.pyBtn}>{loading ? <Loader /> : `Submit Purchase`}</Btn>
                  <div className={styles.ConfirmTerms}>By confirming your subscription, you allow SECURELABS INC to charge your card for this payment and future payments in accordance with their terms. You can always cancel your subscription.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className={styles.footer}>
          <nav>
              <ul className={styles.footerNav}>
                <li><a href="https://app.termly.io/document/terms-of-use-for-website/f78e9e9b-bba1-4a94-983e-68a1005f28b0">Terms of Service</a></li>
                <li><a href="https://app.termly.io/document/privacy-policy/3754276d-7c3f-40ef-931d-584c9bc61e74">Privacy Policy</a></li>
              </ul>
          </nav>
          <br></br>
          <h5>© 2023 SecureLabs Inc </h5>
      </footer>
    </div>
  )
}
