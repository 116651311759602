import React, { useEffect, useState } from 'react'
import cn from 'classnames'

// styles
import styles from './index.module.scss'

export const ProfileImage = ({name, className, onClick}) => {
    const [newName, setNewName] = useState(null)

    const hashCode = (str)=>{
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
         hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      return hash;
  } 
  
  const intToRGB = (i)=>{
      var c = (i & 0x00FFFFFF)
          .toString(16)
          .toUpperCase();
  
      return "00000".substring(0, 6 - c.length) + c;
  }
    useEffect(()=>{
        setNewName(name?.toString()?.split(' ')?.map(name => name[0])?.join('')?.toUpperCase())
      }, [name])
  return newName && (
    <>
        <div  onClick={onClick} className={cn(styles.profileImage, {[className] : className})} style={{background:name?.toString() === 'N / A' ? '#7D8FB3' : '#'+intToRGB(hashCode(name?.toString()))}}> {newName?.substring(0,2)} </div>
    </>

  )
}
