import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    org: {},
    isLoading : false,
    number_of_incident_reports : 0,
    number_of_frameworks : 0,
    number_of_controls : 0,
    number_of_controls_completed : 0,
    number_of_controls_in_Progress : 0,
    number_of_controls_need_attention : 0,
    number_of_controls_not_started : 0,
}

const orgsSlice = createSlice({
    name: 'organization',
    initialState,
    reducers: {
        setOrgIsLoading(state, action){
            state.isLoading = action.payload
        },

        setOrg(state, action) {
            state.org = action.payload
        },

        setNumberOfControlsNeedAttention(state, action){
            state.number_of_controls_need_attention = action.payload
        },

        setNumberOfControlsInProgress(state, action){
            state.number_of_controls_in_Progress = action.payload
        },

        setNumberOfControlsCompleted(state, action){
            state.number_of_controls_completed = action.payload
        },

        setNumberOfControlsNotStarted(state, action){
            state.number_of_controls_not_started = action.payload
        },

        setNumberOfControls(state, action){
            state.number_of_controls = action.payload
        },

        setNumberOfFrameworks(state, action){
            state.number_of_frameworks = action.payload
        },

        setNumberOfIncidentReports(state, action){
            state.number_of_incident_reports = action.payload
        },

        setUpdatedOrgUser(state, action){
            state.org.users = state.org.users.map(user => {
                if(user.id === action.payload.id){
                    return action.payload
                }else{
                    return user
                }
            })
        },
        setCreatedOrgUser (state, action){
            state.org.users.push(action.payload)
        },
        setDeletedOrgUser(state, action){
            state.org.users = state.org.users.filter(user => user.id !== action.payload)
        }

    }
})

export default orgsSlice.reducer
export const { 
            setOrgIsLoading, 
            setOrg, 
            setNumberOfControls,
            setNumberOfFrameworks,
            setNumberOfIncidentReports,
            setNumberOfControlsCompleted, 
            setNumberOfControlsInProgress, 
            setNumberOfControlsNeedAttention, 
            setNumberOfControlsNotStarted, 
            setUpdatedOrgUser, 
            setCreatedOrgUser, 
            setDeletedOrgUser 
        } = orgsSlice.actions
