import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card } from '../../ui/Card/Card'
import { List } from '../../ui/List'
import styles from './index.module.scss'
import cn from 'classnames'
import { DonutChartComp } from '../../ui/DonutChart'
import { ChartBar } from '../../ui/ChartBar'
import { getControlsState } from '../../functions/getControlsState'
import { ProfileImage } from '../../ui/ProfileImage/ProfileImage'
import { getEvents } from '../../functions/getEvents'
import { SvgSprite } from '../../ui/SvgSprite'
import { DotsLoader } from '../../ui/Loader'
import { useNavigate } from 'react-router-dom'

export const Home = () => {
  const users = useSelector(state => state.organization.org.users)
  const org = useSelector(state => state.organization)
  const allEvents = useSelector(state => state.events)
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(false)
  const [dropSort, setDropSort] = useState(false)
  const dropdownRef = useRef()
  const dropdownRefStatus = useRef()
  const dropdownRefType = useRef()
  const [sortBy, setSortBy] = useState('all')
  const [events, setEvents] = useState([])
  const [dropFilter, setDropFilter] = useState(false)
  const [dropStatus, setDropStatus] = useState(false)
  const [dropType, setDropType] = useState(false)
  const [dropStatusValue, setDropStatusValue] = useState("all")
  const [dropTypeValue, setDropTypeValue] = useState("all")

  const status = {
    all : "All status",
    pending : "Pending",
    completed : "Completed"
  }

  const types = {
    all : "All types",
    general : "General",
    audit : "Audit",
    review : "Review",
    task : "Task",
    incident : "Incident"
  }

  const EventType = {
    general :  '#8833FF',
    audit : '#E62E2E',
    review : '#33BFFF',
    task : '#29CC39',
    incident : "#000000"
  }

  const getHomeData = useCallback(async() => {
    setLoader(true)
    await Promise.all([
        dispatch(getControlsState()),
        dispatch(getEvents())
    ]).finally(()=>{
      setLoader(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

    useEffect(() => {
      getHomeData()
    }, [getHomeData])

    useEffect(() => {
      const handleClickOutside = (event) => {
        // Close the dropdown if the click is outside the dropdown
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setDropSort(false);
        }

        if (dropdownRefStatus.current && !dropdownRefStatus.current.contains(event.target)) {
          setDropStatus(false);
        }

        if (dropdownRefType.current && !dropdownRefType.current.contains(event.target)) {
          setDropType(false);
        }
      };
  
      // Attach the event listener
      document.addEventListener('mousedown', handleClickOutside);
  
      // Detach the event listener when the component unmounts
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dropdownRef]);

    const sortEvents = useCallback(() => {
      setDropSort(false);
    
      const currentDate = new Date();
    
      switch (sortBy) {
        case "all":
          allEvents.events && setEvents(filterEvents(allEvents.events, dropStatusValue, dropTypeValue)) 
          break;
        case "week":
          allEvents.events && setEvents(filterEventsByWeek(allEvents.events, currentDate, dropStatusValue, dropTypeValue));
          break;
        case "month":
          allEvents.events && setEvents(filterEventsByMonth(allEvents.events, currentDate, dropStatusValue, dropTypeValue));
          break;
        case "year":
          allEvents.events && setEvents(filterEventsByYear(allEvents.events, currentDate, dropStatusValue, dropTypeValue));
          break;
        default:
          break;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allEvents.events, sortBy, dropStatusValue, dropTypeValue]);
    
    useEffect(() => {
      sortEvents();
    }, [sortEvents]);

    const filterEvents = (events, dropStatusValue, dropTypeValue) => {
      return events?.length > 0 && events?.filter(e => 
        (dropStatusValue === "all" || (dropStatusValue === "completed" && e.completed_at) || (dropStatusValue === "pending" && !e.completed_at)) &&
        (dropTypeValue === "all" || e.color === EventType[dropTypeValue])
      )
    }
    
    const filterEventsByWeek = (events, currentDate, dropStatusValue, dropTypeValue) => {
      return events?.length > 0 && events?.filter(e => {
        const eventDate = new Date(e.startTime);
        const startOfWeek = new Date(currentDate);
        startOfWeek.setHours(0, 0, 0, 0);
        startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(endOfWeek.getDate() + (7 - startOfWeek.getDay()));
        return isEventInRange(e, eventDate, startOfWeek, endOfWeek, dropStatusValue, dropTypeValue);
      });
    };
    
    const filterEventsByMonth = (events, currentDate, dropStatusValue, dropTypeValue) => {
      return events?.length > 0 && events?.filter(e => {
        const eventDate = new Date(e.startTime);
        const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    
        return isEventInRange(e, eventDate, startOfMonth, endOfMonth, dropStatusValue, dropTypeValue);
      });
    };
    
    const filterEventsByYear = (events, currentDate, dropStatusValue, dropTypeValue) => {
      return events?.length > 0 && events?.filter(e => {
        const eventDate = new Date(e.startTime);
        const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
        const endOfYear = new Date(currentDate.getFullYear(), 11, 31);
    
        return isEventInRange(e, eventDate, startOfYear, endOfYear, dropStatusValue, dropTypeValue);
      });
    };
    
    const isEventInRange = (e, eventDate, start, end, dropStatusValue, dropTypeValue) => {
      return (
        (eventDate >= start && eventDate <= end) &&
        (dropStatusValue === "all" || (dropStatusValue === "completed" && e.completed_at) || (dropStatusValue === "pending" && !e.completed_at)) &&
        (dropTypeValue === "all" || e.color === EventType[dropTypeValue])
      );
    };

  return (
    <div>
      <div className={styles.dashboard}>
        <div className={styles.headerCard}>
          <Card className={styles.item1} loader={org.isLoading} key={1} name={'Frameworks'} color='#8833FF' number={org?.number_of_frameworks} big to={`/controls/all-controls`}/>
          <Card className={styles.item2} loader={org.isLoading} key={2} name={'Controls'} color='#FF6633' number={org?.number_of_controls} big to={`/controls/all-controls`}/>
          <Card className={styles.item3} loader={org.isLoading} key={3} name={'Incident Reports'} color='rgb(80 84 92)' number={org.number_of_incident_reports} big to={'/report/all'}/>
        </div>
        <div className={styles.bodyContent}>
          <div className={styles.riegthContent}>
            <div className={styles.cards}>
              <Card className={cn(styles.item1, styles.smallCardStyles , styles.equelHight)} loader={org.isLoading} key={4} name={'Controls Completed'} color='#6DD400' number={org?.number_of_controls_completed} small/>
              <Card className={cn(styles.item2, styles.smallCardStyles , styles.equelHight)} loader={org.isLoading} key={5} name={'Controls In-Progress'} color='#FACA00' number={org?.number_of_controls_in_Progress} small/>
              <Card className={cn(styles.item3, styles.smallCardStyles , styles.equelHight)} loader={org.isLoading} key={6} name={'Controls Need Attention'} color='#E02020' number={org?.number_of_controls_need_attention} small/>
              <Card className={cn(styles.item4, styles.smallCardStyles , styles.equelHight)} loader={org.isLoading} key={7} name={'Controls Not Started Yet'} color='#7D8FB3' number={org?.number_of_controls_not_started} small/>
            </div>
            {/* Risk Card */}
            <div className={styles.card}>
              <div className={styles.cardHeader}>
                <div className={styles.cardHeaderText}>{"Risk"}</div>
                {/* <SvgSprite spriteID={'more'} className={styles.moreSvg}/> */}
              </div>
              <div className={cn(styles.cardBody, styles.chartPadding)}>
                <ChartBar />
              </div>
            </div>

            {/* Incident Report Card */}
            <div className={styles.card}>
              <div className={cn(styles.cardHeader, styles.noBorder)}>
                <div className={styles.cardHeaderText}>{"Incident Reports"}</div>
                {/* <SvgSprite spriteID={'more'} className={styles.moreSvg}/> */}
              </div>
              <div className={styles.chart}>
                <DonutChartComp />
              </div>
            </div>
          </div>
          
          <div className={styles.leftContent}>
            <div className={styles.list}>
              <div className={styles.headerWithFilter}>
                <div className={cn(styles.headerList, styles.item)}>
                  <div className={styles.headerText}>Calendar</div>
                  <div className={cn(styles.resourcesHeaderIcon, styles.resourcesHeaderIconFilter)}>
                    <div className={cn(styles.sortByDate, styles.FilterByDate)} onClick={()=> setDropFilter(!dropFilter)}> 
                      <SvgSprite spriteID={'filter'} className={styles.arrowStyle}/>
                      <div> Filter </div>
                    </div>
                  </div>
                </div>
                {dropFilter && <div className={styles.listOfFilter}>
                  <div className={styles.resourcesHeaderIcon}>
                    <div className={styles.sortByDate} onClick={()=> setDropSort(!dropSort)}> 
                      <div> {sortBy === "all" ? "LifeTime" : `This ${sortBy}` } </div>
                      <SvgSprite spriteID={'arrow'} className={cn(styles.arrowStyle, styles.iconMargin)}/>
                    </div>
                    {dropSort && 
                      <div ref={dropdownRef} className={cn(styles.actionsContent, styles.dropResourcActionsContent)}>
                        <div className={cn(styles.dropResourceBtn)} onClick={()=> {setSortBy('all'); sortEvents()}}>
                           <div> Lifetime </div>
                           { sortBy === "all" && <SvgSprite spriteID={"check"} className={styles.checkStyles} /> }
                        </div>
                        <div className={cn(styles.dropResourceBtn)} onClick={()=> {setSortBy('week'); sortEvents()}}>
                           <div> This week </div>
                           { sortBy === "week" && <SvgSprite spriteID={"check"} className={styles.checkStyles} /> }
                        </div>
                        <div className={cn(styles.dropResourceBtn)} onClick={()=> {setSortBy('month'); sortEvents()}}>
                          <div> This month </div>
                          { sortBy === "month" && <SvgSprite spriteID={"check"} className={styles.checkStyles} /> }
                        </div>
                        <div className={cn(styles.dropResourceBtn)} onClick={()=> {setSortBy('year'); sortEvents()}}>
                          <div> This year </div>
                          { sortBy === "year" && <SvgSprite spriteID={"check"} className={styles.checkStyles} /> }
                        </div>
                      </div>
                    }
                  </div>
                  <div className={cn(styles.resourcesHeaderIcon, styles.resourcesHeaderIconFilter)}>
                    <div className={cn(styles.sortByDate)} onClick={()=> setDropStatus(!dropStatus)}> 
                      <div>  {status[dropStatusValue]} </div>
                      <SvgSprite spriteID={'arrow'} className={cn(styles.arrowStyle, styles.iconMargin)}/>
                    </div>
                    {dropStatus && 
                      <div ref={dropdownRefStatus} className={cn(styles.actionsContent, styles.dropResourcActionsContent)}>
                        {Object.keys(status).map((s) => (
                          <div key={s} className={cn(styles.dropResourceBtn)} onClick={() => { setDropStatusValue(s); sortEvents(); setDropStatus(false) }}>
                            <div>{status[s]}</div>
                            { dropStatusValue === s && <SvgSprite spriteID={"check"} className={styles.checkStyles} /> }
                          </div>
                        ))}
                      </div>
                    }
                  </div>
                  <div className={cn(styles.resourcesHeaderIcon, styles.resourcesHeaderIconFilter)}>
                    <div className={cn(styles.sortByDate)} onClick={()=> setDropType(!dropType)}> 
                      <div>  {types[dropTypeValue]} </div>
                      <SvgSprite spriteID={'arrow'} className={cn(styles.arrowStyle, styles.iconMargin)}/>
                    </div>
                    {dropType && 
                      <div ref={dropdownRefType} className={cn(styles.actionsContent, styles.dropResourcActionsContent)}>
                        {Object.keys(types).map((type) => (
                          <div key={type} className={cn(styles.dropResourceBtn)} onClick={() => { setDropTypeValue(type); sortEvents(); setDropType(false) }}>
                            <div> {types[type]} </div>
                            { dropTypeValue === type && <SvgSprite spriteID={"check"} className={styles.checkStyles} /> }
                          </div>
                        ))}
                      </div>
                    }
                  </div>
                </div>}
              </div>
                <EventsList events={events || []} loader={allEvents.isLoading} sortBy={sortBy} status={dropStatusValue} type={dropTypeValue}/>
            </div>

            <List 
              data={users} 
              header={'Team Members'}
              icon
            />
            
            <List
                activeLoad={loader}
                users={users}
                eventsLogs  
                header={'Events Logs'}
                loader={loader}
            />
          </div>
        </div>
      </div>
      
    </div>
  )
}

function EventsList({ events, loader, sortBy, status, type }) {
  const navigate = useNavigate()
  return (
    <div className={styles.containerListUser}>
      
      {!loader && events?.map((data) => (
        <div
        onClick={()=> navigate('/calendar', { state: { event : data }})} 
        className={cn(styles.userList, styles.item)} key={data.id}>
          <div className={styles.userInfo}>
            <ProfileImage name={(data?.members[0]?.firstName || "N") + " " + (data?.members[0]?.lastName || "/")} />
            <div className={styles.eventsDetails}>
              <div className={styles.eventsTitle}> {data.startTime} </div>
              <div className={styles.eventsText}> New Event : {data.title} </div>
              <div className={styles.eventsTitle}>
                <span className={styles.eventLabel} style={{ background: data?.completed_at ? "#32bf32" : "#ffca00" }}>
                  {data?.completed_at ? "completed" : "Pending"}
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}
      {!loader && events?.length === 0 && <div className={cn(styles.loaderCenter, styles.loaderStyles)}>No {status !== "all" ? status : ""} {type !== "all" ? type : ""} events available {sortBy !== "all" ? `this ${sortBy}` : ""}</div>}
      {loader && <div className={cn(styles.loaderCenter, styles.loaderStyles)}><DotsLoader /></div>}
    </div>
  );
}