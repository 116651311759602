import React, { useState } from 'react'
import cn from 'classnames'

// react Router
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

// styles
import styles from './index.module.scss'

// component
import { Input } from '../../ui/Input/Input'
import { Btn } from '../../ui/Btn'
import { Loader } from '../../ui/Loader'

// hooks
import { useInput } from '../../hooks/useInput'

// functions
import { checkErrors } from '../../functions/checkErrors'
import { axiosInstance } from '../../config/https'
import { useDispatch } from 'react-redux'
import { toast } from '../../functions/toast'
import PasswordInput from '../../ui/PasswordInput/PasswordInput'

export const InviteRegister = () => {
    const [queryParameters] = useSearchParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [validePwd, setValidePwd] = useState(false)
    const inputFirstName = useInput('')
    const inputLastName = useInput('')
    const inputPhone = useInput('')
    const inputPassword = useInput('')
    const inputConfirmPassword = useInput('')

    const [errors, setErrors] = useState({})

    const [register, setRegister] = useState(false)

    const handelRegister = async () =>{
        setRegister(true)
        const inputsInfo = [
            {el: inputFirstName, errorName: 'firstName', errorText: 'Enter First Name'},
            {el: inputLastName, errorName: 'lastName', errorText: 'Enter Last Name'},
            {el: inputPhone, errorName: 'phone', errorText: 'Enter phone'},
            {el: inputPassword, errorName: 'password', errorText: 'Enter Password'},
            {el: inputConfirmPassword, errorName: 'confirmPassword', errorText: 'Enter Password'},
        ]

        if (await checkErrors(setErrors, inputsInfo)) {
            setRegister(false)
            return
        }

        if(!validePwd){
            let tempErrors = []
            tempErrors['password'] = 'Choose a stronger password' 
            setErrors(tempErrors)
            setRegister(false)
            return
        }

        if (inputPassword.value !== inputConfirmPassword.value) {
            let tempErrors = []
            tempErrors['confirmPassword'] = 'Passwords do not match' 
            setErrors(tempErrors)
            setRegister(false)
            return
        }

        const data = {
            firstName : inputFirstName.value,
            lastName : inputLastName.value,
            phone : inputPhone.value,
            email: decodeURIComponent(queryParameters.get("email")),
            password: inputPassword.value,
            token: queryParameters.get("token")
        }  

        await axiosInstance.post(`/auth/invite/register`, data, {
            headers: {
                "content-type": "multipart/form-data",
            }
        }).then(async (res)=>{
            if (res.status === 200) {
                navigate('/auth/login')
            }
        }).catch( (err)=>{
            if(err?.response?.data?.errors){
                for (const [key, value] of Object.entries(err?.response?.data?.errors)) {
                    dispatch(toast('error', `${key}: ${value}`))
                }
            }else
                dispatch(toast('error', err?.response?.data?.message))
        });
        setRegister(false)
    }

    return (
        <div className={styles.container}>
            <div className={styles.form}> 
                <div className={styles.logo}></div> 
                <div className={styles.Header}> Create Account </div>

                <div className={styles.loginInputs}>
                    
                <div className={cn(styles.label, styles.headerLabel)}>
                        <div>Profile</div> 
                        <hr className={styles.rounded} />
                    </div>

                    <div className={styles.label}>First Name</div>
                    <Input 
                        onChange={inputFirstName.onChange} 
                        type={'text'} 
                        className={styles.input} 
                        placeholder={'First Name'}
                        error={errors.firstName}/>
                    
                    <div className={styles.label}>Last Name</div>
                    <Input 
                        onChange={inputLastName.onChange} 
                        type={'text'} 
                        className={styles.input} 
                        placeholder={'Last Name'}
                        error={errors.lastName}
                        required/>
                    
                    <div className={styles.label}>Phone</div>
                    <Input 
                        onChange={inputPhone.onChange} 
                        type={'tel'} 
                        className={styles.input} 
                        placeholder={'Phone'}
                        error={errors.phone}/>

                    <div className={styles.label}>Password</div>
                    {/* <Input 
                        onChange={inputPassword.onChange} 
                        type='password'
                        className={styles.input}
                        error={errors.password}/> */}
                    <PasswordInput 
                        className={styles.input} 
                        setValidePwd={setValidePwd} 
                        onChange={inputPassword.onChange} 
                        value={inputPassword.value} 
                        error={errors.password}
                        validePwd={validePwd}
                    />
                    
                    <div className={styles.label}>Confirm Password</div>
                    <Input 
                        onChange={inputConfirmPassword.onChange} 
                        type='password'
                        className={styles.input}
                        error={errors.confirmPassword}/>
                    
                </div>
                <div className={styles.loginBtn}>
                    <Btn onClick={handelRegister} type={'email'} className={cn(styles.btn, styles.btnSubmit)} disable={register}> 
                        <div className={styles.submitText}>Register</div>
                        {/* Loader */}
                        {register && 
                            <Loader />
                        }
                    </Btn>
                </div>
                <div className={styles.signUpLink}>Do you have an account ? <Link to={'/auth/login'}> Sign In</Link></div>
                
                <footer className={styles.footer}>
        <nav>
            <ul className={styles.footerNav}>
               
                <li><a href="https://app.termly.io/document/terms-of-use-for-website/f78e9e9b-bba1-4a94-983e-68a1005f28b0">Terms of Service</a></li>
                <li><a href="https://app.termly.io/document/privacy-policy/3754276d-7c3f-40ef-931d-584c9bc61e74">Privacy Policy</a></li>
                
            </ul>
        </nav>
        <br></br>
                    <h5>© 2023 SecureLabs Inc </h5>
    </footer>
            </div>
   
     
        </div>
        
    )
}
