export const TimeZone = [
  {
    "id": 1,
    "timezone": "UTC",
    "offset": "+00:00",
    "value": "UTC (+00:00)",
    "name": "UTC (+00:00)"
  },
  {
    "id": 2,
    "timezone": "GMT",
    "offset": "+00:00",
    "value": "GMT (+00:00)",
    "name": "GMT (+00:00)"
  },
  {
    "id": 3,
    "timezone": "EST",
    "offset": "-05:00",
    "value": "EST (-05:00)",
    "name": "EST (-05:00)"
  },
  {
    "id": 4,
    "timezone": "CST",
    "offset": "-06:00",
    "value": "CST (-06:00)",
    "name": "CST (-06:00)"
  },
  {
    "id": 5,
    "timezone": "MST",
    "offset": "-07:00",
    "value": "MST (-07:00)",
    "name": "MST (-07:00)"
  },
  {
    "id": 6,
    "timezone": "PST",
    "offset": "-08:00",
    "value": "PST (-08:00)",
    "name": "PST (-08:00)"
  },
  {
    "id": 7,
    "timezone": "EDT",
    "offset": "-04:00",
    "value": "EDT (-04:00)",
    "name": "EDT (-04:00)"
  },
  {
    "id": 8,
    "timezone": "CDT",
    "offset": "-05:00",
    "value": "CDT (-05:00)",
    "name": "CDT (-05:00)"
  },
  {
    "id": 9,
    "timezone": "MDT",
    "offset": "-06:00",
    "value": "MDT (-06:00)",
    "name": "MDT (-06:00)"
  },
  {
    "id": 10,
    "timezone": "PDT",
    "offset": "-07:00",
    "value": "PDT (-07:00)",
    "name": "PDT (-07:00)"
  },
  {
    "id": 11,
    "timezone": "AKST",
    "offset": "-09:00",
    "value": "AKST (-09:00)",
    "name": "AKST (-09:00)"
  },
  {
    "id": 12,
    "timezone": "AKDT",
    "offset": "-08:00",
    "value": "AKDT (-08:00)",
    "name": "AKDT (-08:00)"
  },
  {
    "id": 13,
    "timezone": "HST",
    "offset": "-10:00",
    "value": "HST (-10:00)",
    "name": "HST (-10:00)"
  },
  {
    "id": 14,
    "timezone": "HAST",
    "offset": "-10:00",
    "value": "HAST (-10:00)",
    "name": "HAST (-10:00)"
  },
  {
    "id": 15,
    "timezone": "HADT",
    "offset": "-09:00",
    "value": "HADT (-09:00)",
    "name": "HADT (-09:00)"
  },
  {
    "id": 16,
    "timezone": "SST",
    "offset": "-11:00",
    "value": "SST (-11:00)",
    "name": "SST (-11:00)"
  },
  {
    "id": 17,
    "timezone": "CHST",
    "offset": "+10:00",
    "value": "CHST (+10:00)",
    "name": "CHST (+10:00)"
  },
  {
    "id": 18,
    "timezone": "NZST",
    "offset": "+12:00",
    "value": "NZST (+12:00)",
    "name": "NZST (+12:00)"
  },
  {
    "id": 19,
    "timezone": "NZDT",
    "offset": "+13:00",
    "value": "NZDT (+13:00)",
    "name": "NZDT (+13:00)"
  },
  {
    "id": 20,
    "timezone": "AEST",
    "offset": "+10:00",
    "value": "AEST (+10:00)",
    "name": "AEST (+10:00)"
  },
  {
    "id": 21,
    "timezone": "AEDT",
    "offset": "+11:00",
    "value": "AEDT (+11:00)",
    "name": "AEDT (+11:00)"
  },
  {
    "id": 22,
    "timezone": "ACST",
    "offset": "+09:30",
    "value": "ACST (+09:30)",
    "name": "ACST (+09:30)"
  },
  {
    "id": 23,
    "timezone": "ACDT",
    "offset": "+10:30",
    "value": "ACDT (+10:30)",
    "name": "ACDT (+10:30)"
  },
  {
    "id": 24,
    "timezone": "JST",
    "offset": "+09:00",
    "value": "JST (+09:00)",
    "name": "JST (+09:00)"
  },
  {
    "id": 25,
    "timezone": "KST",
    "offset": "+09:00",
    "value": "KST (+09:00)",
    "name": "KST (+09:00)"
  },
  {
    "id": 26,
    "timezone": "HKT",
    "offset": "+08:00",
    "value": "HKT (+08:00)",
    "name": "HKT (+08:00)"
  },
  {
    "id": 27,
    "timezone": "CST",
    "offset": "+08:00",
    "value": "CST (+08:00)",
    "name": "CST (+08:00)"
  },
  {
    "id": 28,
    "timezone": "WIB",
    "offset": "+07:00",
    "value": "WIB (+07:00)",
    "name": "WIB (+07:00)"
  },
  {
    "id": 29,
    "timezone": "IST",
    "offset": "+05:30",
    "value": "IST (+05:30)",
    "name": "IST (+05:30)"
  },
  {
   
    "id": 30,
    "timezone": "PKT",
    "offset": "+05:00",
    "value": "PKT (+05:00)",
    "name": "PKT (+05:00)"
  },
  {
    "id": 31,
    "timezone": "BST",
    "offset": "+06:00",
    "value": "BST (+06:00)",
    "name": "BST (+06:00)"
  },
  {
    "id": 32,
    "timezone": "MSK",
    "offset": "+03:00",
    "value": "MSK (+03:00)",
    "name": "MSK (+03:00)"
  },
  {
    "id": 33,
    "timezone": "CEST",
    "offset": "+02:00",
    "value": "CEST (+02:00)",
    "name": "CEST (+02:00)"
  },
  {
    "id": 34,
    "timezone": "CET",
    "offset": "+01:00",
    "value": "CET (+01:00)",
    "name": "CET (+01:00)"
  },
  {
    "id": 35,
    "timezone": "WAT",
    "offset": "+01:00",
    "value": "WAT (+01:00)",
    "name": "WAT (+01:00)"
  },
  {
    "id": 36,
    "timezone": "CAT",
    "offset": "+02:00",
    "value": "CAT (+02:00)",
    "name": "CAT (+02:00)"
  },
  {
    "id": 37,
    "timezone": "EAT",
    "offset": "+03:00",
    "value": "EAT (+03:00)",
    "name": "EAT (+03:00)"
  },
  {
    "id": 38,
    "timezone": "AST",
    "offset": "+03:00",
    "value": "AST (+03:00)",
    "name": "AST (+03:00)"
  },
  {
    "id": 39,
    "timezone": "MUT",
    "offset": "+04:00",
    "value": "MUT (+04:00)",
    "name": "MUT (+04:00)"
  },
  {
    "id": 40,
    "timezone": "SCT",
    "offset": "+04:00",
    "value": "SCT (+04:00)",
    "name": "SCT (+04:00)"
  },
  {
    "id": 41,
    "timezone": "IOT",
    "offset": "+06:00",
    "value": "IOT (+06:00)",
    "name": "IOT (+06:00)"
  }
];
    