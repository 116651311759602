import { useState } from 'react'

// Utils
import { useDispatch } from 'react-redux'
import { setIsAuth, setNotificationNabr, setUserInfo } from '../../store/slices/userSlice'

// axois
import { axiosInstance } from '../config/https'
import { getCookie } from '../utils/cookie'

export const useAuth = () => {
    const dispatch = useDispatch()
    const [isChecked, setIsChecked] = useState(false)

    const checkIsAuth = async () => {

        try {
            if(getCookie('access_token')){
                const res = await axiosInstance(`/v2/auth/sessions`)

                if(res.status === 200){
                    dispatch(setUserInfo({...res.data.user}))
                    dispatch(setNotificationNabr(res.data.notifications_nbr))
                    dispatch(setIsAuth(true))
                }else{
                    dispatch(setIsAuth(false))
                }
            }else{
                dispatch(setIsAuth(false))
            }
        } catch (error) {
            dispatch(setIsAuth(false))
        }

        setIsChecked(true)
    }

    return {checkIsAuth, isChecked}
}
