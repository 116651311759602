import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { axiosInstance } from '../../config/https'
import { Btn } from '../../ui/Btn'
import { DotsLoader, Loader } from '../../ui/Loader'
import styles from './index.module.scss'
import cn from 'classnames'
import { Search } from '../../ui/Search/Search'
import { SvgSprite } from '../../ui/SvgSprite/SvgSprite'
import { Select } from '../../ui/Select/Select'
import { toast } from '../../functions/toast'
import { useNavigate } from 'react-router-dom'
import { Popup } from '../../ui/Popup'
import { RemoveFramwork } from './RemoveFramwork'
import { setAllFrameworkFunction } from '../../functions/getAllFrameworks'
import { fetchControls, fetchFrameworks } from '../../../store/slices/documentsSlice'

export const AddFramework = ({closePopup}) => {
    const allFrameworks = useSelector(state => state.allFramework?.allFrameworks)
    const frameworks = useSelector(state => state.documents.frameworks);
    const [sortFrameworks, setSortFrameworks] = useState([])
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [ids, setIds] = useState([])
    const [search, setSearch] = React.useState('');
    const [open, setOpen] = useState(false)
    const [newSelectCategory, setNewSelectCategory] = useState(null)
    const [searchData, setSearchData] = useState([])
    const [newCategory, setNewCategory] = useState(null)
    const [sortOk, setSortOk] = useState(true)
    const [removeFramwork, setRemoveFramwork] = useState(false)

    const navigate = useNavigate()
    const [category] = useState([
        {
            id : 0,
            name : 'All',
            value : 'All'
        },
        {
            id : 1,
            name : 'Privacy',
            value : 'Privacy'
        },
        {
            id : 2,
            name : 'Security',
            value : 'Security'
        },
        {
            id : 3,
            name : 'Custom',
            value : 'Custom'
        }
    ])

    const addFramework = async(id) => {
        setLoader(true)
        const data = {
            ids : ids,
        }

        if(ids?.length <= 0){
            dispatch(toast('error', 'No framework selected. Please choose at least one.'))
            setLoader(false)
            return;
        }
        await axiosInstance.post(`organization/frameworks`, data)
        .then((res) => {
            dispatch(toast('success', 'Frameworks Updated Successflly'))
            dispatch(fetchFrameworks());
            dispatch(fetchControls());
            closePopup()
        }).catch((err)=>{
            dispatch(toast('error', err?.response?.data?.message))
        }).finally(()=>{
            setLoader(false)
        })
    }

    const select = (id)=>{
        if(!ids.includes(id)){
            setIds([...ids, id])
        }else{
            setIds([...ids.filter(i => i !== id)])
        }
    }

    useEffect(()=>{
       sortFrameworks?.length >0 &&  setSearchData(sortFrameworks?.filter((item) => item.name.toLowerCase().includes(search.toLowerCase())  ))
    }, [sortFrameworks, search])

    useEffect(()=>{
        frameworks && setIds([...frameworks?.map(f => {return f.id})])
    }, [frameworks])

    const handelFilter=()=>{
        if(newSelectCategory === 'Privacy' || newSelectCategory === 'Security' || newSelectCategory === 'Custom'){
            setNewCategory(newSelectCategory)
        }else{
            setNewCategory(null)
        }
        setOpen(false)
    }

    useEffect(()=>{   
        if(ids?.length === frameworks?.length) {
            if(allFrameworks?.length > 0 && sortOk){
                const uniqueFrameworks = [...new Set([...allFrameworks?.filter(f => ids.includes(f.id)), ...allFrameworks?.filter(f => f.category === 'custom'), ...allFrameworks?.filter(f => !ids?.includes(f.id) && f.category !== 'custom')])];
                setSortFrameworks(uniqueFrameworks)
                setSortOk(false)
            } 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allFrameworks, frameworks, ids])

    useEffect(()=>{
        (async()=>{
          await Promise.all([
            !allFrameworks && dispatch(setAllFrameworkFunction()),
          ])
        })()
      }, [allFrameworks, dispatch])

  return (
  <>
    <div className={styles.addPopupFramwork}>
        <div className={styles.topPopupAddFramwork}>
            <div className={styles.addframworkTitle}> Add Framework </div>
            <div className={styles.searchFramworkPopup}>
                <Search setSearch={setSearch} className={styles.search} placeholder={'Search Frameworks'}/>
                <div className={styles.searchContainerFramwork}>
                    <Btn className={styles.filterBtn} onClick={()=>setOpen(!open)}>
                        <SvgSprite spriteID={'filters'} className={styles.filterIcon} />
                        <div>Filters</div>
                    </Btn>    
                    {open && <div className={cn(styles.actionsContent)}>
                        <div className={styles.AddFrameworkPopupHeader}>
                            <div>Filter By</div>
                            <SvgSprite spriteID={'close'} className={styles.closePopupFilter} onClick={()=>setOpen(false)}/>
                        </div>
                        <div className={styles.AddFrameworkPopupBody}>
                            <div>
                                <div>Category</div>
                                <Select selectFullWidth 
                                    className={styles.selectClassName}  
                                    setNewValue={setNewSelectCategory} 
                                    initValue={newSelectCategory} 
                                    values={category}/>
                            </div>
                            <div  className={styles.AddFrameworkPopupFooter}>
                                <div className={styles.AddFrameworkPopupFooterBtnReset} onClick={()=>{setNewSelectCategory(null);setNewCategory(null)}}>
                                    <SvgSprite spriteID={'trash'} />
                                    <div>Clear Filters</div>
                                </div>
                                <Btn className={styles.AddFrameworkPopupFooterBtn} onClick={()=>handelFilter()}>Apply Filter</Btn>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
            <Btn className={styles.newFramework} onClick={()=>navigate('/new-framework')} >Create Framework</Btn>

            <div className={styles.addframworkTitleCenter}> {frameworks?.length}/{allFrameworks?.length} Selected</div>
        </div>

        <div className={cn(styles.gridRow, {
            [styles.individualPlan] : true
        })}>
            {sortFrameworks?.length > 0 && (searchData?.length>0 || (searchData?.length===0 && search?.length>0) ? searchData : sortFrameworks)?.map((f,i) => ((newCategory === 'Privacy' || newCategory === 'Security' || newCategory === 'Custom') ? (f.category === newCategory.toLowerCase()) : true) && (
                <div key={i} className={cn(styles.gridItem, 
                    {[styles.selectedFramwork] : ids.includes(f.id) ,
                    // [styles.disabledSelected] :  frameworks.find(fram => fram.id === f.id)
                })} onClick={()=>select(f.id)}> 
                    <div className={styles.centerFrameworkName}>{f.name} </div>
                    <div className={styles.frameworkCategory}>{f.category}</div>
                </div>
            ))}
        </div>
        {(searchData?.length === 0 && search?.length > 0) && 
            <div className={styles.NoFramworkFound}> 
                No Frameworks Found
            </div>
        }
        {!allFrameworks?.length && 
            <div className={styles.NoFramworkFound}> 
                <DotsLoader />
            </div>
        }
        <div className={styles.listBtnsAddframework}>
            <Btn className={cn(styles.addBtn, styles.backroundFrameworkCancel)} onClick={closePopup}>Cancel</Btn>
            <Btn disable={loader} className={styles.addBtn} noHover onClick={()=>{frameworks?.filter(f => !ids?.includes(f.id))?.length > 0 ? setRemoveFramwork(true) : addFramework();} }>
                <div>Update</div>
                {loader && <Loader />}</Btn>
        </div>
    </div>
    <Popup small isOpenedPopup={removeFramwork} closePopup={() => {setRemoveFramwork(false); }}>
        <RemoveFramwork 
            closePopup={()=>setRemoveFramwork(false)}
            handleRemove= {addFramework}
            framework = {frameworks?.filter(f => !ids?.includes(f.id))}
            loader= {loader}
        />
    </Popup>
  </>
  )
}