export function sortControlsFn(data, accessor, order) {
  const riskLevels = [
    "negligible",
    "minor",
    "moderate",
    "significant",
    "high"
  ];

  let sortData = [...data];

  switch (accessor) {
    case 'name':
      sortData.sort((a, b) => {
        return order === 'asc'
          ? a.control_name.localeCompare(b.control_name)
          : b.control_name.localeCompare(a.control_name);
      });
      break;
    case 'risk':
      sortData.sort((a, b) => {
        const aRiskIndex = riskLevels.indexOf(a.submission_risk?.toLowerCase() || a.control_risk?.toLowerCase());
        const bRiskIndex = riskLevels.indexOf(b.submission_risk?.toLowerCase() || b.control_risk?.toLowerCase());
        return order === 'desc' ? aRiskIndex - bRiskIndex : bRiskIndex - aRiskIndex;
      });
      break;
    case 'status':
      sortData.sort((a, b) => {
        return order === 'desc' ? a.submission_status - b.submission_status : b.submission_status - a.submission_status;
      });
      break;
    default:
      break;
  }

  return sortData;
}
