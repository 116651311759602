import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    controlLogs: {},
}

const controlLogsSlice = createSlice({
    name: 'controlLogs',
    initialState,
    reducers: {
        setcontrolLogs(state, action) {
            state.controlLogs = action.payload
        },
    }
})

export default controlLogsSlice.reducer
export const { setcontrolLogs } = controlLogsSlice.actions
