import React, { useEffect, useState } from 'react'
import { ProfileImage } from '../../ui/ProfileImage/ProfileImage'
import styles from './index.module.scss'


export const Comment = ({owner, comment, date}) => {
  const [newDate, setNewDate] = useState(0)
  const [type, setType] = useState('')

  const diffDate = (dt2, dt1, value = 60*60*24)=> 
  {
    var diff =(dt2.getTime() - dt1.getTime()) / 1000;
    diff /= value
    return Math.abs(Math.round(diff));
  }  

  useEffect(()=>{
    let dt1 = new Date()
    let dt2 = new Date(date)
    if(diffDate(dt1, dt2, 1)<60){
      setNewDate(diffDate(dt1, dt2, 1))
      setType('Seconds Ago')
    }else if(diffDate(dt1, dt2, 60)<60){
      setNewDate(diffDate(dt1, dt2, 60))
      setType('Minutes Ago')
    }else if(diffDate(dt1, dt2, 60*60)<24){
      setNewDate(diffDate(dt1, dt2, 60*60))
      setType('Hours Ago')
    }else {
      setNewDate(dt2.toLocaleString('default', { month: 'short', day : '2-digit', hour : '2-digit', minute:'2-digit' }))
    }
  }, [date])

  const formatComment = (comment) => {
    // Replace @[username](id) with @username
    const formattedComment = comment.replace(/@\[([^\]]+)\]\(\d+\)/g, '@$1');
    return formattedComment;
};

  return comment?.length > 0 && (
    <div className={styles.commentContainer}>
        <ProfileImage name={owner?.firstName + ' ' + owner?.lastName} className={styles.profileimage}/>
        <div className={styles.comment}>
            <div className={styles.commentsHeader}> 
              <div className={styles.commentOwner}>{owner?.firstName + ' ' + owner?.lastName} <span className={styles.spanCommented}>Commented</span></div>
              <div className={styles.spanCommented}>{newDate} {type}</div>
            </div>
            {formatComment(comment)}
        </div>
    </div>
  )
}
