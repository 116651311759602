import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styles from './index.module.scss'

export const Task = ({activities}) => {
    const currentUser = useSelector(state => state.user.userInfo)
    const users = useSelector(state => state.organization.org.users)
    const [owner, setOwner] = useState(null)
    const [newDate, setNewDate] = useState(0)
    const [type, setType] = useState('')
    const EventType = {
            '#8833FF' : 'General',
            '#E62E2E' : 'Audit',
            '#33BFFF' : 'Review',
            '#29CC39' : 'Task',
            '#000000' : 'Incident'
        }

    const logs = {
        'OWNER_ASSIGNED' : `Assigned ${activities?.meta_data?.first_name} ${activities?.meta_data?.last_name} as owner of this control`,
        'OWNER_REMOVED': `Removed ${activities?.meta_data?.first_name} ${activities?.meta_data?.last_name} as owner of this control`,
        'STAKEHOLDER_ADDED': `Added ${activities?.meta_data?.first_name} ${activities?.meta_data?.last_name} as a stakeholder of this control`,
        'STAKEHOLDER_REMOVED': `Removed ${activities?.meta_data?.first_name} ${activities?.meta_data?.last_name} as a stakeholder of this control`,
        'COMMENT_ADDED': `Added a comment to control`,
        'COMMENT_UPDATED': ``,
        'COMMENT_REMOVED': ``,
        'RESOURCES_UPDATED': `Updated Resources`,
        'ATTACHMENT_ADDED': `Added ${activities?.meta_data?.type} attachment to this control`,
        'ATTACHMENT_UPDATED': `Updated  ${activities?.meta_data?.type} attachment`,
        'ATTACHMENT_REMOVED': `Removed ${activities?.meta_data?.type} from control`,
        'CONTROL_EVENT_ADDED': `Added one ${EventType[activities?.meta_data?.event_type]} event starting from ${new Date(activities?.meta_data?.start)?.toLocaleString('default', { month: 'short', day : '2-digit', hour : '2-digit', minute:'2-digit' })} to ${new Date(activities?.meta_data?.end)?.toLocaleString('default', { month: 'short', day : '2-digit', hour : '2-digit', minute:'2-digit' })}`,
        'CONTROL_EVENT_UPDATED': `Updated one ${EventType[activities?.meta_data?.event_type]} event starting from ${new Date(activities?.meta_data?.start)?.toLocaleString('default', { month: 'short', day : '2-digit', hour : '2-digit', minute:'2-digit' })} to ${new Date(activities?.meta_data?.end)?.toLocaleString('default', { month: 'short', day : '2-digit', hour : '2-digit', minute:'2-digit' })}`,
        'CONTROL_EVENT_REMOVED': `Removed one ${EventType[activities?.meta_data?.event_type]} event from control`,
        'RISK_ADDED': 'Added a risk level to this control',
        'RISK_UPDATED' : 'Updated the risk level of this control',
        'RISK_REMOVED' : 'Removed the risk level of this control'
    }
    const diffDate = (dt2, dt1, value = 60*60*24)=> 
    {
        var diff =(dt2.getTime() - dt1.getTime()) / 1000;
        diff /= value
        return Math.abs(Math.round(diff));
    }  

    useEffect(()=>{
        let dt1 = new Date()
        let dt2 = new Date(activities?.updated_at)
        if(diffDate(dt1, dt2, 1)<60){
        setNewDate(diffDate(dt1, dt2, 1))
        setType('Seconds Ago')
        }else if(diffDate(dt1, dt2, 60)<60){
        setNewDate(diffDate(dt1, dt2, 60))
        setType('Minutes Ago')
        }else if(diffDate(dt1, dt2, 60*60)<24){
        setNewDate(diffDate(dt1, dt2, 60*60))
        setType('Hours Ago')
        }else {
        setNewDate(dt2.toLocaleString('default', { month: 'short', day : '2-digit', hour : '2-digit', minute:'2-digit' }))
        }
    }, [activities])

    useEffect(()=>{
        if(activities.user_id === currentUser?.id)
            setOwner('You')
        else{
            let u = users?.find(u => u.id === activities.user_id)
            setOwner(u?.firstName + ' ' + u?.lastName)
        }
    }, [activities, currentUser?.id, users])
    return (
        <div className={styles.taskContainer}>
            <div className={styles.taskActivitie}>
                <span className={styles.taskOwner}>{owner}</span> {logs[activities?.activities]}</div>
            <div className={styles.taskDate}>{newDate} {type}</div>
        </div>
    )
}
