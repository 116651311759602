/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import cn from 'classnames'

// Styles
import styles from './index.module.scss'

// Components
import { SvgSprite } from '../SvgSprite'
import { useDispatch, useSelector } from 'react-redux'
import { axiosInstance } from '../../config/https'
import { ProfileImage } from '../ProfileImage/ProfileImage'
import { Btn } from '../Btn'
import {Loader} from '../Loader'
import { getControlSubmissions } from '../../functions/getControlSubmission'
import { toast } from '../../functions/toast'
export const SelectUser = ({owner, setOwner, controlId, submission, setStakeholders, stakeholders, activities, setActivities}) => {

    const users = useSelector(state => state.organization.org.users)
    const select = useRef()
    const [listUser, setListUser] = useState(false)
    const [start, setStart] = useState(false)
    const [forOwner, setForOwner] = useState(false)
    const [loader, setLoader] = useState(false)
    const [selectedOption, setSelectedOption] = useState(null);
    const dispatch = useDispatch()
    const [ids, setIds] = useState([])

    const addOwner = async() => {
        setLoader(true)

        const data = {
            control_id : controlId,
            assignedTo : selectedOption
        }

        if(!submission){

            await axiosInstance.post(`/submission/control`, data)
            .then((res)=>{
                selectedOption ? setOwner(users?.find(u=>u.id === selectedOption)) : setOwner(null)
                const temp = activities ? res.data.meta_data.concat(activities) : res.data.meta_data
                setActivities(temp)
                dispatch(getControlSubmissions())
                dispatch(toast('success',  'Owner Updated Successfully' ))
            }).catch((err)=>{
                dispatch(toast('error',  err?.response?.data?.message ))
            }).finally(()=>{
                setLoader(false)
                setListUser(false)
            })
        }else if(selectedOption){
            await axiosInstance.patch(`/submission/control/${submission.id}`, data)
            .then((res)=>{
                selectedOption ? setOwner(users?.find(u=>u.id === selectedOption)) : setOwner(null)
                const temp = activities ? res.data.meta_data.concat(activities) : res.data.meta_data
                setActivities(temp)
                dispatch(getControlSubmissions())
                dispatch(toast('success',  'Owner Updated Successfully' ))
            }).catch((err)=>{
                dispatch(toast('error',  err?.response?.data?.message ))
            }).finally(()=>{
                setLoader(false)
                setListUser(false)
            })
        }else{
            await axiosInstance.patch(`/owner/submission/control/${submission?.id}`)
            .then((res)=>{
                selectedOption ? setOwner(users?.find(u=>u.id === selectedOption)) : setOwner(null)
                const temp = activities ? res.data.meta_data.concat(activities) : res.data.meta_data
                setActivities(temp)
                dispatch(getControlSubmissions())
                dispatch(toast('success',  'Owner Reset Successfully' ))
            }).catch((err)=>{
                dispatch(toast('error',  err?.response?.data?.message ))
            }).finally(()=>{
                setLoader(false)
                setListUser(false)
            })
        }
    }

    const addStakeholder = async() => {
        setLoader(true)
        const data = {
            ids : ids.filter((v,i) => ids.indexOf(v) === i),
            control_id: controlId
        }

        await axiosInstance.patch(`stakeholders/submission/control/${submission && submission.id}`, data)
        .then((res)=>{
            setStakeholders([...users.filter(u => data.ids.includes(u.id) )])
            const temp = activities ? res.data.meta_data.concat(activities) : res.data.meta_data
            setActivities(temp)
            dispatch(getControlSubmissions())
            dispatch(toast('success',  'Stackeholders Updated Successfully'))
        }).catch((err)=>{
            dispatch(toast('error',  err?.response?.data?.message ))
        }).finally(()=>{
            setLoader(false)
            setListUser(false)
        })
    }


    const assignedTo = (e, id)=>{
        if(e.target?.checked && !ids.includes(id)){
            setIds([...ids, id])
        }else{
            setIds([...ids.filter(i => i !== id)])
        }
    }

    useEffect(()=>{
        setSelectedOption(owner?.id)
    }, [users, listUser])

    useEffect(()=>{
        const temp = []
        stakeholders?.map((u)=> temp.push(u?.id))
        setIds([...temp])
    }, [setStakeholders, listUser])

    useEffect(() => {
        function handleClickOutside(event) {
          if (select.current && !select.current.contains(event.target)) {
            setStart(false)
          }
        }
    
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [select]);


    return (
        <div ref={select} className={cn(styles.select, {
            [styles.selectOpened]: start,
           
        })}>
            <div className={cn(styles.selectActive,styles.whiteText, {
                [styles.selectActiveOpenedWhite]: start,
            })} onClick={() => {setStart(!start); setListUser(false)}}>
                <span>Add Members</span>
                <div className={styles.selectIconCl}>
                    <SvgSprite spriteID={'arrow'} />
                </div>
            </div>
            {start &&
                <>
                {!listUser ? 
                    <div className={cn(styles.selectDrop, styles.selectDropUser)}>
                        <div className={styles.assignUser}>
                            <div>Assign Control Owner</div>
                            <SvgSprite spriteID={'arrow'} />
                        </div>
                        {owner ? (
                            <div className={styles.ownerStyles}>
                                <div className={styles.addAdssignListUser} onClick={()=>{setForOwner(true);setListUser(true)}}>
                                    <ProfileImage name={owner?.firstName + ' ' + owner?.lastName} /><div>{owner?.firstName +' '+ owner?.lastName}</div>
                                </div>
                            </div>
                        ) : (
                            <div className={styles.addAdssignUser} onClick={()=>{setForOwner(true);setListUser(true)}}>
                            <SvgSprite spriteID={'add'}  className={styles.svgAdd}/> 
                            <div>Assign Control Owner</div>
                        </div>
                        )}
                        
                        <div className={styles.assignUser}>
                            <div>Assign Stakeholder</div>
                            <SvgSprite spriteID={'arrow'} />
                        </div>
                        {stakeholders?.map((owner, i)=>(
                            <div className={styles.stakeholdersStyles} key={i}>
                                <div className={styles.addAdssignListUser} onClick={()=>{setForOwner(false);setListUser(true)}}>
                                    <ProfileImage name={owner?.firstName + ' ' + owner?.lastName} /><div>{owner?.firstName +' '+ owner?.lastName}</div>
                                </div>
                            </div>
                        ))}
                        <div className={styles.addAdssignUser} onClick={()=>{setForOwner(false);setListUser(true)}}>
                            <SvgSprite spriteID={'add'}  className={styles.svgAdd} /> 
                            <div>Assign Stakeholder</div>
                        </div>
                    </div>
                :
                    <div className={cn(styles.selectDrop, styles.selectDropUser)}>
                        <div className={styles.hederSelect}>
                            <SvgSprite spriteID={'arrowNext'} onClick={()=>{setListUser(false);setForOwner(false)}} className={styles.returnToSelect} />
                            <div className={styles.listUsersTilte}> {forOwner ? 'Assign Control Owner' : 'Assign Stakeholders'}</div>
                        </div>
                        <div className={styles.containerListUser}>
                            {users?.map((u,i) => 
                                ((!forOwner && u.id !== owner?.id) || (forOwner && !submission?.stakeholders?.find(s => s.id === u.id))) && <div key={i}>
                                    {forOwner? 
                                    <div className={cn(styles.addAdssignListUser, styles.ownerStyles)} key={i}  onClick={() => {forOwner ? setSelectedOption(selectedOption === u.id ? null : u.id) : assignedTo(u.id)}}>
                                        <div className={styles.addAdssignListUser} htmlFor={u.id} >
                                            <ProfileImage name={u.firstName + ' ' + u.lastName} /><div className={styles.listUserName}>{u.firstName +' '+ u.lastName} <span className={styles.listUserNameRole}>{(u?.role)?.charAt(0)?.toUpperCase()+(u?.role)?.substring(1,)}</span></div>
                                        </div>
                                        <div>
                                            <input 
                                            type="radio" 
                                            name="option" 
                                            id={u.id} 
                                            value={u.id} 
                                            checked={selectedOption ? selectedOption === u.id : owner === u.id}  
                                            onChange={() => setSelectedOption(selectedOption === u.id ? null : u.id)}/>
                                        </div>
                                    </div>
                                        : 
                                    <label className={cn(styles.addAdssignListUser, styles.ownerStyles)} key={i+u.id}>
                                        <div className={styles.addAdssignListUser} htmlFor={u.id} onClick={() => {forOwner ? setSelectedOption(selectedOption === u.id ? null : u.id) : assignedTo(u.id)}}>
                                            <ProfileImage name={u.firstName + ' ' + u.lastName} /><div className={styles.listUserName}>{u.firstName +' '+ u.lastName} <span className={styles.listUserNameRole}>{(u?.role)?.charAt(0)?.toUpperCase()+(u?.role)?.substring(1,)}</span></div>
                                        </div>
                                        <div>
                                            <input 
                                                type="checkbox" 
                                                name={u.id} 
                                                defaultChecked={stakeholders?.find(s => s.id === u.id)} 
                                                onChange={(e)=>assignedTo(e, u.id)} />
                                        </div>
                                    </label>
                                }
                                </div>
                            )}
                        </div>
                        <div className={styles.containerSave}>
                            <Btn 
                                className={cn(styles.saveBtn,styles.saveWithLoader)} 
                                onClick={()=> forOwner? addOwner() : addStakeholder()}
                            >
                                <div>Save</div>
                                {loader && <Loader />}
                            </Btn>
                        </div>
                    </div>
                }
                </>
            }   
        </div>
    )
}