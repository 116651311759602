import React, { useEffect, useState } from 'react'
import { SvgSprite } from '../../../ui/SvgSprite';
import styles from '../../Documents/index.module.scss'
import cn from 'classnames'
import { Btn } from '../../../ui/Btn';
import { ProfileImage } from '../../../ui/ProfileImage/ProfileImage';
import { axiosInstance } from '../../../config/https';
import { useDispatch, useSelector } from 'react-redux';
import { useInput } from '../../../hooks/useInput';
import { Input } from '../../../ui/Input';
import { Comment } from '../../Documents/Comment';
import { Status } from './Status';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DotsLoader, Loader } from '../../../ui/Loader';
import { Task } from '../../Documents/Task';
import { getControlSubmissions } from '../../../functions/getControlSubmission';
import { toast } from '../../../functions/toast';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../../services/api';
import DocumentFormAttachments from '../../Documents/DocumentFormAttachments';
import { Popup } from '../../../ui/Popup';
import { Breakdown } from './Breakdown';

export const AuditDocumentForm = () => {
    const [breakdown, setBreakdown] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const comment = useInput('')
    const controlSubmissions = useSelector(state => state.controlSubmission.controlSubmission)
    const users = useSelector(state => state.organization.org.users)
    const [owner, setOwner] = useState(null)
    const [commentLoader, setCommentLoader] = useState(false)
    const location = useLocation();
    const { state } = location;
    const [submission, setSubmission] = useState(null)
    const [activeControl, setActiveControl] = useState(null)
    const [activeFramework, setActiveFramework] = useState(null)
    const [stakeholders, setStakeholders] = useState([])
    const [activities, setActivities] = useState([])
    const [listComment, setListComment] = useState([])
    const [attachments, setAttachments] = useState([])
    const [listEvents,setListEvents] = useState([])
    const accountPlan =useSelector(state => state.organization.org.account_plan)
    const riskStatus = useSelector(state => state.organization.org.risk_status)
    const [resources, setResources] = useState([])
    const { controlId } = useParams();

    const submissionQuery = useQuery({
        queryKey : ["framework","control", {id : controlId}, "submission"],
        queryFn : () => { return api.fetchData(`/framework/control/${controlId}/submission`)}
    })

    const controlQuery = useQuery({
        queryKey : ["control", {id : controlId}],
        queryFn : () => { return api.fetchData(`/control/${controlId}`)}
    })


    useEffect(()=>{
        setActiveControl(controlQuery?.data?.data)
    }, [controlQuery?.data])

    const EventType = {
        '#8833FF' : 'General',
        '#E62E2E' : 'Audit',
        '#33BFFF' : 'Review',
        '#29CC39' : 'Task',
        '#000000' : 'Incident'
    }

    
    const addComment = async() =>{
        setCommentLoader(true)
        if(comment.value?.length > 0){

            const data = {
                control_id : activeControl?.id,
                control_submission_id : submission?.id,
                comment : comment.value,
            }
            comment.setNewValue('')
            await axiosInstance.post(`comment/log/control`, data)
            .then((res)=>{
                listComment?.length > 0 ? setListComment([res.data.data, ...listComment]) : setListComment([res.data.data])
                activities?.length > 0 ? setActivities([res.data.meta_data, ...activities]) :  setActivities([res.data.meta_data])
                dispatch(toast('success', 'Comment Added Successfully'))
            }).catch((err)=>{
                dispatch(toast('error', err?.response?.data?.message))
            }).finally(()=>{
                setCommentLoader(false)
            })
        }else{
            dispatch(toast('error', 'Add Comment'))
            setCommentLoader(false)
        }
    }

    useEffect(()=>{
        setActiveFramework(state?.framework)
    },[state])
    
    useEffect(()=>{
        controlSubmissions?.length > 0 && (async () => {
            setSubmission(controlSubmissions?.find( c => c.control_id === parseInt(controlId)))
        })()
    }, [controlSubmissions, controlId, submission, users])

    useEffect(()=>{
        (async()=>{
          await Promise.all([
            dispatch(getControlSubmissions())
          ])
        })()
      }, [dispatch])
    
    useEffect(()=>{
        setStakeholders(submissionQuery?.data?.submission?.stakeholders)
        setActivities(submissionQuery?.data?.submission?.controls_activities?.reverse())
        setListComment(submissionQuery?.data?.submission?.comments?.reverse())
        setAttachments(submissionQuery?.data?.submission?.attachments)
        setListEvents(submissionQuery?.data?.submission?.events)
        setResources(submissionQuery?.data?.submission?.resources)
        setOwner(submissionQuery?.data?.submission?.owner)
    },[submissionQuery?.data])

    return (
        <div>
            <div className={cn(styles.main, {[styles.removeBorder] : true})}>
                <div className={styles.borderContainer}>
                    <div className={styles.header}>
                    <div className={styles.rieghtHeader}>
                        <div className={cn(styles.headerFramework, styles.paddingTo)} onClick={()=> navigate(`/framework/${activeFramework?.id}`)}>{activeFramework?.name}</div>
                        {activeFramework?.name && <SvgSprite spriteID={'arrow'}  className={styles.iconTo}/>}
                        <div className={cn(styles.headerFramework, styles.paddingTo)} onClick={()=> navigate(`/framework/${activeFramework?.id}`)}>{activeControl?.name}</div>
                        {activeControl?.control_function && <div className={styles.divStatus}>
                            <div className={cn(styles.btnStatus, styles.paddingTo, styles.colorFunction)}>
                                {activeControl?.control_function} 
                            </div>
                        </div>}
                    </div>
                    <div className={styles.leftHeader} onClick={()=> navigate(`/framework/${activeFramework?.id ? activeFramework?.id : "AllControls"}`)}>
                        <SvgSprite className={styles.closeIcon} spriteID={'close'} />
                    </div>
                    </div>
                    <div className={styles.header}>
                    <div className={styles.rieghtHeader}>
                        <div className={cn(styles.headerFrameworkUser, styles.ownerList)}> 
                            <div className={styles.ownerName}>Controls Owner</div> 
                            {owner &&
                                <ProfileImage name={owner?.firstName + ' ' + owner?.lastName} className={styles.popupInconOwner}/> 
                            }
                        </div>
                        <div className={cn(styles.headerFrameworkUser, styles.ownerList)}> 
                            <div className={styles.ownerName}>Control Stakeholders</div>
                            <div className={styles.listStakeholdersImage}>
                                {stakeholders?.map((owner,i) => 
                                    <ProfileImage key={i} name={owner.firstName + ' ' + owner.lastName} className={styles.popupInconOwner}/> 
                                )}
                            </div>
                        </div>
                    </div>

                    </div>
                    <div className={styles.formBody}>
                        <div className={styles.leftformBody}>
                            <div className={styles.controlCard}>
                                <div>
                                    <div className={styles.controlCardLabel}>
                                        <div>Title</div>
                                        <Btn noHover className={cn(styles.saveBtn, styles.breakDown)} onClick={()=>setBreakdown(true)}>Breakdown</Btn>
                                    </div>
                                    <div className={styles.controlCardValue}>{activeControl?.name}</div>
                                </div>
                                <div>
                                    <div className={cn(styles.controlCardLabel, styles.cardSpace)}>Description</div>
                                    <div className={styles.controlCardValue}>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: activeControl?.description
                                        }}
                                    />
                                    </div>
                                </div>
                            </div>
                            
                            <DocumentFormAttachments 
                                auditor={true}
                                attachments={attachments} 
                                setAttachments={setAttachments}
                                resources={resources} 
                                users={users} 
                                dispatch={dispatch} 
                                submission_id={submission?.id}/>
                        </div>
                        <div className={styles.riegthformBody}>
                            <div className={styles.formrisk}>
                                <div className={styles.dropDownRisk}>
                                    <div className={styles.riskTitle}>Risk Level : {(riskStatus && !submission) ? (activeControl?.controlRisk) : submission?.riskLevel}</div>
                                </div>
                                <div className={styles.formstatus}>
                                    <div className={styles.riskTitle}>Status</div>
                                    <div className={styles.statusRisk}>
                                        <Status status={submissionQuery?.data?.submission?.status || 0} big/>
                                    </div>
                                </div>
                            </div>
                            { accountPlan && accountPlan?.toLowerCase() !== 'individual' && <div className={styles.submissionDate}>
                                <div className={cn(styles.eventsListSubmission, {
                                    [styles.eventsListSubmissionLoader] : !activeControl
                                })}>
                                    {
                                        activeControl ? listEvents?.length > 0 && listEvents?.map(e=>(
                                            <div key={e.id}
                                                className={styles.eventItem}
                                                style={{borderLeft : `2px solid ${e.color}`}}
                                                onClick={()=> navigate('/calendar', { state: { event : e,  controlSubmissions: submission, framework : activeFramework, control :activeControl }})}>
                                                {EventType[e.color]} : {e.title}
                                            </div>
                                        )):<div className={styles.eventsLoader}><DotsLoader /></div>
                                    }
                                </div>
                            </div>}
                            <div className={cn(styles.taskList, {
                                [styles.topborderActivites] : !(accountPlan && accountPlan?.toLowerCase() !== 'individual')
                            })}>
                                <div className={styles.taskListTitle}>LAST ACTIVITY BY TASK</div>
                                <div className={styles.activitiesContainer}>
                                    {
                                        activities?.length > 0 && activities?.map((a,i)=> (
                                            <Task key={i} activities={a}/>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className={styles.listComments}>
                                <div className={styles.listCommentsTitle}>Comments</div>
                                <div className={styles.commentContainerDiv}>
                                {listComment?.length > 0 && listComment?.map((m, i) => (
                                    <Comment  owner={users?.find(u => u.id === m.user_id)} key={i} comment={m.comment} date={m.updated_at}/>                       ))}
                                </div>
                            </div>
                            <div className={styles.submitForm}>
                                <Input 
                                    comment
                                    onChange={comment.onChange} 
                                    type={'text'} 
                                    value = {comment.value}
                                    className={styles.inputComment} 
                                    placeholder={'Comment on Task ...'}
                                    />
                                <Btn className={cn(styles.submitBtn)} onClick={()=>addComment()} noHover>
                                    <div>Comment</div>
                                    {commentLoader && 
                                        <Loader />
                                    }
                                </Btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Popup bigger isOpenedPopup={breakdown} closePopup={()=>setBreakdown(false)}>
                <Breakdown framework={activeFramework} activeControl={activeControl} submission={submission} closePopup={()=>setBreakdown(false)} />
            </Popup>
        </div>
  )
}
