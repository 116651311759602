import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  events: [],
  isLoading: false,
};

const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setEventsIsLoading(state, action) {
      state.isLoading = action.payload;
    },

    setEvents(state, action) {
      state.events = action.payload;
    },
  },
});

export default eventsSlice.reducer;
export const { setEventsIsLoading, setEvents } = eventsSlice.actions;
