import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './index.module.scss'
import cn from 'classnames';

const toolbarOptions = [
  [{ 'header': [1, 2, false] }],
  ['bold', 'italic', 'blockquote'],
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
  ['link', 'image'],
  [{ 'align': [] }]
];

export const TextEditor = ({setData, data = '', error, editorRef}) => {

  const handleContentChange = (value) => {
    setData(value)
  };

  return (
    <ReactQuill
      ref={editorRef}
      value={data}
      onChange={(e) => handleContentChange(e)}
      modules={{ toolbar: toolbarOptions }}
      className={cn(styles.editorStyles, {[styles.error]: error})}
    />
  );
};
