import React, { useEffect, useRef, useState } from 'react'
import styles from './controlsActions.module.scss'
import stylesActions from './ControlsHeader.module.scss'
import cn from 'classnames'
import {  Btn } from '../../ui/Btn'
import { Search } from '../../ui/Search/Search'
import { useDispatch, useSelector } from 'react-redux'
import { axiosInstance } from '../../config/https'
import { toast } from '../../functions/toast'
import { SvgSprite } from '../../ui/SvgSprite'
import { DotsLoader, Loader } from '../../ui/Loader'
import { useLocation, useNavigate } from 'react-router-dom'
import { fetchControls, fetchControlsByFramework, fetchNonApplicableControls } from '../../../store/slices/documentsSlice'
import { filterControlsFn } from '../../functions/filterControlsFn'

export const ControlsActions = () => {
  const [controls, setControls] = useState(null)
  const [sortItem, setSortItem] = useState('default')
  const [searchData, setSearchData] = useState([])
  const [search, setSearch] = React.useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [loader, setLoader] = useState(false) 
  const [data, setData] = useState()
  const users = useSelector(state => state.organization.org.users)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation();
  const dataFramework = location.state;
  const controlsData = useSelector(state => state.documents.controls);
  const controlsByFramework = useSelector(state => state.documents.controlsByFramework);
  const nonApplicableControls = useSelector(state => state.documents.nonApplicableControls);
  const status = useSelector(state => state.documents.status);
  const [owner, setOwner] = useState(null)
  const [openActions, setOpenActions] = useState({
    actions : false,
    assign_control_owners: false,
    remove_control_owners: false,
    assign_stakeholders: false,
    remove_stakeholders: false,
    remove_controls: false,
  })

  const [selectedUsers, setSelectedUsers] = useState({
    assign_control_owners: [],
    remove_control_owners: [],
    assign_stakeholders: [],
    remove_stakeholders: [],
  })

  const [selectedFilter, setSelectedFilter] = useState([]);
  const [label] = useState({
    assign_control_owners: "Assign control owners",
    remove_control_owners: "Remove control owners",
    assign_stakeholders: "Assign stakeholders",
    remove_stakeholders: "Remove stakeholders",
    remove_controls: "Remove controls",
  });

  const dropdownRef = useRef(null);
  const dropdownRefChild = useRef(null);

  useEffect(() => {
      if (status === 'idle') {
        switch (dataFramework.id) {
          case 'all-controls':
              dispatch(fetchControls());
              break;
          case 'non-applicable':
              dispatch(fetchNonApplicableControls());
              break;
          default:
              dispatch(fetchControlsByFramework(parseInt(dataFramework.id)));       
              break;
        }
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, dispatch]);

  useEffect(()=>{
    const storedFilter = JSON.parse(localStorage.getItem("controls-filter"));
      switch (dataFramework.id) {
          case 'all-controls':
              setControls(filterControlsFn(controlsData, storedFilter || []));
              break;
          case 'non-applicable':
              setControls(filterControlsFn(nonApplicableControls, storedFilter || []));
              break;
          default:
              setControls(filterControlsFn(controlsByFramework[parseInt(dataFramework.id)]?.controls, storedFilter || []));
              break;
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlsData, controlsByFramework, dataFramework.id, nonApplicableControls])

  const compareBy = (key) => {
    return function (a, b) {
      if (a[key] < b[key]) return -1
      if (a[key] > b[key]) return 1
      return 0
    }
  }
  
  const sortBy = (key, direc) => {
    let arrayCopy = [...controls]
    if(direc === -1){
      arrayCopy.sort(compareBy(key)).reverse()
    }else{
      arrayCopy.sort(compareBy(key))
    }
    setData([...arrayCopy])
  }
    
  const svgDeselect = (<SvgSprite className={styles.svgIconsEmpty}  spriteID={'list'}></SvgSprite>)
  const svgSelect= (<SvgSprite className={styles.CustomSvgIcons}  spriteID={'check'}></SvgSprite>)

   
  useEffect(()=>{
    setSearchData(controls?.filter((item) => item?.name?.toLowerCase()?.includes(search?.toLowerCase())  ))
  }, [controls, search])

  const handelSave = async() => {
    setLoader(true)
      const data = {
        controlsIds : selectedItems,
        action : dataFramework.id === "non-applicable" ? "reactivate_controls" : selectedFilter[0],
        stakeholders : selectedUsers[selectedFilter[0]],
        owner : owner,
      }
      await axiosInstance.post('/bulk/action/', data)
      .then((res) => {
        console.log(dataFramework);
        dispatch(toast("success", res.data.message))
        if (dataFramework.id === "all-controls") {
          dispatch(fetchControls());
        } else if (dataFramework.id === "non-applicable") {
          dispatch(fetchNonApplicableControls());
        } else {
          dispatch(fetchControlsByFramework(parseInt(dataFramework.id))); 
        }
        navigate(-1);
      }).catch((err)=>{
        if (err.response && err.response.data && err.response.data.errors) {
          const errors = err.response.data.errors;
          let errorMessage = "";
          Object.keys(errors).forEach((key) => {
            errorMessage += `${errors[key].join(', ')}\n`;
          });
          dispatch(toast("error", errorMessage));
        } else if (err.response && err.response.data && err.response.data.message) {
          dispatch(toast("error", err.response.data.message));
        } else {
          dispatch(toast("error", "An error occurred."));
        }
      }).finally(()=> setLoader(false))
  }

  const handleFilterSelection = (filter) => {
    if (selectedFilter?.includes(filter)) {
      setSelectedFilter([...selectedFilter.filter(s => s !== filter)]);
    } else {
      setSelectedFilter([filter]);
    }
  };

  const openDropDown = (item, value) => {
    setOpenActions(prevState => ({
      ...prevState,
      [item]: value
    }));
  };

  const getSelectedFilterDisplayText = () => {
    if (!selectedFilter.length) return "Actions";

    const displayItems = selectedFilter.slice(0, 2).map(filter => {
      return label[filter];
    });

    return `${displayItems.join(', ')}${selectedFilter.length > 2 ? '...' : ''}`;
  };

  const handleCreateFilterList = (filterLabel, filterItem) => {
    if(filterLabel === 'assign_control_owners'){
      setOwner(filterItem)
    }else{
      setSelectedUsers(prevState => {
        const currentItems = prevState[filterLabel] || [];
        const newItem = filterItem;
  
        if (currentItems?.includes(newItem)) {
          return {
            ...prevState,
            [filterLabel]: currentItems.filter(item => item !== newItem)
          };
        } else {
          return {
            ...prevState,
            [filterLabel]: [...currentItems, newItem]
          };
        }
      });
    }
  };

  const getFilterDisplayText = (filterLabel, filterItems) => {
    if (!filterItems?.length) return `${label[filterLabel]}`;

    const displayItems = filterItems.slice(0, 2).map(item => {
        const user = users.find(u => u.id === item);
        return user ? user.firstName + " " + user.lastName : item;
    });

    return `${displayItems.join(', ')}${filterItems.length > 2 ? '...' : ''}`;
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenActions(prevState => {return {
        ...prevState,
        actions: false
      }});
    }
    if (dropdownRefChild.current && !dropdownRefChild.current.contains(event.target)) {
      setOpenActions(prevState => {return {
        ...prevState,
        assign_control_owners: false,
        remove_control_owners: false,
        assign_stakeholders: false,
        remove_stakeholders: false,
        remove_controls: false,
      }});
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
      <div className={styles.main}>
        <div className={cn(styles.header, styles.headerDocument)}>
          <div className={cn(styles.rieghtHeader, styles.rieghtHeaderHiegth)}>
            <div className={styles.titleCustomFramework} onClick={()=> navigate(-1)}>{dataFramework?.name}</div>
            {!(dataFramework?.id === "non-applicable") && <div className={styles.filterContainerActions}  ref={dropdownRef}>
              <button className={cn(styles.btnActionBulk, {[styles.activeFilter] : selectedFilter?.length > 0})} onClick={()=> openDropDown("actions", !openActions['actions'])}>
                {getSelectedFilterDisplayText()}
                <SvgSprite spriteID={"arrow"} />
              </button>
              {openActions.actions && <div className={stylesActions.selectFilter}>
                {Object.keys(label).map(filter => (
                  <div key={filter} value={filter} onClick={() => handleFilterSelection(filter)} className={cn(stylesActions.itemFilter, { [stylesActions.activeFilter]: selectedFilter?.includes(filter) })}>{label[filter]}</div>
                ))}
              </div>}
            </div>}
            {!selectedFilter.includes("remove_controls") && !selectedFilter.includes("remove_control_owners") && selectedFilter.map(f => (
            <div key={`#${f}`} className={styles.filterContainerActions} ref={dropdownRefChild}>
              <button className={cn(stylesActions.BtnFilters, { [stylesActions.activeFilterList]:  selectedUsers[f]?.length > 0})} onClick={() => openDropDown(f, !openActions[f])}>
                <div>{f === 'assign_control_owners' ? (owner ? (users?.find(u => u.id === owner)?.lastName +' '+ users?.find(u => u.id === owner)?.firstName) : "Select control owner") : getFilterDisplayText(f, selectedUsers[f])}</div>
                <SvgSprite spriteID={"arrow"} className={stylesActions.filterIconArrow} />
              </button>
              {openActions[f] && <div className={stylesActions.selectFilter}>
                {users?.map(user => (
                  <div 
                    key={`#${user.id}`} 
                    value={user.firstName + ' ' + user.lastName} 
                    onClick={() => handleCreateFilterList(f, user.id)}  
                    className={cn(stylesActions.itemFilter, { [stylesActions.activeFilter]: f === 'assign_control_owners' ? user.id === owner : selectedUsers[f].includes(user.id)})}>
                      {user.lastName + ' ' + user.firstName}
                  </div>
                ))}
              </div>}
            </div>
          ))}
          </div>
          <div className={styles.leftHeader}>
            <Btn disable={loader} className={styles.addBtn} onClick={handelSave}>
                {loader? <Loader /> : (dataFramework?.id === "non-applicable") ? "Reactivate" : 'Save' }
            </Btn>
          </div>
        </div>
        <div className={styles.subHeader}>
          <div className={styles.controls}>{selectedItems?.length} / {(search ? searchData : (data || controls))?.length} Controls</div>
          <div className={styles.gridSearch}>
            <div className={styles.search}><Search setSearch={setSearch} className={styles.search} placeholder={'Search Controls'}/></div>
          </div>
          <div className={styles.sort}>Sort By:
          <div className={cn(styles.sortItem, {[styles.sortItemActive]: sortItem === 'default'})} onClick={()=>{ setSortItem('default'); sortBy('default') } }>Default</div>
            <div className={cn(styles.sortItem, {[styles.sortItemActive]: sortItem === 'A-Z'})} onClick={()=>{ setSortItem('A-Z'); sortBy('name') } }>A-Z</div>
            <div className={cn(styles.sortItem, {[styles.sortItemActive]: sortItem === 'Z-A'})} onClick={()=>{ setSortItem('Z-A'); sortBy('name', -1) }}>Z-A</div>
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.table}>
            <div className={styles.tableHeader}>
              <div className={cn(styles.tableAction, styles.newFrameworkAction)}>Select</div>
              <div className={cn(styles.name, styles.nameHeader, styles.newFrameworkName, styles.newFrameworkNameAssign)}>Name</div>
              <div className={cn(styles.description, styles.newFrameworkDescrption)}>Description</div>
            </div>
            <div className={styles.TableBody}>
            {!controls ? 
                <div className={styles.bodyDataControls}><DotsLoader /></div> 
              : 
                (search ? searchData : (data || controls))?.map( (c, i) => 
                <div 
                    key={i} 
                    className={cn(styles.row, styles.newFrameworkRow)} 
                    onClick={()=>{
                        if (!selectedItems.includes(c?.control_id)) {
                            setSelectedItems([...selectedItems, c?.control_id]);
                        } else {
                            setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== c?.control_id));
                    }}}>
                    <div className={cn(styles.tableAction, styles.newFrameworkAction)}>
                        {selectedItems.includes(c?.control_id) ?  svgSelect: svgDeselect }
                    </div>  
                    <div className={cn(styles.name, styles.newFrameworkName)}>{c?.control_name}</div>
                    <div className={cn(styles.description, styles.newFrameworkDescrption)}>
                      <div dangerouslySetInnerHTML={{__html: c?.control_description}}/>
                    </div> 
                </div>
              )}
            </div>
          </div>
        </div>
    </div>
  )
}
