import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const DonutChart = ({colors, data}) => {

  const dataOfDonut = {
    datasets: [
      {
        label: '# number of Individuals Affected',
        data: data?.map(i => {return i.value}),
        backgroundColor: data?.map(i => {return i.color}),
        borderColor: data?.map(i => {return i.color}),
        borderWidth: 1,
      },
    ],
  };

  const options = {
    cutout:83,
  }

  return <Doughnut data={dataOfDonut} width={200} height ={200}  options={options}/>
}
