import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import cn from 'classnames'
import { axiosInstance } from '../../config/https'

// components
import { Btn } from '../../ui/Btn'
import { Link, useLocation } from 'react-router-dom'
import { Input } from '../../ui/Input'
import { useInput } from '../../hooks/useInput'
import { useDispatch } from 'react-redux'
import { toast } from '../../functions/toast'
import { Loader } from '../../ui/Loader'
import { checkErrors } from '../../functions/checkErrors'

export const VerifyEmail = () => {
  const email = useInput('')
  const location = useLocation()
  const [show, setShow] = useState(false)
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(false)
  const [errors, setErrors] = useState({})

  const resendEmail = async () => {
    try {
      const inputsInfo = [
        {el: email, errorName: 'email', errorText: 'Please enter your email'},
    ]

    if (await checkErrors(setErrors, inputsInfo)) {
        return
    }
      setLoader(true)
      const data = {
        email : email
      }
      const response = await axiosInstance.post(`/email/verify/resend`, data);

      if (response.status === 200) {
        // Display a success message to the user
        dispatch(toast('success', 'Email resent successfully!'))
        setLoader(false)
      } else {
        // Handle unexpected response status codes
        dispatch(toast('error', "Unexpected response:", response.data.message))
        setLoader(false)
      }
    } catch (error) {
      // Handle the error
      dispatch(toast('error', "Error resending email:", error))
      setLoader(false)
    }
  };

  useEffect(()=>{
    if(location?.state){
      email.setNewValue(location?.state)
      setShow(false)
    }else{
      setShow(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state])

  return (
    <div className={styles.container}>
        <div className={styles.form}> 
            <div className={styles.logo}></div>
            <div className={styles.Header}> Please verify your email address </div>
            {/* <h3>Get started with SecureLabs! Continue to your email account to verify</h3> */}
            <div className={styles.loginInputs}>
              {show && 
                <Input
                  onChange={email.onChange} 
                  type={'email'} 
                  className={styles.input} 
                  placeholder={'Email'}
                  error={errors.email}
                />
              }
              <div className={styles.loginBtn}>
                  <Btn disable={loader} onClick={resendEmail} type={'email'} className={cn(styles.btn, styles.btnSubmit)}> 
                    <div>Resend Email</div>
                    {loader && <Loader />}
                  </Btn>
              </div>
            </div>
            <div className={styles.signUpLink}><Link to={'/auth/login'}> Sign In</Link></div>

            <footer className={styles.footer}>
                  <nav>
                    <ul className={styles.footerNav}>
                       
                       <li><a href="https://app.termly.io/document/terms-of-use-for-website/f78e9e9b-bba1-4a94-983e-68a1005f28b0">Terms of Service</a></li>
                       <li><a href="https://app.termly.io/document/privacy-policy/3754276d-7c3f-40ef-931d-584c9bc61e74">Privacy Policy</a></li>
                    </ul>
                   
                  </nav>
                  <br></br>
                    <h5>© 2023 SecureLabs Inc </h5>
            </footer>
        </div>
        
       
    </div>
    
  )
}
