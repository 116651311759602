import React, { useState } from 'react'
import styles from './index.module.scss'
import { Faq } from '../../../StaticData'
import cn from 'classnames'

export const About = () => {
    const [ids, setIdes] = useState([])

    const handleShow = (id)=>{
        if(ids.includes(id)){
            setIdes([...ids.filter(i => i !== id)])
        }else{
            setIdes([...ids, id])
        }
    }
  return (
    <div>
      <div className={styles.main}>
            {Faq.map((f,i)=>(
                <div className={styles.faq} key={i}>
                    <div className={styles.faqQuestion} onClick={()=>handleShow(f.id)}>{f.question}</div>
                    <div className={cn(styles.faqResponse, {
                        [styles.showResponse] : ids.includes(f.id)
                        })}
                        dangerouslySetInnerHTML={{__html:f.response}}/>
                </div>
            ))}
      </div>
    </div>
  )
}
