import React, { useEffect, useState } from "react";
import { SvgSprite } from "../../ui/SvgSprite";
import styles from "./index.module.scss";
import cn from "classnames";
import { Btn } from "../../ui/Btn";
import { Select } from "../../ui/Select/Select";
import { ProfileImage } from "../../ui/ProfileImage/ProfileImage";
import { axiosInstance } from "../../config/https";
import { useDispatch, useSelector } from "react-redux";
import { SelectUser } from "../../ui/Select/SelectUser";
import { Comment } from "./Comment";
import { Status } from "./Status";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DotsLoader, Loader } from "../../ui/Loader";
import { Task } from "./Task";
import { getControlSubmissions } from "../../functions/getControlSubmission";
import { toast } from "../../functions/toast";
import { getFrameworks } from "../../functions/getFrameworks";
import { useQuery } from "@tanstack/react-query";
import { api } from "../../services/api";
import DocumentFormControlAndEvidence from "./DocumentFormControlAndEvidence";
import DocumentFormAttachments from "./DocumentFormAttachments";
import MentionEditor from "../../ui/MentionEditor/MentionEditor";

export const DocumentForm = () => {
  const [newValue, setNewValue] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const controlSubmissions = useSelector(state => state.controlSubmission.controlSubmission);
  const frameworks = useSelector(state => state.documents.frameworks);
  const users = useSelector(state => state.organization.org.users);
  const [owner, setOwner] = useState(null);
  const location = useLocation();
  const [riskChange, setRiskChange] = useState(false);
  const { state } = location;
  const [submission, setSubmission] = useState(null);
  const [activeControl, setActiveControl] = useState(null);
  const [activeFramework, setActiveFramework] = useState(null);
  const [stakeholders, setStakeholders] = useState([]);
  const [activities, setActivities] = useState([]);
  const [listComment, setListComment] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [listEvents, setListEvents] = useState([]);
  const accountPlan = useSelector(state => state.organization.org.account_plan);
  const [status, setStatus] = useState(true);
  const [showStatus, setShowStatus] = useState(false);
  const [statusLoader, setStatusLoader] = useState(false);
  const riskStatus = useSelector(state => state.organization.org.risk_status);
  const [resources, setResources] = useState([]);
  const { controlId } = useParams();
  const [controlLoader, setControlLoader] = useState(false);
  const [searchParams] = useSearchParams();

  const frameworkId = searchParams.get("frameworkId");

  const submissionQuery = useQuery({
    queryKey: ["framework", "control", { id: controlId }, "submission"],
    queryFn: () => {
      return api.fetchData(`/framework/control/${controlId}/submission`);
    },
  });

  const frameworkName =
    frameworkId !== "all-controls" ? frameworks.find(f => f.id === parseInt(frameworkId))?.name : "All Controls";

  const getControlData = async () => {
    setControlLoader(true);
    const queryParams = activeFramework?.id ? { frameworkId: activeFramework?.id } : {};
    axiosInstance
      .get(`/control/${controlId}`, { params: queryParams })
      .then(res => {
        setActiveControl(res.data.control);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => setControlLoader(false));
  };

  useEffect(() => {
    getControlData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFramework, controlId]);

  const EventType = {
    "#8833FF": "General",
    "#E62E2E": "Audit",
    "#33BFFF": "Review",
    "#29CC39": "Task",
    "#000000": "Incident",
  };
  const [risk] = useState([
    {
      id: 0,
      name: "-",
      value: "-",
      color: "#ccc",
    },
    {
      id: 1,
      name: "High",
      value: "high",
      color: "#E02020",
    },
    {
      id: 2,
      name: "Significant",
      value: "significant",
      color: "#F7B500",
    },
    {
      id: 3,
      name: "Moderate",
      value: "moderate",
      color: "#FAFA00",
    },
    {
      id: 4,
      name: "Minor",
      value: "minor",
      color: "#6DD400",
    },
    {
      id: 5,
      name: "Negligible",
      value: "negligible",
      color: "#2EE6CA",
    },
  ]);

  const saveControls = async () => {
    if (submission?.riskLevel === newValue || !riskChange) {
      return false;
    }
    const data = {
      riskLevel: newValue === "-" ? 1 : newValue,
      control_id: activeControl?.id,
      assignedTo: null,
    };
    if (!submission && data.riskLevel !== 1 && data.riskLevel !== null) {
      await axiosInstance
        .post(`/submission/control`, data)
        .then(res => {
          // setSubmission(res.data.data)
          // activities?.length > 0 ? setActivities([...res.data.meta_data, ...activities]) : setActivities([...res.data.meta_data])
          dispatch(toast("success", "Risk Updated Successfully"));
        })
        .catch(err => {
          dispatch(toast("error", err?.response?.data?.message));
        })
        .finally(() => {
          setRiskChange(false);
        });
    } else if (submission) {
      await axiosInstance
        .patch(`/submission/control/${submission.id}`, data)
        .then(res => {
          // setSubmission(res.data.data)
          // setActivities([...res.data.meta_data, ...activities])
          dispatch(toast("success", "Risk Updated Successfully"));
        })
        .catch(err => {
          dispatch(toast("error", err?.response?.data?.message));
        })
        .finally(() => {
          setRiskChange(false);
        });
    }
    await dispatch(getControlSubmissions());
    setRiskChange(false);
  };

  useEffect(() => {
    (async () => {
      await saveControls();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newValue]);

  useEffect(() => {
    setActiveFramework(state?.framework);
  }, [state]);

  useEffect(() => {
    controlSubmissions?.length > 0 &&
      (async () => {
        setSubmission(controlSubmissions?.find(c => c.control_id === parseInt(controlId)));
      })();
  }, [controlSubmissions, controlId, submission, users, attachments]);

  useEffect(() => {
    (async () => {
      await Promise.all([dispatch(getControlSubmissions())]);
    })();
  }, [dispatch]);

  useEffect(() => {
    setStakeholders(submissionQuery?.data?.submission?.stakeholders);
    setActivities(submissionQuery?.data?.submission?.controls_activities?.reverse());
    setListComment(submissionQuery?.data?.submission?.comments?.reverse());
    setAttachments(submissionQuery?.data?.submission?.attachments);
    setListEvents(submissionQuery?.data?.submission?.events);
    setResources(submissionQuery?.data?.submission?.resources);
    setOwner(submissionQuery?.data?.submission?.owner);
  }, [submissionQuery?.data]);

  const statusFunction = async () => {
    setStatusLoader(true);
    setShowStatus(false);
    setStatus(!status);
    const data = {
      status: status,
    };

    await axiosInstance
      .post(`hide/control/${activeControl?.id}`, data)
      .then(async res => {
        await dispatch(getFrameworks());
        dispatch(toast("success", res?.data?.message));
      })
      .catch(err => {
        dispatch(toast("error", err?.response?.data?.message));
      })
      .finally(() => {
        setStatusLoader(false);
      });

    navigate(`/framework/${activeFramework?.id}`);
  };

  return (
    <div>
      <div className={cn(styles.main, { [styles.removeBorder]: true })}>
        <div className={styles.borderContainer}>
          <div className={styles.header}>
            <div className={styles.rieghtHeader}>
              {!activeFramework && frameworkId && (
                <>
                  <div
                    className={cn(styles.headerFramework, styles.paddingTo)}
                    onClick={() => navigate(`/controls/${frameworkId}`)}
                  >
                    {frameworkName}
                  </div>

                  <SvgSprite
                    spriteID={"arrow"}
                    className={styles.iconTo}
                  />
                </>
              )}
              <div
                className={cn(styles.headerFramework, styles.paddingTo)}
                onClick={() => navigate(`/framework/${activeFramework?.id}`)}
              >
                {activeFramework?.name}
              </div>
              {activeFramework?.name && (
                <SvgSprite
                  spriteID={"arrow"}
                  className={styles.iconTo}
                />
              )}
              <div
                className={cn(styles.headerFramework, styles.paddingTo)}
                onClick={() => navigate(`/framework/${activeFramework?.id}`)}
              >
                {controlLoader ? (
                  <div className={styles.controlLoader}>
                    <DotsLoader />
                  </div>
                ) : (
                  activeControl?.name
                )}
              </div>
              {activeControl?.article_numbers?.length > 0 && (
                <>
                  <SvgSprite
                    spriteID={"arrow"}
                    className={styles.iconTo}
                  />
                  {activeControl?.article_numbers?.length > 3 ? (
                    <div className={styles.tooltip}>
                      {activeControl.article_numbers.slice(0, 3).map((item, index) => (
                        <div
                          key={index}
                          className={styles.articleNumbers}
                        >{`${item}`}</div>
                      ))}{" "}
                      ...
                      <div className={styles.tooltiptext}>
                        {activeControl.article_numbers.slice(3).map((item, index) => (
                          <div
                            key={index}
                            className={styles.articleNumbers}
                          >
                            {item}
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    activeControl.article_numbers.map((item, index) => (
                      <div
                        key={index}
                        className={styles.articleNumbers}
                      >{`${item}`}</div>
                    ))
                  )}
                </>
              )}

              <div className={styles.divStatus}>
                <Btn
                  className={cn(styles.btnStatus, styles.paddingTo)}
                  onClick={() => setShowStatus(!showStatus)}
                  disable={statusLoader}
                >
                  {" "}
                  {statusLoader ? <Loader /> : "Status"}
                </Btn>
                {showStatus && (
                  <div className={cn(styles.actionsContentStatus, styles.dropResourcActionsContent)}>
                    <div
                      className={styles.dropResourceBtn}
                      onClick={() => statusFunction()}
                    >
                      <input
                        type="checkbox"
                        onChange={() => console.log("")}
                        checked={status}
                      />
                      <div> Control Applicable </div>
                    </div>
                  </div>
                )}
              </div>
              {activeControl?.control_function && (
                <div className={styles.divStatus}>
                  <div className={cn(styles.btnStatus, styles.paddingTo, styles.colorFunction)}>
                    {activeControl?.control_function}
                  </div>
                </div>
              )}
            </div>
            <div
              className={styles.leftHeader}
              onClick={() => navigate(`/framework/${activeFramework?.id ? activeFramework?.id : "AllControls"}`)}
            >
              <SvgSprite
                className={styles.closeIcon}
                spriteID={"close"}
              />
            </div>
          </div>
          <div className={styles.header}>
            <div className={styles.rieghtHeader}>
              <div className={cn(styles.headerFrameworkUser, styles.ownerList)}>
                <div className={styles.ownerName}>Controls Owner</div>
                {owner && (
                  <ProfileImage
                    name={owner?.firstName + " " + owner?.lastName}
                    className={styles.popupInconOwner}
                  />
                )}
              </div>
              <div className={cn(styles.headerFrameworkUser, styles.ownerList)}>
                <div className={styles.ownerName}>Control Stakeholders</div>
                <div className={styles.listStakeholdersImage}>
                  {stakeholders?.map((owner, i) => (
                    <ProfileImage
                      key={i}
                      name={owner.firstName + " " + owner.lastName}
                      className={styles.popupInconOwner}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className={styles.leftHeader}>
              <SelectUser
                owner={owner}
                setOwner={setOwner}
                users={users}
                controlId={activeControl?.id}
                submission={submission}
                setStakeholders={setStakeholders}
                stakeholders={stakeholders}
                setActivities={setActivities}
                activities={activities}
              />
            </div>
          </div>
          <div className={styles.formBody}>
            <div className={styles.leftformBody}>
              <DocumentFormControlAndEvidence
                activeControl={activeControl}
                ResourcesIds={resources?.map(r => {
                  return r.id;
                })}
                dispatch={dispatch}
                submission={submission}
                resources={resources}
                setResources={setResources}
                attachments={attachments}
                setAttachments={setAttachments}
                activeFramework={activeFramework}
                controlLoader={controlLoader}
              />

              <DocumentFormAttachments
                attachments={attachments}
                setAttachments={setAttachments}
                resources={resources}
                users={users}
                dispatch={dispatch}
                submission_id={submission?.id}
                frameworkId={frameworkId}
              />
            </div>
            <div className={styles.riegthformBody}>
              <div className={styles.formrisk}>
                <div className={styles.dropDownRisk}>
                  <div className={styles.riskTitle}>Risk Level</div>
                  <Select
                    onClick={() => setRiskChange(true)}
                    className={styles.selectRisk}
                    values={risk}
                    setNewValue={setNewValue}
                    initValue={riskStatus && !submission ? activeControl?.controlRisk : submission?.riskLevel}
                    color
                  />
                </div>
                <div className={styles.formstatus}>
                  <div className={styles.riskTitle}>Status</div>
                  <div className={styles.statusRisk}>
                    <Status
                      submission={submission}
                      resources={attachments}
                      big
                    />
                  </div>
                </div>
              </div>
              {accountPlan && accountPlan?.toLowerCase() !== "individual" && (
                <div className={styles.submissionDate}>
                  <div className={styles.dateRisk}>
                    <Btn
                      className={styles.datRiskBtn}
                      noHover
                      onClick={() =>
                        navigate("/calendar", {
                          state: { controlSubmissions: submission, framework: activeFramework, control: activeControl },
                        })
                      }
                    >
                      <SvgSprite
                        spriteID={"calendar"}
                        className={styles.calendarIcon}
                      />
                      <div>Add to Calendar</div>
                    </Btn>
                  </div>
                  <div
                    className={cn(styles.eventsListSubmission, {
                      [styles.eventsListSubmissionLoader]: !activeControl,
                    })}
                  >
                    {activeControl ? (
                      listEvents?.length > 0 &&
                      listEvents?.map(e => (
                        <div
                          key={e.id}
                          className={styles.eventItem}
                          style={{ borderLeft: `2px solid ${e.color}` }}
                          onClick={() =>
                            navigate("/calendar", {
                              state: {
                                event: e,
                                controlSubmissions: submission,
                                framework: activeFramework,
                                control: activeControl,
                              },
                            })
                          }
                        >
                          {EventType[e.color]} : {e.title}
                        </div>
                      ))
                    ) : (
                      <div className={styles.eventsLoader}>
                        <DotsLoader />
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div
                className={cn(styles.taskList, {
                  [styles.topborderActivites]: !(accountPlan && accountPlan?.toLowerCase() !== "individual"),
                })}
              >
                <div className={styles.taskListTitle}>LAST ACTIVITY BY TASK</div>
                <div className={styles.activitiesContainer}>
                  {activities?.length > 0 &&
                    activities?.map((a, i) => (
                      <Task
                        key={i}
                        activities={a}
                      />
                    ))}
                </div>
              </div>
              <div className={styles.listComments}>
                <div className={styles.listCommentsTitle}>Comments</div>
                <div className={styles.commentContainerDiv}>
                  {listComment?.length > 0 &&
                    listComment?.map((m, i) => (
                      <Comment
                        owner={users?.find(u => u.id === m.user_id)}
                        key={i}
                        comment={m.comment}
                        date={m.updated_at}
                      />
                    ))}
                </div>
              </div>
              <div className={styles.submitForm}>
                {users && (
                  <MentionEditor
                    mentions={
                      users?.map(u => ({
                        id: u.id,
                        name:
                          (u.firstName + " " + u.lastName)?.charAt(0).toUpperCase() +
                          (u.firstName + " " + u.lastName).slice(1),
                        role: u.role,
                      })) || []
                    }
                    activeControlId={activeControl?.id}
                    submissionId={submission?.id}
                    listComment={listComment}
                    activities={activities}
                    setActivities={setActivities}
                    setListComment={setListComment}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
