import React from 'react'
import styles from './index.module.scss'
import { SvgSprite } from "../SvgSprite/SvgSprite";
import cn from 'classnames';
export const Search = ({className, icon, placeholder, setSearch, value}) => {
  const handleSearch = (event) => {
    setSearch && setSearch(event.target.value);
  };
  
  return (
    <div className={cn(styles.container, {[className] : className})}>
        <SvgSprite spriteID={'search'} className={styles.svgSearch}/>
        <input type={'text'} value={value} onChange={handleSearch} className={styles.input} placeholder={placeholder?placeholder:'Search'}/>
        {icon && <SvgSprite spriteID={'arrow'} className={styles.svgSubmit}/>}
    </div>
  )
}
