import React from 'react'
import { Btn } from '../Btn'
import { SvgSprite } from '../SvgSprite'
import styles from './index.module.scss'
import cn from 'classnames'
import { Loader } from '../Loader'

export const RemoveFile = ({closePopup, fileName, handleRemove, deleteFileLoader}) => {
  return (
    <div className={styles.addPopup}>
        <div className={styles.controlPopupHeader}>
            <div>Remove {fileName}</div>
            <SvgSprite spriteID={'close'} className={styles.closeIconPopup} onClick={closePopup}/>
        </div>
        <div className={styles.controlPopupBody}>
            <Btn className={cn(styles.fileBtn, styles.cancelBtn)} onClick={closePopup}>Cancel</Btn>
            <Btn className={cn(styles.fileBtn, styles.removeBtn)} onClick={handleRemove}>{deleteFileLoader ? <Loader /> : "Remove" }</Btn>
        </div>
    </div>
  )
}
