import React, { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { Btn } from '../../ui/Btn'
import { SvgSprite } from '../../ui/SvgSprite'
import cn from 'classnames'
import { Select } from '../../ui/Select/Select'
import { Input } from '../../ui/Input/Input'
import { useDispatch, useSelector } from 'react-redux'
import { axiosInstance } from '../../config/https'
import { toast } from '../../functions/toast'
import { Loader } from '../../ui/Loader'

export const UpdatePlan = () => {
    const [number, setNumber] = useState('');
    const [cvc, setCvc] = useState('');
    // const [type, setType] = useState('month');
    const [monthYear, setMonthYear] = useState('');
    const [loader, setLoader] = useState('')
    const accountPlan =useSelector(state => state.organization.org.account_plan)
    const dispatch = useDispatch()
    const [proratedAmount, setProratedAmount] = useState(0)
    const [priceLoader, setPriceLoader] = useState(false)
    const [loadingDots, setLoadingDots] = useState('');

    useEffect(() => {
        if(priceLoader){
            const intervalId = setInterval(() => {
            setLoadingDots(prev => prev.length < 3 ? prev + '.' : '');
            }, 500);

            return () => clearInterval(intervalId);
        }
    }, [priceLoader]);

    const [seats] = useState([
      {
          id: 0,
          name:  '3 seats',
          value: 3,
      },
      {
          id: 1,
          name:  '5 seats',
          value: 5,
      },
      {
          id: 2,
          name:  '10 seats',
          value: 10,
      },
      {
          id: 3,
          name:  '15 seats',
          value: 15,
      },
      {
          id: 4,
          name:  '20 seats',
          value: 20,
      },
      {
          id: 5,
          name:  '25 seats',
          value: 25,
      },
      {
          id: 6,
          name:  '30 seats',
          value: 30,
      },
      {
          id: 7,
          name:  '40 seats',
          value: 40,
      },
      {
          id: 8,
          name:  '50 seats',
          value: 50,
      },
      {
          id: 9,
          name:  '100 seats',
          value: 100,
      },
     ]
  )
  
  const [newPlan, setNewPlan] = useState()
  const [newSeat, setNewSeat] = useState()
  const [listPlanForIndividual] = useState([
        {
            id : 0,
            name : 'Basic',
            value : 'basic'
        },
        {
          id: 1,
          name:  'Standard',
          value: 'standard',
        },
    ]
)
const [listPlanForBasic] = useState([
    {
        id: 0,
        name:  'Standard',
        value: 'standard',
    },
   ]
)
  
//   const [listType] = useState([
//     {
//         id: 0,
//         name:  'Current',
//         value: 'current',
//     },
//     {
//         id: 1,
//         name:  'Monthly',
//         value: 'month',
//     },
//     {
//         id: 2,
//         name: 'Yearly',
//         value: 'year',
//     },
//    ]
//   )
  
    const handleSave = () =>{
        try {
            setLoader(true)
            if(newPlan && cvc && number && monthYear && newSeat)
            {
                const date = new Date(monthYear);
                const month = date.getMonth() + 1;
                const year = date.getFullYear();
                let mode = '';
                if (process.env.NODE_ENV === 'development') {
                  mode = 'test'
                }

                const data = {
                    plan : newPlan,
                    number : parseInt(number),
                    exp_month: month,
                    exp_year: year,
                    cvc : parseInt(cvc),
                    quantity : newPlan === 'individual' ? 2 : parseInt(newSeat),
                    mode : mode
                }
                const dataJson =  JSON.stringify(data)
                axiosInstance.patch('/subscribe/upgrade', dataJson)
                .then((res)=>{
                    dispatch(toast('success', res?.data?.message))
                }).catch((err)=>{
                    dispatch(toast('error', err?.response?.data?.message))
                }).finally(()=>{
                    setLoader(false)
                })
            }else{
                setLoader(false)
                return false
            }   
        } catch (error) {
            setLoader(false)
        }
    }

    useEffect(()=>{
        getProratedAmount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newSeat, newPlan])

    const getProratedAmount = () => {
        try {
            if(newPlan){
                setPriceLoader(true)
                axiosInstance(`subscribe/get/prorated/amount/${newPlan}/${newSeat || 3}`)
                .then((res)=>{
                    setProratedAmount(res.data.proratedAmountInDollars)
                }).catch((err)=>{
                    console.log(err)
                }).finally(()=>{
                    setPriceLoader(false)
                })
            }
        } catch (error) {
            setPriceLoader(false)
        }
    }

  return (
    <div>
        <div className={styles.planHeader}>
            <div className={styles.HeaderPlanText}>
                <SvgSprite className={styles.settingsIconText} spriteID={'members'}></SvgSprite>
                <div>
                    Plan
                </div>
            </div>
            <Btn disable={loader} className={cn(styles.AddUserBtn, styles.btnCenter)} onClick={() => {handleSave()} }>
                <div>Save</div>
                {loader && <Loader />}
            </Btn>
        </div>
        <div className={styles.planBody}>
            <div className={styles.planForm}>
                <div className={styles.headerPlanTitle}>Upgrade Current Plan to {newPlan?.charAt(0).toUpperCase()+newPlan?.slice(1,)?.toLowerCase()} for {priceLoader ? loadingDots :proratedAmount + ' $'}</div>
                <div className={cn(styles.gridInvitFormInfo, styles.planContainer)}>
                    <div className={styles.customInput}>
                        <label className={styles.label} >Plan</label>
                        <Select selectFullWidth 
                            className={styles.selectRole}
                            setNewValue={setNewPlan} 
                            values={accountPlan?.toLowerCase() === 'basic' ? listPlanForBasic : listPlanForIndividual}/>
                    </div>
                    <div className={styles.customInput}>
                        {/* <label className={styles.label} >Subscription Type</label>
                        {newPlan !== 'individual' ?<Select selectFullWidth 
                            className={styles.selectRole} 
                            setNewValue={setType} 
                            initValue={'month'}
                            values={listType}/>
                        :
                            <Input 
                                className={styles.selectRoleInput} 
                                value={'Month'}
                                disabled={true}
                                onChange = {(e)=>{}}
                                />
                            } */}
                    </div>
                    <div className={styles.customInput}>
                        <label className={styles.label} >Choose Team Size</label>
                        {newPlan !== 'individual' ? <Select selectFullWidth 
                            className={styles.selectRole} 
                            setNewValue={setNewSeat} 
                            values={seats}/>
                            :
                            <Input 
                                className={styles.selectRoleInput} 
                                value={'2 seats'}
                                disabled={true}
                                onChange = {(e)=>{}}

                            />
                        }
                    </div>
                    <div className={styles.customInput}>
                        <label className={styles.label} >Card Number</label>
                         <Input 
                            type="text" 
                            pattern="[0-9]{16}"
                            maxlength="16"
                            id="number" 
                            value={number} 
                            onChange={(e) => setNumber(e.target.value)} 
                            className={styles.inputUser}/>
                    </div>
                    <div className={styles.customInput}>
                        <label className={styles.label} >Expiration Date</label>
                        <Input 
                            type="month" 
                            id="expYear" 
                            value={monthYear} 
                            onChange={(e) => setMonthYear(e.target.value)} 
                            className={styles.inputUser} />
                    </div>
                    <div className={styles.customInput}>
                        <label className={styles.label} >CVC</label>
                        <Input 
                            type="text" 
                            minlength="3" 
                            maxlength="4" 
                            id="cvc" 
                            value={cvc} 
                            onChange={(e) => setCvc(e.target.value)} 
                            className={styles.inputUser} />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
