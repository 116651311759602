import React from 'react'
import styles from './index.module.scss'
import cn from 'classnames'

// comp
// import { SvgSprite } from "../SvgSprite/SvgSprite";
import { DotsLoader } from '../Loader';
import { Link } from 'react-router-dom';

export const Card = ({className, big, small, name, color = '#ccc', number, loader, to}) => {

    return (
    <div className={cn(styles.container, {
        [styles.bigCard] : big,
        [styles.smallCard] : small,
        [className] : className
    })} style={{backgroundColor : color}}>
        <div className={styles.cardHeader}>
            <div className={styles.cardHeaderText}>{name}</div>
            {/* <SvgSprite spriteID={'more'} className={styles.moreSvg}/> */}
        </div>
        <div className={styles.cardBody}>
            <div className={styles.cardHeaderNumber}>{loader?<DotsLoader className={cn({[styles.equelHighte]: small})} white/>:number}</div>
            {big && <Link 
                className={styles.btnViewAll}
                style={{backgroundColor : color}}
                to={to}
            >
                View All
            </Link>
            }
        </div>
    </div>
  )
}
