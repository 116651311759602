import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getIncidentReport } from '../../functions/getIncidentReport'
import { FormReport } from './FormReport'
import styles from './index.module.scss'

export const Report = () => {
  const [reports, setReports] = useState([])
  const users = useSelector(state => state.organization.org.users)
  const dispatch = useDispatch()
  const allReports = useSelector(state => state.incidentReport.incidentReport)

  useEffect(() => {
      (async () => {
          await Promise.all([
              !allReports && dispatch(getIncidentReport()),
          ])
      })()
  }, [dispatch, allReports])

  return (
    <div>
      <div className={styles.main}>
          <FormReport 
            setReports={setReports} 
            reports={reports}
            users={users}
          />
      </div>
    </div>
  )
}
