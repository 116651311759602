
const prod = {
    url: {
        API_URL: process.env.REACT_APP_API_HOST,
    }
}

const dev = {
    url: {
        API_URL: process.env.REACT_APP_API_HOST_DEV
    }
}

export const config = process.env.NODE_ENV === 'development' ? dev : prod
