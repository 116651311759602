import React, { useState, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import styles from "./index.module.scss";
import cn from "classnames";
import { ProfileImage } from "../../ui/ProfileImage/ProfileImage";
import { DotsLoader } from "../../ui/Loader";
import { getControlsState } from "../../functions/getControlsState";
import { getEvents } from "../../functions/getEvents";
import { Popup } from "../../ui/Popup";
import { AddEventComponent } from "../Calendar/AddEventComponent";

export const CalendarWidget = ({
  allEvents,
  setDropSort,
  sortBy,
  events,
  setEvents,
  setDropStatus,
  setDropType,
  dropStatusValue,
  dropTypeValue,
  dropdownRef,
  dropdownRefStatus,
  dropdownRefType,
}) => {
  const dispatch = useDispatch();

  const getHomeData = useCallback(async () => {
    await Promise.all([dispatch(getControlsState()), dispatch(getEvents())]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getHomeData();
  }, [getHomeData]);

  useEffect(() => {
    const handleClickOutside = event => {
      // Close the dropdown if the click is outside the dropdown
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropSort(false);
      }

      if (dropdownRefStatus.current && !dropdownRefStatus.current.contains(event.target)) {
        setDropStatus(false);
      }

      if (dropdownRefType.current && !dropdownRefType.current.contains(event.target)) {
        setDropType(false);
      }
    };

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Detach the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownRef]);

  return (
    <div className={styles.calendarWidget}>
      <div className={styles.list}>
        <EventsList
          events={events || []}
          loader={allEvents.isLoading}
          sortBy={sortBy}
          status={dropStatusValue}
          type={dropTypeValue}
          setEvents={setEvents}
        />
      </div>
    </div>
  );
};

function EventsList({ events, loader, sortBy, status, type, setEvents }) {
  const [event, setEvent] = useState(false);

  return (
    <div className={styles.containerListUser}>
      {!loader &&
        events?.map(data => (
          <div
            onClick={() => {
              setEvent(data);
            }}
            className={cn(styles.userList, styles.item)}
            key={data.id}
          >
            <div className={styles.userInfo}>
              <ProfileImage name={(data?.members[0]?.firstName || "N") + " " + (data?.members[0]?.lastName || "/")} />
              <div className={styles.eventsDetails}>
                <div className={styles.eventsTitle}> {data.startTime} </div>
                <div className={styles.eventsText}> New Event : {data.title} </div>
                <div className={styles.eventsTitle}>
                  <span
                    className={styles.eventLabel}
                    style={{ background: data?.completed_at ? "#32bf32" : "#ffca00" }}
                  >
                    {data?.completed_at ? "completed" : "Pending"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      {event && (
        <Popup
          calendar
          isOpenedPopup={event}
          closePopup={() => {
            setEvent(null);
          }}
        >
          <AddEventComponent
            closePopup={() => {
              setEvent(null);
            }}
            title={"Add Event"}
            setEvents={setEvents}
            events={events}
            startAccessor="start"
            endAccessor="end"
            stateData={event}
            newEvent={event}
            report_id={event?.report_id}
            report_title={event?.title}
            report={event?.report_id || event?.report}
            updateEvent
          />
        </Popup>
      )}
      {!loader && events?.length === 0 && (
        <div className={cn(styles.loaderCenter, styles.loaderStyles)}>
          No {status !== "all" ? status : ""} {type !== "all" ? type : ""} events available{" "}
          {sortBy !== "all" ? `this ${sortBy}` : ""}
        </div>
      )}
      {loader && (
        <div className={cn(styles.loaderCenter, styles.loaderStyles)}>
          <DotsLoader />
        </div>
      )}
    </div>
  );
}
