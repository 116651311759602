import React from 'react'
import styles from './index.module.scss' 
import cn from 'classnames'

export const DotsLoader = ({white, className}) => {
  return <div className={cn(styles.dotWindmill, {
    [styles.whiteDots] : white,
    [className] : className
  })} />
}
