import React, { useCallback, useEffect, useState } from 'react'
import { Btn } from '../../ui/Btn/Btn'
import { DotsLoader } from '../../ui/Loader'
import { SvgSprite } from '../../ui/SvgSprite/SvgSprite'
import { Search } from '../../ui/Search/Search'
import { Popup } from '../../ui/Popup'
import { toast } from '../../functions/toast'
import { axiosInstance } from '../../config/https'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import styles from './index.module.scss'


const AddAttachments = ({file, setFile, resourcesIds, setResourcesIds, dispatch}) => {
    const [displayResources, setdisplayResources] = useState(false)
    const resources = useSelector(state => state.resources.resources)
    const [search, setSearch] = useState('')
    const [data, setData] = useState([])
    const [listRepositories, setListRepositories] = useState([])
    const [activeRepository, setActiveRepository] = useState(null);
    const [listOfRepositoryResources, setListOfRepositoryResources] = useState([])
    const [resourcesLoad, setResourcesLoad] = useState(false)
    const [repositoryLoad, setRepositoryLoad] = useState(false)

   const handleUpload = (e) => {
        const selectedFiles = Array.from(e.target.files);

        if (selectedFiles.length + file.length <= 5) {
            const updatedFiles = file.length > 0 ? [...file, ...selectedFiles] : selectedFiles;
            setFile(updatedFiles);
        } else {
            dispatch(toast('error', 'The maximum number of files is 5'))
        }
    };

    const removeFileFromList = useCallback((removedFile) => {
        setFile((prevFiles) =>
          prevFiles.filter((f) => (removedFile instanceof File ? f !== removedFile : f.id !== removedFile.id))
        );
    }, [setFile]);
    
    const select = useCallback((res) => {
        setResourcesIds((prevIds) =>
          prevIds.find((r) => r.id === res.id) ? prevIds.filter((r) => r.id !== res.id) : [...prevIds, res]
        );
    }, [setResourcesIds]);
    
    useEffect(() => {
        setData(search ? resources?.filter((item) => item.policyName?.toLowerCase().includes(search?.toLowerCase())) : resources);
    }, [search, resources]);

    const getRepository = useCallback(async () =>{
        setRepositoryLoad(true)
        await axiosInstance('/repositories')
        .then((res)=>{
          setListRepositories([...res.data.data])
        }).catch((err)=>{
          dispatch(toast('error', err?.response?.data?.message))
        }).finally(()=> setRepositoryLoad(false))
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [listRepositories])
    
      useEffect(()=>{
        getRepository()
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

      const getExt = (path) =>{
        return path.substring(path.lastIndexOf(".")+1)
      }    


    const getRepositoryResources = useCallback(()=>{
        setResourcesLoad(true)
        axiosInstance(`repositories/${activeRepository?.id}`)
        .then((res)=>{
          setListOfRepositoryResources([...res?.data?.data?.resources])
        })
        .catch((err)=>{
          dispatch(toast('error', err?.response?.data?.message))
        }).finally(()=>setResourcesLoad(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeRepository])

    useEffect(()=>{
        activeRepository && getRepositoryResources()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getRepositoryResources])

    return(
        <>
            <div className={styles.documentFormUoImage}>
                <div className={styles.gridTemplate}>
                    <div><SvgSprite spriteID={"file"}  className={styles.labelAttachment} /></div>
                    <div className={styles.gridTemplate}>
                        <div className={styles.upload}>
                            <input 
                                disable={(file?.length > 5)+''}
                                type="file" 
                                accept=".pdf,.doc,.docx,.jpg,.jpeg,.png,.gif"
                                multiple
                                name="attachment[]"
                                onChange={(e) => handleUpload(e)}/>
                            <div className={styles.customUploadBtn}> Upload Files </div>
                        </div>
                        <div className={cn(styles.customUploadBtn, styles.resourcesRow)} onClick={() => {setdisplayResources(true) }}> Browse Resources </div>
                        <div className={styles.listFiles}>
                            {Array.isArray(file) && file?.map((f, i)=>(
                                <div key={i} className={styles.wrapFileName}>
                                    <div className={styles.listFileName}>{f['filename'] || f['name']}</div>
                                    <SvgSprite spriteID={"close"} className={styles.closeFile} onClick={()=>removeFileFromList(f)}/>
                                </div>
                            ))}
                        </div>
                        <div className={styles.listFiles}>
                            {resourcesIds && resourcesIds?.map((r, i)=>(
                                <div key={i} className={styles.wrapFileName}>
                                    <div className={styles.listFileName}>{r.policyName}</div>
                                    <SvgSprite spriteID={"close"} className={styles.closeFile} onClick={()=>select(r)}/>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Popup big={true} isOpenedPopup={displayResources} closePopup={() => setdisplayResources(false)}> 
                <div className={styles.addPopupFramwork}>
                
                    <div className={styles.topPopupAddFramwork}>
                        <div className={styles.addframworkTitle}> Add Resources </div>
                        <div className={styles.searchFramworkPopup}>
                            <Search setSearch={setSearch} className={styles.search} placeholder={'Search Resources'}/>                       
                        </div>
                    </div>

                    {/* List Of Repositories And Resources */}
                    {!activeRepository && (
                        (data?.length > 0) ? (
                            <div className={cn(styles.gridRow, styles.individualPlan)}>
                                {listRepositories?.map((r, i) => (
                                    <div key={i} className={cn(styles.gridItem)} onClick={() => setActiveRepository(r)}>
                                        <div className={styles.bodyTypeContainer}>
                                            <SvgSprite spriteID={'folder'} className={styles.folderStyles} />
                                        </div>
                                        <div className={styles.centerFrameworkName}>{r.name} </div>
                                    </div>
                                ))}
                                {(data?.filter(r => r.organization_id != null) || []).map((r, i) => (
                                    <div key={i} className={cn(styles.gridItem, {
                                        [styles.selectedFramwork]: resourcesIds && resourcesIds?.find(res => res.id === r.id),
                                    })} onClick={() => select(r)}>
                                        <div className={styles.bodyTypeContainer}>
                                            <div className={styles.extention}>{getExt(r.policyDocument)}</div>
                                        </div>
                                        <div className={styles.centerFrameworkName}>{r.policyName} </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className={styles.NoResourcesFound}>
                                {repositoryLoad ? <div className={cn(styles.loaderResources)}><DotsLoader /></div> : 'No Resources Found' }
                            </div>
                        )
                    )}

                    {activeRepository && <div className={styles.repositoryPathDiv}> 
                        <div className={styles.repositoryPath} onClick={()=>{setActiveRepository(null);setListOfRepositoryResources([])}}>{"Resources "}</div> 
                        <div className={styles.repositoryPath}>       
                        <pre>{" / "}</pre>
                        </div>  
                        <div className={styles.repositoryPath}>{activeRepository?.name}</div>
                    </div>}
                          
                    {activeRepository && <div className={cn( styles.gridRow, {[styles.flexRow] : !listOfRepositoryResources?.length > 0} , styles.individualPlan)}>        
                        {!resourcesLoad ? (listOfRepositoryResources?.length > 0 ? (listOfRepositoryResources?.filter(r => r.organization_id != null) || [] )?.map((r,i) => (
                            <div key={i} className={cn(styles.gridItem, 
                                {[styles.selectedFramwork] : resourcesIds && resourcesIds?.find(res => res.id === r.id) ,
                            })} onClick={()=>select(r)}>
                                <div className={styles.bodyTypeContainer}>
                                    <div className={styles.extention}>{getExt(r.policyDocument)}</div>
                                </div>
                                <div className={styles.centerFrameworkName}>{r.policyName} </div>
                            </div>
                        )) : 
                            <div className={styles.NoResourcesFound}> 
                                No Resources Found 
                            </div>
                        ) : (
                        <div className={styles.NoResourcesFound}> 
                            <DotsLoader/> 
                        </div>
                        )}
                    </div>}
                    <div className={styles.listBtnsAddframework}>
                        <Btn className={cn(styles.addBtn, styles.backroundFrameworkCancel)} onClick={()=>{setdisplayResources(false);setActiveRepository(null);setListOfRepositoryResources([])}}>Update Resources</Btn>
                    </div>
                </div>
            </Popup>
        </>
    )
}

export default AddAttachments;